import React, { Suspense, useEffect, useLayoutEffect } from "react";
import Lottie from "lottie-react";
import { MouseParallax } from 'react-just-parallax';
import TextTransition, { presets } from 'react-text-transition';
import { useScrollDirection } from 'react-use-scroll-direction'
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import { translate } from "views/utils/translate";
import { connect, useDispatch } from 'react-redux';

import homeIcon from "../../assets/img/menu/home.webp";
import companyIcon from "../../assets/img/menu/company.webp";
import partnershipIcon from "../../assets/img/menu/partnership.webp";
import researchIcon from "../../assets/img/menu/research.webp";
import tradingIcon from "../../assets/img/menu/trading.webp";

import { useHistory } from "react-router";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const MenuDesktop = (props) => {
    const history = useHistory();
    const { isScrollingDown, isScrollingUp } = useScrollDirection();
    const [textBgChanged, setTextBgChanged] = React.useState(0);
    const [sliderArray, setSliderArray] = React.useState(null);
    const [carouselState, setCarouselState] = React.useState({
        goToSlide: 0,
        offsetRadius: 1,
        showNavigation: false,
        enableSwipe: true,
        config: config.gentle
    })
    let sliderArrayTemp = [
        { name: translate(props.lang, "OUR-CORE") },
        { name: translate(props.lang, "TRADING") },
        { name: translate(props.lang, "PARTNERSHIP") },
        { name: translate(props.lang, "RESEARCH") },
        { name: translate(props.lang, "COMPANY") },
    ]

    useLayoutEffect(() => {
        if (window.location.pathname === "/our-core") {
            setTextBgChanged(0);
            setCarouselState({ goToSlide: 0 });
        }
        else if (window.location.pathname === "/product" || window.location.pathname === "/platform" || window.location.pathname === "/account-overview") {
            setTextBgChanged(1);
            setCarouselState({ goToSlide: 1 });
        }
        else if (window.location.pathname === "/partnership" || window.location.pathname === "/referral-program") {
            setTextBgChanged(2);
            setCarouselState({ goToSlide: 2 });
        }
        else if (window.location.pathname === "/research") {
            setTextBgChanged(3);
            setCarouselState({ goToSlide: 3 });
        }
        else if (window.location.pathname === "/company") {
            setTextBgChanged(4);
            setCarouselState({ goToSlide: 4 });
        }
    }, [props.lang])

    useEffect(() => {
        setSliderArray(sliderArrayTemp);
    }, [props.lang]);

    const slides = [
        {
            key: uuidv4(),
            content:
                <MouseParallax strength={textBgChanged === 0 ? 0.04 : 0} lerpEase={textBgChanged === 0 ? 0.5 : 0}>
                    {/* <Lottie animationData={homeJson} className={textBgChanged === 0 ? "w-100 cursor-pointer lottieFile" : "w-100 lottieFile"} id="animIcon1" onClick={() => redirectToPage()} />; */}
                    <img src={homeIcon} className={textBgChanged === 0 ? "w-100 cursor-pointer" : "w-100"} id="animIcon1" onClick={() => redirectToPage()} draggable={false} />
                </MouseParallax>
        },
        {
            key: uuidv4(),
            content:
                <MouseParallax strength={textBgChanged === 1 ? 0.04 : 0} lerpEase={textBgChanged === 1 ? 0.5 : 0}>
                    {/* <Lottie animationData={tradingJson} className={textBgChanged === 1 ? "w-100 cursor-pointer lottieFile" : "w-100 lottieFile"} id="animIcon2" onClick={() => redirectToPage()} /> */}
                    <img src={tradingIcon} className={textBgChanged === 1 ? "w-100 cursor-pointer" : "w-100"} id="animIcon2" onClick={() => redirectToPage()} draggable={false} />
                </MouseParallax>
        },
        {
            key: uuidv4(),
            content:
                <MouseParallax strength={textBgChanged === 2 ? 0.04 : 0} lerpEase={textBgChanged === 2 ? 0.5 : 0}>
                    {/* <Lottie animationData={partnershipJson} className={textBgChanged === 2 ? "w-100 cursor-pointer lottieFile" : "w-100 lottieFile"} id="animIcon3" onClick={() => redirectToPage()} /> */}
                    <img src={partnershipIcon} className={textBgChanged === 2 ? "w-100 cursor-pointer" : "w-100"} id="animIcon3" onClick={() => redirectToPage()} draggable={false} />
                </MouseParallax>
        },
        {
            key: uuidv4(),
            content:
                <MouseParallax strength={textBgChanged === 3 ? 0.04 : 0} lerpEase={textBgChanged === 3 ? 0.5 : 0}>
                    {/* <Lottie animationData={researchJson} className={textBgChanged === 3 ? "w-100 cursor-pointer lottieFile" : "w-100 lottieFile"} id="animIcon4" onClick={() => redirectToPage()} /> */}
                    <img src={researchIcon} className={textBgChanged === 3 ? "w-100 cursor-pointer" : "w-100"} id="animIcon4" onClick={() => redirectToPage()} draggable={false} />
                </MouseParallax>
        },
        {
            key: uuidv4(),
            content:
                <MouseParallax strength={textBgChanged === 4 ? 0.04 : 0} lerpEase={textBgChanged === 4 ? 0.5 : 0}>
                    {/* <Lottie animationData={companyJson} className={textBgChanged === 4 ? "w-100 cursor-pointer lottieFile" : "w-100 lottieFile"} id="animIcon5" onClick={() => redirectToPage()} /> */}
                    <img src={companyIcon} className={textBgChanged === 4 ? "w-100 cursor-pointer" : "w-100"} id="animIcon5" onClick={() => redirectToPage()} draggable={false} />
                </MouseParallax>
        },
    ]

    const goToNextSlide = () => {
        let sliderIndex = textBgChanged;
        if (textBgChanged >= sliderArray.length - 1) {
            sliderIndex = -1;
        }
        setCarouselState({ goToSlide: sliderIndex + 1 });
        setTextBgChanged(sliderIndex + 1);
    }

    const goToPrevSlide = () => {
        let sliderIndex = textBgChanged;
        if (textBgChanged <= 0) {
            sliderIndex = sliderArray.length;
        }

        setCarouselState({ goToSlide: sliderIndex - 1 });
        setTextBgChanged(sliderIndex - 1);
    }

    const redirectToPage = () => {
        sessionStorage.setItem('vtgm-menuPage', false);
        document.getElementById('menuPage').style.transform = 'translate(0, 0)';
        document.getElementById('menuPage').style.clipPath = 'none';
        document.getElementById('menuPage').style.borderRadius = '0%';

        if (textBgChanged === 0) {
            if (window.location.pathname !== "/our-core") {
                history.push('/our-core');
            }
            else {
                window.location.reload();
            }
        }
        else if (textBgChanged === 1) {
            if (window.location.pathname === "/product") {
                window.location.reload();
            }
            else {
                history.push('/product');
            }
        }
        else if (textBgChanged === 2) {
            if (window.location.pathname === "/partnership") {
                window.location.reload();
            }
            else {
                history.push('/partnership');
            }
        }
        else if (textBgChanged === 3) {
            if (window.location.pathname !== "/research") {
                history.push('/research');
            }
            else {
                window.location.reload();
            }
        }
        else if (textBgChanged === 4) {
            if (window.location.pathname !== "/company") {
                history.push('/company');
            }
            else {
                window.location.reload();
            }
        }
    }

    let xDown = null;
    let yDown = null;

    const getTouches = (evt) => {
        return (
            evt.touches || evt.originalEvent.touches // browser API
        ); // jQuery
    };

    const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    const handleTouchMove = (evt) => {
        if (!xDown || !yDown) { return; }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                goToNextSlide();
            } else {
                goToPrevSlide();
            }
        }
        xDown = null;
        yDown = null;
    };

    const boxRef = React.useRef(null);
    let startX = 0;

    const handleDragStart = (e) => {
        startX = e.clientX;
        var img = document.createElement("img");
        e.dataTransfer.setDragImage(img, 0, 0);
    };

    const handleDragOver = (e) => {
        const deltaX = e.clientX - startX;
        if (deltaX > 0) {
            goToPrevSlide();
        } else if (deltaX < 0) {
            goToNextSlide();
        }

        startX = e.clientX;
    };

    const redirectToSlide =(index)=> {
        setCarouselState({ goToSlide: index });
        setTextBgChanged(index);
    }

    const handleScroll = (event) => {
        const deltaY = event.deltaY;
        if (deltaY > 0) {
            // console.log("NEXT");
            goToNextSlide();
        } else if (deltaY < 0) {
            // console.log("PREV");
            goToPrevSlide();
        }
    };

    return (
        <>
            <div
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                draggable={true}
                onDragStart={(e) => handleDragStart(e)}
                onDragEnd={(e) => handleDragOver(e)}
                onWheel={(e) => handleScroll(e)}
                ref={boxRef}
            >
                <div className="menu" id="menuPage" draggable={false}>
                    <div id="homeHeader" className="position-relative w-100" draggable={false}>
                        <div className="staggerTextBg text-center w-100 inline" draggable={false}>
                            {`${sliderArray && sliderArray[textBgChanged].name}`.split("").map((txt, i) => (
                                <TextTransition key={i} delay={i * 100} springConfig={presets.gentle} inline className={'textTrans' + props.lang + ' ' + 'textTrans' + props.lang + i}>
                                    {txt}
                                </TextTransition>
                            ))}
                        </div>

                        <div className="menu-slider-3d w-100 h-100 position-relative">
                            <Carousel
                                slides={slides}
                                goToSlide={carouselState.goToSlide}
                                offsetRadius={1}
                                showNavigation={false}
                                enableSwipe={true}
                                animationConfig={carouselState.config}
                                className="w-100 h-100"
                            />
                            <div className="d-none d-lg-block navigationPos">
                                <div />
                                <div className='d-flex justify-content-start align-items-center arrow-btn leftArrowPos' onClick={() => goToPrevSlide()}>
                                    <div className='btn'>
                                        <FaArrowLeft className='sectionDivTitle text-white ' />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-start align-items-center arrow-btn rightArrowPos' onClick={() => goToNextSlide()}>
                                    <div className='btn'>
                                        <FaArrowRight className='sectionDivTitle text-white' />
                                    </div>
                                </div>
                            </div>

                            <div className="d-none d-lg-flex justify-content-center align-items-center menuDotsPos">
                                <div className="sideBarSectionNumber smallText mx-auto" onClick={()=>redirectToSlide(0)}>
                                    1
                                </div>
                                <div className="sideDotDeco mx-2" />
                                <div className="sideDotDeco mx-2" />
                                <div className="sideDotDeco mx-2" />
                                <div className="sideBarSectionNumber smallText mx-auto" onClick={()=>redirectToSlide(1)}>
                                    2
                                </div>
                                <div className="sideDotDeco mx-2" />
                                <div className="sideDotDeco mx-2" />
                                <div className="sideDotDeco mx-2" />
                                <div className="sideBarSectionNumber smallText mx-auto" onClick={()=>redirectToSlide(2)}>
                                    3
                                </div>
                                <div className="sideDotDeco mx-2" />
                                <div className="sideDotDeco mx-2" />
                                <div className="sideDotDeco mx-2" />
                                <div className="sideBarSectionNumber smallText mx-auto" onClick={()=>redirectToSlide(3)}>
                                    4
                                </div>
                                <div className="sideDotDeco mx-2" />
                                <div className="sideDotDeco mx-2" />
                                <div className="sideDotDeco mx-2" />
                                <div className="sideBarSectionNumber smallText mx-auto" onClick={()=>redirectToSlide(4)}>
                                    5
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        lang: i18n.lang,
    };
};

export default connect(mapStateToProps)(MenuDesktop);
