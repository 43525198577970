import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const missionVisionArray = [
    { title: "Mission", desc: "Our mission is to become the world class brokerage to provide the best trading environment for our users. We always value our users as our primary assets because VT is designed for users to have access to all the tools you need to succeed and to maximize your wealth and potential." },
    { title: "Vision", desc: "VT Global markets provides our valued customers with a leading-edge trading platform (MT5), offering them a great trading experience and environment. MT5 is the latest advancement in MetaQuotes technology that not only provides upgraded functionality and charting tools, but also allows brokers to connect with a few liquidity providers in order to provide the best available price feed to our investors." }
]

function MissionVision(props) {
    const [imageIndex, setImageIndex] = React.useState(0);
    const slider2 = React.useRef(null);

    const settings = {
        dots: true,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: 1,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='missionVision'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-stretch mx-0 w-100 h-100'>
                        <Col lg={5} md={11} sm={11} xs={11} className='mb-5 mb-lg-4 text-left text-lg-center'>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-left'>{translate(props.lang, "Mission & Vision of VT Global markets")}</span>
                            <span className='font-Regular sectionDesc text-light-purple mb-3 d-block text-left'>
                                {translate(props.lang, "With years of expertise and experience in foreign exchange and financial products, our management team truly understands the demands of every trader, resulting in VT being a platform built for users by constantly improving on product optimization and service quality.")}
                            </span>
                        </Col>
                        <Col lg={7} md={11} sm={11} xs={11} className='mb-5 mb-lg-4 text-left text-lg-center'>
                            <div className='missionVisionBox h-100'>
                                <div className='sliderContentBox position-relative mt-4 h-100'>
                                    <Slider {...settings} arrows={false} ref={slider2} >
                                        {
                                            missionVisionArray.map((item, i) => {
                                                return (
                                                    <Row className="justify-content-start align-items-start mx-0 w-100 h-100 missionVisionBoxContent">
                                                        <Col lg={10} md={11} sm={12} xs={12}>
                                                            <span className='font-Bold sectionDivTitle text-white d-block text-left text-capitalize mb-3'>{translate(props.lang, item.title)}</span>
                                                            <span className='sectionDesc font-Regular text-light-purple d-block  text-left'>{translate(props.lang, item.desc)}</span>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                    </Slider>
                                    <div className="d-flex align-items-center justify-content-start sliderArrowAbsolute">
                                        <div className='d-flex justify-content-start align-items-center arrow-btn mr-3 mr-lg-4' onClick={() => slider2?.current?.slickPrev()}>
                                            <div className='btn'>
                                                <FaArrowLeft className='sectionDivTitle text-white' />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start align-items-center arrow-btn' onClick={() => slider2?.current?.slickNext()}>
                                            <div className='btn'>
                                                <FaArrowRight className='sectionDivTitle text-white' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(MissionVision);