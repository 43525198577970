import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row, Table } from 'reactstrap'
import { translate } from "views/utils/translate";
import { FaArrowLeft, FaArrowRight, FaCheck } from 'react-icons/fa';

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import cfdWorkIcon1 from "../../../assets/img/product/cfdWorkIcon1.webp";
import cfdWorkIcon2 from "../../../assets/img/product/cfdWorkIcon2.webp";
import cfdWorkIcon3 from "../../../assets/img/product/cfdWorkIcon3.webp";

import cfdBenefitIcon1 from "../../../assets/img/product/benefitsIcon1.webp";
import cfdBenefitIcon2 from "../../../assets/img/product/benefitsIcon2.webp";
import cfdBenefitIcon3 from "../../../assets/img/product/benefitsIcon3.webp";

import assetTradeIcon1 from "../../../assets/img/product/assetTradeIcon1.webp";
import assetTradeIcon2 from "../../../assets/img/product/assetTradeIcon2.webp";
import assetTradeIcon3 from "../../../assets/img/product/assetTradeIcon3.webp";
import assetTradeIcon4 from "../../../assets/img/product/assetTradeIcon4.webp";
import assetTradeIcon5 from "../../../assets/img/product/assetTradeIcon5.webp";
import assetTradeIcon6 from "../../../assets/img/product/assetTradeIcon6.webp";

const assetTradeArray = [
    { img: assetTradeIcon1, index: "01.", title: 'Forex', desc: 'Currency CFDs track forex pairs. A regulated CFD broker like TMGM offers access to major pairs like EUR/USD and AUD/USD, as well as minor pairs and exotic currencies.' },
    { img: assetTradeIcon2, index: "02.", title: 'Shares', desc: 'Share CFDs track major stocks like Apple, Tesla, Amazon, Netflix and Baidu. Since they track spot markets, CFDs do not have the same divergennt pricing features that make futures and options challenging to trade..' },
    { img: assetTradeIcon3, index: "03.", title: 'Metals', desc: "Gold and Silver CFDs offer access to precious metal spot markets. These CFDs can work for day trading or protecting against inflation and volatility." },
    { img: assetTradeIcon4, index: "04.", title: 'Energies', desc: "Crude oil CFDs offer access to volatile global energy markets. You can trade these popular commodities using both technical analysis and fundamental research." },
    { img: assetTradeIcon5, index: "05.", title: 'Cryptocurrencies', desc: 'Crypto CFDs allows you to invest in digital currency spot markets without the hassle of purchasing the underlying asset.' },
    { img: assetTradeIcon6, index: "06.", title: 'Indices', desc: 'Index CFDs track major global indices, including the S&P 500 and Dow Jones Industrial Average(DJIA) in the US, the S&P/ASX 200 in Australia, and the Nikkei 225 in Japan.' },
]

const cfdWorkArray = [
    { img: cfdWorkIcon1, index: '01.', desc: 'The trader opens a position and either pays or receives money based on the market movements.' },
    { img: cfdWorkIcon2, index: '02.', desc: 'If the market goes down, you must pay the difference between the opening and closing price.' },
    { img: cfdWorkIcon3, index: '03.', desc: 'If the underlying asset increases in price, the broker pays the difference to the trader.' },
]

const cfdBenefitArray = [
    { img: cfdBenefitIcon1, title: 'Spread', desc: "This is the difference between the buy and sell price. The buying price is always lower than the selling price, leaving the market price in the middle. The tighter the spread, the more value you can get. That's why we provide products that spread from 0.0 pips." },
    { img: cfdBenefitIcon2, title: 'Commission', desc: 'Providers will charge a commission when a trade takes place on their platform. Liquidity providers also charge brokers.' },
    { img: cfdBenefitIcon3, title: 'Swaps', desc: "This is the leverage cost, a daily cost that is added or subtracted from an account to pay for leverage." },
]

function CFDContent(props) {
    const [imageIndex, setImageIndex] = React.useState(0);
    const [imageIndex2, setImageIndex2] = React.useState(0);
    const slider2 = React.useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 991 ? 3 : window.innerWidth > 767 ? 2 : 1,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    const settings2 = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 767 ? 1.8 : 1.2,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex2(next) },
    };

    return (
        <>
            <div className='cfdContent'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center justify-content-lg-start align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={11} xs={11}>
                            <Row className='longShortCFDDiv justify-content-around align-items-center'>
                                <Col lg={10} md={12} sm={12} xs={12} className='mb-4 mb-lg-0'>
                                    <span className='font-Bold sectionDivTitle text-white d-block text-center mb-3'>
                                        {translate(props.lang, "Going Short Or Long With CFDs")}
                                    </span>
                                </Col>
                                <Col lg={5} md={5} sm={11} xs={11} className='mb-4 mb-lg-0'>
                                    {
                                        props.lang === "EN" ?
                                            <img src={require("../../../assets/img/product/cfdLongImg.webp").default} alt="" className="d-block w-100 mb-4" />
                                            :
                                            <img src={require("../../../assets/img/product/cfdLongImgCN.png").default} alt="" className="d-block w-100 mb-4" />
                                    }
                                    <span className='font-Bold sectionDivTitle text-white d-block text-center mb-3'>
                                        {translate(props.lang, "Going Long")}
                                    </span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                        {translate(props.lang, 'When you buy a CFD, you "go long", meaning you make profits when the underlying market rises. A CFD long position may be more common, but you can also take the other side of the trade.')}
                                    </span>
                                </Col>
                                <Col lg={5} md={5} sm={11} xs={11} className='mb-4 mb-lg-0'>
                                    {
                                        props.lang === "EN" ?
                                            <img src={require("../../../assets/img/product/cfdShortImg.webp").default} alt="" className="d-block w-100 mb-4" />
                                            :
                                            <img src={require("../../../assets/img/product/cfdShortImgCN.png").default} alt="" className="d-block w-100 mb-4" />
                                    }
                                    <span className='font-Bold sectionDivTitle text-white d-block text-center mb-3'>
                                        {translate(props.lang, "Going Short")}
                                    </span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                        {translate(props.lang, 'When you take the "sell" side, you "go short", taking a short CFD position. If you take a short CFD position, you can make profits if the underlying market goes down.')}
                                    </span>
                                </Col>
                            </Row>

                            <Row className='justify-content-around align-items-center mt-4 mb-4'>
                                <Col lg={7} md={12} sm={12} xs={12} className='mb-5 mb-lg-0'>
                                    <div className='cfdTableDiv'>
                                        <Table responsive className="w-100 rank-table mt-4 text-center position-relative">
                                            <thead>
                                                <tr className=''>
                                                    <th className={`text-left font-Bold sectionDesc text-white lh-1`}>{translate(props.lang, "CFD Trading")}</th>
                                                    <th className={`text-left font-Bold sectionDesc text-white lh-1`}>{translate(props.lang, "Long")}</th>
                                                    <th className={`text-left font-Bold sectionDesc text-white lh-1`}>{translate(props.lang, "Short")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row">
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "Trade")}</td>
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "Buy")}</td>
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "Sell")}</td>
                                                </tr>
                                                <tr role="row">
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "Profit")}</td>
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "Market Goes Down")}</td>
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "Market Goes Down")}</td>
                                                </tr>
                                                <tr role="row">
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "24-hour Market")}</td>
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "Yes")}</td>
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "Yes")}</td>
                                                </tr>
                                                <tr role="row">
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "Automatic Trading")}</td>
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "Available")}</td>
                                                    <td className={`font-Regular sectionDesc text-light-purple text-left`}>{translate(props.lang, "Available")}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                                <Col lg={5} md={12} sm={12} xs={12}>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                        {translate(props.lang, "The ability to easily go long or short can potentially double the number of trading opportunities for traders who would otherwise only be able to go long with other assets.")}
                                    </span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                        {translate(props.lang, "CFD trading often involves leverage, which grants traders more significant exposure to large positions without having to hand over the full cost at the time of trading.")}
                                    </span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                        {translate(props.lang, "This allows traders to increase the size of a CFD position even if they have limited capital. However, increasing the size of your position with borrowed capital can also expose you to magnified profits and losses alike.")}
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='justify-content-center align-items-center mx-0 w-100 h-100 cfdWorkSection'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100'>
                                <Col lg={12} md={11} sm={11} xs={11} className=''>
                                    <span className='font-Bold sectionDivTitle text-white d-block text-left mb-3'>
                                        {translate(props.lang, "How CFDs work")}
                                    </span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                        {translate(props.lang, "Here is what happens when you initiate a CFD trade on our website.")}
                                    </span>
                                </Col>
                            </Row>
                            <Row className='justify-content-end align-items-stretch mx-0 w-100 h-100'>
                                <Col lg={12} md={11} sm={11} xs={11} className='px-0'>
                                    <div className='d-none d-lg-block'>
                                        <Row className='justify-content-around align-items-stretch px-0 mx-0 w-100 cfdWorkBoxSection'>
                                            {
                                                cfdWorkArray.map((item, i) => (
                                                    <Col lg={4} md={12} sm={12} xs={12} className='h-100 mb-4 mb-lg-0'>
                                                        <Row className='justify-content-center align-items-center cfdWorkBox mx-0 h-100'>
                                                            <Col lg={6} md={6} sm={6} xs={6}>
                                                                <div className='cfdWorkImgDiv' id={'cfdWorkImgDiv' + i}>
                                                                    <img src={item.img} className='' />
                                                                </div>
                                                            </Col>
                                                            <Col lg={4} md={5} sm={5} xs={5}>
                                                                <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                                            </Col>
                                                            <Col lg={10} md={11} sm={11} xs={11} className='descSpacing mt-4'>
                                                                <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, item.desc)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </div>

                                    <div className='d-block d-lg-none mt-5'>
                                        <Slider {...settings2} arrows={false} className='h-100' >
                                            {
                                                cfdWorkArray.map((item, i) => (
                                                    <div className='h-100 mb-4 mb-lg-0 px-2'>
                                                        <Row className='justify-content-center align-items-center cfdWorkBox mx-0 h-100'>
                                                            <Col lg={6} md={6} sm={6} xs={6}>
                                                                <div className='cfdWorkImgDiv' id={'cfdWorkImgDiv' + i}>
                                                                    <img src={item.img} className='' />
                                                                </div>
                                                            </Col>
                                                            <Col lg={4} md={5} sm={5} xs={5}>
                                                                <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                                            </Col>
                                                            <Col lg={10} md={11} sm={11} xs={11} className='descSpacing mt-4'>
                                                                <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, item.desc)}</span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                            }
                                        </Slider>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100 cfdBenefitSection'>
                        <Col lg={12} md={11} sm={11} xs={11} className=''>
                            <span className='font-Bold sectionDivTitle text-white d-block text-left mb-3'>
                                {translate(props.lang, "Benefits of CFD Trading")}
                            </span>
                            <span className='font-Bold sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "Low Trading Cost")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "A major benefit of CFD trading, especially when compared to stocks, is that they have a significantly lower cost. There are three costs involved in CFD trading:")}
                            </span>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            <Row className='justify-content-end align-items-stretch px-0 mx-0 w-100 h-100'>
                                <Col lg={12} md={11} sm={11} xs={11} className='px-0'>
                                    <div className='d-none d-lg-block'>
                                        <Row className='justify-content-start align-items-stretch mx-0 w-100 cfdWorkBoxSection'>
                                            {
                                                cfdBenefitArray.map((item, i) => {
                                                    return (
                                                        <Col lg={4} md={6} sm={12} xs={12}>
                                                            <div className="w-100 px-2 h-100 cfdBenefitBoxSection">
                                                                <Row className='justify-content-center align-items-start cfdBenefitBox mx-0' id={"cfdBenefitBox" + i}>
                                                                    <Col lg={11} md={11} sm={10} xs={10}>
                                                                        <div className='cfdBenefitImgDiv mx-auto' id={'cfdBenefitImgDiv' + i}>
                                                                            <img src={item.img} className='mx-auto' />
                                                                        </div>

                                                                        <span className='font-Regular sectionDivTitle text-white text-center d-block my-4'>{translate(props.lang, item.title)}</span>
                                                                        <span className='font-Regular sectionDesc text-white text-center d-block'>{translate(props.lang, item.desc)}</span>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    );
                                                })}
                                        </Row>
                                    </div>

                                    <div className='d-block d-lg-none'>
                                        <Slider {...settings2} arrows={false} className='h-100' >
                                            {
                                                cfdBenefitArray.map((item, i) => {
                                                    return (
                                                        <div className='h-100 px-2'>
                                                            <div className="w-100 px-2 h-100 cfdBenefitBoxSection">
                                                                <Row className='justify-content-center align-items-start cfdBenefitBox mx-0' id={"cfdBenefitBox" + i}>
                                                                    <Col lg={11} md={11} sm={10} xs={10}>
                                                                        <div className='cfdBenefitImgDiv mx-auto' id={'cfdBenefitImgDiv' + i}>
                                                                            <img src={item.img} className='mx-auto' />
                                                                        </div>

                                                                        <span className='font-Regular sectionDivTitle text-white text-center d-block my-4'>{translate(props.lang, item.title)}</span>
                                                                        <span className='font-Regular sectionDesc text-white text-center d-block'>{translate(props.lang, item.desc)}</span>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </Slider>
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='justify-content-center justify-content-lg-start align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={12} xs={12}>
                            <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100 cfdGoFurther'>
                                <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                                    <Row className='justify-content-center align-items-start px-0 mx-0 w-100'>
                                        <Col lg={12} md={12} sm={12} xs={12} className=''>
                                            <span className='font-Bold sectionDivTitle text-white d-block text-left mb-3'>
                                                {translate(props.lang, "Make Your Capital Go Further")}
                                            </span>
                                        </Col>
                                        <Col lg={6} md={6} sm={12} xs={12} className=''>
                                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                                {translate(props.lang, "Leverage trading is another advantage of CFDs. When trading with leverage, you can increase the size of your position, even if you don't have the required upfront capital. This means that if you were to buy 200 shares of anything, you wouldn't have to pay the full cost immediately.")}
                                            </span>
                                        </Col>
                                        <Col lg={6} md={6} sm={12} xs={12} className=''>
                                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                                {translate(props.lang, "Leveraged CFD trading allows you to increase the volume of profits or losses without the initial cost that a traditional trade would require. In certain circumstances, you could control $30 for every $1 in your account. Other significant benefits CFDs have that other asset classes lacks including:")}
                                            </span>
                                        </Col>
                                    </Row>

                                    <Row className='justify-content-center justify-content-lg-around align-items-center mx-0 w-100 goFurtherBox'>
                                        <Col lg={5} md={6} sm={10} xs={10}>
                                            <img src={require("../../../assets/img/product/goFurtherImg.webp").default} alt="" className="d-block w-100 mb-4 floating3" />
                                        </Col>
                                        <Col lg={6} md={10} sm={11} xs={11}>
                                            <Row className='justify-content-start align-items-start mt-3'>
                                                <Col lg={1} md={1} sm={1} xs={1}>
                                                    <FaCheck className='sectionDesc mt-1' color={'#03FFE1'} />
                                                </Col>
                                                <Col lg={10} md={10} sm={10} xs={10}>
                                                    <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Global accessibility to investors with limited capital.")}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-start align-items-start mt-3'>
                                                <Col lg={1} md={1} sm={1} xs={1}>
                                                    <FaCheck className='sectionDesc mt-1' color={'#03FFE1'} />
                                                </Col>
                                                <Col lg={10} md={10} sm={10} xs={10}>
                                                    <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Access to automatic and algorithmic trading.")}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-start align-items-start mt-3'>
                                                <Col lg={1} md={1} sm={1} xs={1}>
                                                    <FaCheck className='sectionDesc mt-1' color={'#03FFE1'} />
                                                </Col>
                                                <Col lg={10} md={10} sm={10} xs={10}>
                                                    <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Go long or short on a market's direction.")}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-start align-items-start mt-3'>
                                                <Col lg={1} md={1} sm={1} xs={1}>
                                                    <FaCheck className='sectionDesc mt-1' color={'#03FFE1'} />
                                                </Col>
                                                <Col lg={10} md={10} sm={10} xs={10}>
                                                    <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Trade across various markets, including shares, forex, precious metals, energies, indices and stock indices. 24-hour, 5-day-a-week trading across several markets.")}</span>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-start align-items-start mt-3'>
                                                <Col lg={11} md={11} sm={11} xs={11}>
                                                    <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "To learn more about how CFDs stack up against other assets classes, like stocks, check out Our Blog.")}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100 cfdAssetTrade'>
                                <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 mb-4 mb-lg-0'>
                                        <Col lg={8} md={9} sm={12} xs={12} className=''>
                                            <span className='font-Bold sectionDivTitle text-white d-block text-left mb-3'>
                                                {translate(props.lang, "Assets to trade with CFDs")}
                                            </span>
                                            <span className='font-Bold sectionDesc text-light-purple d-block text-left mb-3'>
                                                {translate(props.lang, "What is a CFD trading market option?")}
                                            </span>
                                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                                {translate(props.lang, "CFDs track many different asset classes. Here is what you can trade with a quality broker:")}
                                            </span>
                                        </Col>
                                        <Col lg={4} md={3} sm={12} xs={12} className=''>
                                            <div className="d-flex align-items-center justify-content-end">
                                                <div className='d-flex justify-content-start align-items-center arrow-btn mr-3 mr-lg-4' onClick={() => slider2?.current?.slickPrev()}>
                                                    <div className='btn'>
                                                        <FaArrowLeft className='sectionDivTitle text-white' />
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-start align-items-center arrow-btn' onClick={() => slider2?.current?.slickNext()}>
                                                    <div className='btn'>
                                                        <FaArrowRight className='sectionDivTitle text-white' />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center align-items-stretch w-100 mx-0'>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <Slider {...settings} arrows={false} ref={slider2} className='h-100' >
                                                {
                                                    assetTradeArray.map((item, i) => {
                                                        return (
                                                            <Row className='justify-content-around align-items-start d-flex assetTradeBox mx-0 h-100' id={"assetTradeBox" + i}>
                                                                <Col lg={6} md={6} sm={6} xs={6}>
                                                                    <div className='assetTradeImgDiv' id={'assetTradeImgDiv' + i}>
                                                                        <img src={item.img} className='' />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={4} md={5} sm={5} xs={5}>
                                                                    <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                                                </Col>
                                                                <Col lg={11} md={11} sm={11} xs={11} className='mt-3'>
                                                                    <span className='font-Bold sectionDivTitle text-white d-block text-left text-capitalize mb-3'>{translate(props.lang, item.title)}</span>
                                                                    <span className='font-Regular sectionDesc text-light-purple d-block'>{translate(props.lang, item.desc)}</span>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                            </Slider>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >

                <div className=''>
                    <Container>
                        <Row className='justify-content-center align-items-center mx-0'>
                            <Col lg={12} md={11} sm={12} xs={12} className=''>
                                <Row className='justify-content-start align-items-center mx-0 w-100 h-100 getInTouchBanner'>
                                    <Col lg={9} md={12} sm={12} xs={12} className=''>
                                        <div className=''>
                                            <span className='font-Black sectionHeaderTitle3 text-white text-uppercase d-block mb-2 text-start'>{translate(props.lang, "Get in touch with us to begin trading in CFDs, or open an account now and gain access to global markets in under three minutes.")}</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={12} sm={12} xs={12} className='' />

                                    <Col lg={2} md={5} sm={6} xs={6}>
                                        <div className='mt-4 d-flex justify-content-start align-items-center vtgm-btn-transparent'>
                                            <div className='btn'>
                                                <FaArrowRight className='sectionDivTitle text-white' />
                                            </div>
                                            <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Get In Touch")}</span>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={5} sm={6} xs={6}>
                                        <div className='mt-4 d-flex justify-content-start align-items-center vtgm-btn-transparent'>
                                            <div className='btn'>
                                                <FaArrowRight className='sectionDivTitle text-white' />
                                            </div>
                                            <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Register")}</span>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>


            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(CFDContent);