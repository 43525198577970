import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";
import "../../assets/scss/trading.scss";

function Trading(props) {
    return (
        <>
            <div className="trading">
                <div className='header'>
                    <Container className='w-100 h-100'>
                        <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                            <Col lg={7} md={11} sm={11} xs={11} className=''>
                                <span className='font-Black headerTitle text-white text-uppercase d-block mb-4 text-left'>
                                    {translate(props.lang, "The Ultimate Trading Experience")}
                                </span>
                                <span className='font-Regular sectionDesc text-light-purple d-block text-left'>
                                    {translate(props.lang, "A professional trading environment that priorities users trading experience, giving users access to opportunities across the financial markets. We offers various resources and tools needed for users to success in their trading careers. Registered and regulated in Vanuatu have made us the most trustworthy and secure brokers among others.")}
                                </span>
                            </Col>
                            <Col lg={5} md={8} sm={11} xs={11} className=''>
                                <img src={require("../../assets/img/trading/tradingImg.webp").default} alt="" className="d-block w-100" />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(Trading);