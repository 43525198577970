import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function IndicesHeader(props) {
    return (
        <>
            <div className='indicesHeader' id="indices">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center justify-content-lg-around align-items-center px-0 mx-0 w-100 h-100 forexHeaderRow'>
                        <Col lg={5} md={9} sm={11} xs={11} className='order-2 order-lg-1'>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center text-lg-left'>
                                {translate(props.lang, "indices")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center text-lg-left mb-3'>
                                {translate(props.lang, "Trade the movements of an industry or sector's growth or trajectory in the form of CFD, with the ability to buy or sell.")}
                            </span>
                            <span className='font-Bold sectionDivTitle text-white d-block text-left mb-3 mt-4'>
                                {translate(props.lang, "What Are Indices?")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "Indices are a collection of stocks and instruments that are used to track an industry or sector’s growth or trajectory.")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "For example, the US500, also known as the S&P 500 Index, is an American stock market index that tracks the performance of 500 large companies listed on US stock exchanges.")}
                            </span>
                        </Col>
                        <Col lg={4} md={5} sm={9} xs={9} className='mb-4 mb-lg-0 order-1 order-lg-2'>
                            <img src={require("../../../assets/img/product/indicesImg.webp").default} alt="" className="d-block w-100 floating3" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(IndicesHeader);