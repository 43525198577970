import React from "react";
import "../../../assets/scss/product.scss";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import CFDHeader from "./CFDHeader";
import CFDContent from "./CFDContent";
import ForexHeader from "./ForexHeader";
import ForexContent from "./ForexContent";
import IndicesHeader from "./IndicesHeader";
import EquitiesHeader from "./EquitiesHeader";
import CommoditiesHeader from "./CommoditiesHeader";
import ETFHeader from "./ETFHeader";
import IndicesContent from "./IndicesContent";
import CommoditiesContent from "./CommoditiesContent";
import EquitiesContent from "./EquitiesContent";
import ETFContent from "./ETFContent";
import SideBar from "components/SideBar";
import Trading from "../Trading";

const Product = () => {

    return (
        <>
            <IndexNavbar />
            <SideBar />
            <Trading />
            <div className="product">
                <CFDHeader />
                <CFDContent />
                <ForexHeader />
                <ForexContent />
                <IndicesHeader />
                <IndicesContent />
                <CommoditiesHeader />
                <CommoditiesContent />
                <EquitiesHeader />
                <EquitiesContent />
                <ETFHeader />
                <ETFContent />
            </div>
            <DarkFooter />
        </>
    );
};

export default Product;
