import React from 'react'
import { FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function License(props) {
    return (
        <>
            <div className='license' id="regulation-and-license">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={12} sm={11} xs={11} className='px-0'>
                            <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                                <Col lg={9} md={10} sm={12} xs={12} className='mb-3'>
                                    <span className='font-Black sectionHeaderTitle text-white d-block text-center text-uppercase mb-2'>{translate(props.lang, "Regulation and License")}</span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-center mb-3'>
                                        {translate(props.lang, "Regulated and trusted, allowing you to trade with complete piece of mind")}
                                    </span>
                                </Col>
                                <Col lg={11} md={11} sm={12} xs={12} className='mb-3 licenseBg'>
                                    <img src={require("../../assets/img/company/licenseImg.png").default} alt="" className="d-block" />

                                    <Row className='justify-content-center align-items-start px-0 mx-0 w-100 h-100'>
                                        <Col lg={5} md={10} sm={12} xs={12} className=''>
                                            <Row className='justify-content-center align-items-start px-0 mx-0 w-100 h-100'>
                                                <Col lg={1} md={1} sm={1} xs={1} className=''>
                                                    <FaCheck className='sectionDesc' color={'#03FFE1'} />
                                                </Col>
                                                <Col lg={11} md={11} sm={11} xs={11} className=''>
                                                    <span className='font-Regular sectionDesc text-light-purple d-block text-start mb-3'>
                                                        {translate(props.lang, "VT Global Markets is authorized and regulated by multiple regulators across the globe")}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col lg={5} md={10} sm={12} xs={12} className=''>
                                            <Row className='justify-content-center align-items-start px-0 mx-0 w-100 h-100'>
                                                <Col lg={1} md={1} sm={1} xs={1} className=''>
                                                    <FaCheck className='sectionDesc' color={'#03FFE1'} />
                                                </Col>
                                                <Col lg={11} md={11} sm={11} xs={11} className=''>
                                                    <span className='font-Regular sectionDesc text-light-purple d-block text-start mb-3'>
                                                        {translate(props.lang, "VT Global Markets is authorised and regulated by Vanuatu Financial Services Commission (VFSC) (Financial Services License ：VFSC 700 557 ).")}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(License);