import React from 'react'
import { FaArrowRight, FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";
import Lottie from "lottie-react";
import journeyAnim from "../../assets/img/home/journey.json";

function PartJourney(props) {
    return (
        <>
            <div className='journey' id="our-journey">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={12} sm={11} xs={11} className='px-0'>
                            <Row className='justify-content-around align-items-center px-0 mx-0 w-100 h-100'>
                                <Col lg={5} md={8} sm={12} xs={12} className='mb-4 mb-lg-0'>
                                    <Lottie animationData={journeyAnim} />;
                                </Col>

                                <Col lg={4} md={10} sm={12} xs={12} className=''>
                                    <span className='font-Black sectionHeaderTitle text-white d-block text-left text-uppercase mb-3'>{translate(props.lang, "Be A Part Of Our Journey")}</span>
                                    <span className='font-Regular sectionDesc text-light-purple'>{translate(props.lang, "Connect with a trustworthy partner who will assist you in every way")}</span>

                                    <div className='d-flex justify-content-start align-items-center mt-4'>
                                        <FaCheck className='sectionDesc mr-3' color={'#03FFE1'} />
                                        <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Expansive Range of Instruments")}</span>
                                    </div>

                                    <div className='d-flex justify-content-start align-items-center mt-2'>
                                        <FaCheck className='sectionDesc mr-3' color={'#03FFE1'} />
                                        <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Competitive Bid/Ask pip spreads")}</span>
                                    </div>

                                    <div className='d-flex justify-content-start align-items-center mt-2'>
                                        <FaCheck className='sectionDesc mr-3' color={'#03FFE1'} />
                                        <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Leverage up to 1:400")}</span>
                                    </div>

                                    <div className='d-flex justify-content-start align-items-center mt-2'>
                                        <FaCheck className='sectionDesc mr-3' color={'#03FFE1'} />
                                        <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Rebates per lot")}</span>
                                    </div>

                                    <div className='d-flex justify-content-start align-items-center mt-2'>
                                        <FaCheck className='sectionDesc mr-3' color={'#03FFE1'} />
                                        <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Redemption points for every traded lot")}</span>
                                    </div>

                                    <div className='mt-4 d-flex justify-content-start align-items-center vtgm-btn'>
                                        <div className='btn'>
                                            <FaArrowRight className='sectionDivTitle text-white' />
                                        </div>
                                        <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Open Live Account")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(PartJourney);