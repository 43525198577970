import React from 'react'
import { FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function Certificate(props) {

    return (
        <>
            <div className='certificate'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-end align-items-center mx-0 w-100 h-100'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100'>
                                <Col lg={8} md={10} sm={12} xs={12} className=''>
                                    <img src={require("../../assets/img/company/certificateImg.png").default} alt="" className="d-block w-100 mb-5" />
                                    <span className='font-Black sectionDivTitle text-white d-block text-center'>{translate(props.lang, "ALK TRADE GLOBAL LIMITED trading as VTGM, is authorized and regulated by the VANUATU FINANCIAL SERVICES COMMISSION, No.700557")}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(Certificate);