import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function AccountHeader(props) {
    return (
        <>
            <div className='header' id="accounts-overview">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-start px-0 mx-0 w-100 h-100'>
                        <Col lg={6} md={11} sm={11} xs={11} className='mb-4 mb-lg-0'>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center'>{translate(props.lang, "Accounts Overview")}</span>
                            <span className='font-Black sectionHeaderTitle3 text-white text-capitalize d-block mb-4 text-center'>{translate(props.lang, "Which account is the best fit for me?")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                {translate(props.lang, "Your trading journey begins with a trading account. We offer different account types to suit your trading needs and experience. Refer to the table below to find out which account is best for you.")}
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(AccountHeader);