import React from "react";
import "../../../assets/scss/accountOverview.scss";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import AccountHeader from "./AccountHeader";
import StandardAccount from "./StandardAccount";
import DepositWithdrawal from "./DepositWithdrawal";
import PaymentOptions from "./PaymentOptions";
import SideBar from "components/SideBar";

const AccountOverview = () => {

    return (
        <>
            <IndexNavbar />
            <SideBar />
            <div className="accountOverview">
                {/* <AccountHeader /> */}
                <StandardAccount />
                <DepositWithdrawal />
                <PaymentOptions />
            </div>
            <DarkFooter />
        </>
    );
};

export default AccountOverview;
