const ZH = {
    //home
    "Join": "加入",
    "Glow": "成长",
    "Multiply": "倍增",
    "Become a PAMM account investor, earn without having to trade yourself by entrusting your funds to an experienced trader.": "成为 PAMM 账户投资者，通过将您的资金委托给经验丰富的交易者，无需亲自交易即可轻松赚取收益。",
    "Empowering Your Trading Experience": "增强您的交易体验",
    "Unlock unparalleled opportunities with our refined USP": "通过我们精心打磨的独特优势，解锁无与伦比的机会",
    '$100 Welcome Bonus': '$100 迎新奖励',
    'Low Spread & Slippage': '低点差和滑点',
    'Low Minimum Deposit of $100': '最低存款额只需 100 美元',
    'Over 100 Trading Products': '超过100种交易产品',
    'Why Choose Us': '为什么选择我们',
    'New Generation & Innovative Trading Platform': '全新一代创新交易平台',
    'Lightning-Fast Trading Experience': '闪电般快速的交易体验',
    'Regulated Broker Connected With Multi Liquidity Providers': '与多个流动性供应商相连的受监管经纪商',
    'Quick Withdrawal & Deposit Promises': '快速提款和存款的承诺',
    'Free Daily Technical Analysis': '每日提供免费的技术分析',
    'Dedicated Account Managers': '专属客户经理',
    "Be A Part Of Our Journey": "成为我们旅程的一部分",
    "Connect with a trustworthy partner who will assist you in every way": "与值得信赖，并且会在各方面协助您的伙伴合作",
    "Expansive Range of Instruments": "广泛的交易种类",
    "Competitive Bid/Ask pip spreads": "有竞争力的买价/卖价点差",
    "Leverage up to 1:400": "高达1:500的杠杆",
    "Rebates per lot": "每手都能享有回扣",
    "Redemption points for every traded lot": "每手交易都能获得积分",
    "Open Live Account": "立即开设账户",
    "Take a look at": "了解更多关于",
    "PAMM": "PAMM",
    "Subscribe PAMM to obtain a competitive advantage from our team of professionals, who are all traders with a track record of success.": "订阅 PAMM ，从我们有成功记录的专业团队中获得竞争优势。",
    "With our exclusive PAMM service, you may now profitably trade Forex and CFDs even if you have no prior trading experience. Run out of time to trade? Our PAMM will take care of everything. Invest and relax.": "通过我们独家的PAMM服务，即使您没有交易经验，也可以在外汇和差价合约交易中获利。没时间交易？我们的PAMM将为您处理一切。您只需投资和放松。",
    "Subscribe": "订阅",
    "Start Trading With Us": "与我们一起开始交易",
    "Open an account in just 3 STEPS": "只需 3 步即可开设账户",
    'Register': '注册',
    'Fill in your details and submit documents for verification': '填写您的详细信息并提交文件以进行验证',
    'Fund': '资金',
    'Deposit funds by online banking or cryptocurrency method': '通过网上银行或加密货币方式存入资金',
    'Trade': '交易',
    'Start trading by choosing your desired account type': '选择您想要的账户类型开始交易',
    "Start your trading journey with us and unleash your potential!": "与我们一起开始您的交易之旅，释放您的潜力！",
    "Trade With Us": "开始交易",

    //Company
    "About Us": "关于我们",
    "VT Global markets is a leading global financial institution which offers clients the opportunity to trade the financial markets via contract for differences (CFDs)": "VT Global Markets 是一家全球领先的金融机构，为客户提供通过差价合约 (CFDs) 在金融市场进行交易的机会",
    "Our goal is to continually explore and discover innovative trading technologies in order to provide our clients with an unparalleled value and trading experience. We aim to provide exceptional services in currency trading, indices, commodities, equities and ETFs, to an extent that our clients remain satisfied with their trading experience.": "我们的目标是不断探索和发现创新的交易技术，以便为我们的客户提供无与伦比的价值和交易体验。我们致力在货币交易、指数、商品、股票和 ETF 方面提供卓越的服务，使我们的客户对他们的交易经验保持满意。",
    "Mission & Vision of VT Global markets": "VT Global Markets 的使命与愿景",
    "With years of expertise and experience in foreign exchange and financial products, our management team truly understands the demands of every trader, resulting in VT being a platform built for users by constantly improving on product optimization and service quality.": "凭借在外汇和金融产品方面多年来的专业知识和经验，我们的管理团队得以了解每个交易者的需求，因此 VT Global Markets 通过不断改进产品优化和服务质量而成为为用户构建的平台。",
    "Mission": "使命",
    "Our mission is to become the world class brokerage to provide the best trading environment for our users. We always value our users as our primary assets because VT is designed for users to have access to all the tools you need to succeed and to maximize your wealth and potential.": "我们的使命是成为世界级的经纪商，为我们的用户提供最佳的交易环境。我们始终把用户作为我们的主要资产，因为 VT Global Markets 旨在为用户提供获取成功所需的所有工具，并最大化您的财富和潜力。",
    "Vision": "愿景",
    "VT Global markets provides our valued customers with a leading-edge trading platform (MT5), offering them a great trading experience and environment. MT5 is the latest advancement in MetaQuotes technology that not only provides upgraded functionality and charting tools, but also allows brokers to connect with a few liquidity providers in order to provide the best available price feed to our investors.": "VT Global Markets为我们尊贵的客户提供领先的交易平台（ProTrader），为他们提供良好的交易体验和环境,不仅提供了升级的功能和图表工具，还允许经纪商与一些流动性供应商连接，以便为我们的投资者提供最佳的报价。",
    "Our Advantages": "我们的优势",
    "The right place for online trading on financial markets.": "在金融市场上进行在线交易的正确场所",
    'Expansive Range of Instruments': '广泛的产品范围',
    "A Selection of Trading Instruments": "一系列的交易种类",
    "Competitive Bid/Ask pip spreads": "有竞争力的买卖点差",
    "Leverage Up to 1:400": "高达1:500的杠杠",
    'Market-Leading Trading Platform': '引领市场的交易平台',
    "Fast execution": "快速执行",
    "Increased functionality and efficiency for traders": "为交易者提供更多的功能和效率",
    'Exclusive Rewards for Traders': '交易员的专属奖励',
    "Rebates per lot for traders": "交易员每手返佣",
    "Promotional campaign from time to time": "不定期的促销活动",
    "Regulation and License": "监管与合规",
    "Regulated and trusted, allowing you to trade with complete piece of mind": "受监管且值得信赖，让您安心交易",
    "VT Global Markets is authorized and regulated by multiple regulators across the globe": "VT Global Markets在全球范围内被多个监管机构授权和监管",
    "VT Global Markets is authorised and regulated by Vanuatu Financial Services Commission (VFSC) (Financial Services License ：VFSC 700 557 ).": "VT Global Markets 持有⽡努阿图⾦融服务委员会（VFSC）发出的⾦融 服务许可证（VFSC700557）。",
    "Client money held in segregated accounts with top-tier bank": "客户资金储存在一家顶级银行的隔离账户中。",
    "Client money is held in segregated accounts. Valk Trade banks with Westpac, which is one of four major banking organisations in Australia and has over 200 years of continuous service.": "客户资金存放在隔离账户中。VT Global Markets与澳大利亚四大主要银行之一的Westpac银行合作，该银行服务历史超过200年。",
    "Professional indemnity insurance to protect client's financial security": "专业责任保险用于保护客户的财政安全。",
    "VALK TRADE operates with adequate Professional Indemnity Insurance (PI insurance) in place. Our PI insurance is underwritten by Falcon Insurance, which received its insurance license in 2013. PI insurance is a safe net that covers the costs and expenses of responding to claims arising from professional services.": "VT Global Markets 已经配置了充分的专业责任保险（PI保险）。我们的PI保险由Falcon Insurance提供，该公司于2013年获得了保险执照。PI保险是一种安全网，涵盖了应对专业服务引发的索赔所需的成本和费用。",
    "Liquidity Partner": "流动性合作伙伴",
    "Trusted liquidity partner to ensure your trading comfort and speed": '值得信赖的流动性合作伙伴，确保您的交易舒适度和速度',
    "VT is pleased to announce the alliance with": "VTGM 很高兴地宣布与",
    "GBE Prime": "GBE Prime",
    "in terms of strategic STP liquidity partner.": "在战略 STP 流动性供应商方面的联盟。",
    "GBE Prime is a fully regulated broker and liquidity provider offering aggregated, institutional liquidity to brokers, banks, funds and asset managers operating in a regulated environment.": "GBE Prime 是一家规范的监管经纪商和流动性提供商，它向在监管环境中经营的经纪商、银行、基金和资产经理提供流动性集合。",
    "GBE Prime is a brand of GBE brokers and focuses exclusively on institutional business activities.": "GBE Prime 是一个 GBE 旗下的经纪品牌，专注于机构业务。",
    "GBE Prime is offering advanced, aggregated liquidity in the Equinix Datacenters in LD4 (London), NY4 (New York) and TY3 (Tokyo), using state of the art bridging and aggregation software, ultra-low-latency connectivity and institutional grade hosting solutions.": "GBE Prime 通过在 LD4（伦敦）、NY4（纽约）和 TY3（东京）的 Equinix 数据中心，以及最先进的桥接和集合软件、超低延迟的桥接技术和机构级托管解决方案，为机构提供先进的流动性集合。",
    "The Edge of GBE Prime": "GBE Prime 的优势",
    "One of the TOP 50 players in the worldwide eFX market": "世界外汇市场排名前 50",
    "Excellent trading conditions": "卓越的交易条件",
    "Fully regulated investment firm": "完全受监管的投资公司",
    "German management with German understanding of operating in business": "具有德国人对商业运作理解的德国管理层",
    "Ultra-flexible account setups and individual conditions": "超灵活的账户设置和个人条件",
    "World-wide liquidity hubs with server locations in Equinix LD4 (London), NY4 (New York) and TY3 (Tokyo) datacenters.": "世界范围的流动性集合中心，通过在Equinix LD4（伦敦）、NY4（纽约）和 TY3（东京）的数据中心提供先进的流动性集合。",
    "Dispute Resolution": "争端解决",
    "Your satisfaction is what we care about.": "您的满意程度，是我们所关注的",
    "VT Global markets seeks to develop a stable and long-term relationship with its clients.": "VT Global Markets 寻求与客户建立稳定和长期的关系。",
    "We are constantly striving to provide better service and accomplish values in order to exceed clients’ expectations with additional service assistance. In line with this, we regard our clients’ suggestions, opinions and concerns to be of the utmost significance. We also acknowledge that a client’s dissatisfaction is an opportunity for us to improve by enhancing our products and quality of services.": "我们一直致力于提供更好的服务，实现价值，以超越客户的期望，并提供额外的协助服务。 因此，我们认为客户的建议、意见和关注是最重要的。 我们也认同客户的不满是我们通过提高我们的产品和服务质量而改进的机会。",
    "If I have a complaint, what should I do?": "如果我想投诉，我应该怎么做？",
    "In the unlikely event that you are dissatisfied with the services provided by VT Global markets, please contact your Account Manager as soon as possible or email us with the process outlined below. This will help us to resolve your complaint in the most efficient way possible in order to regain your confidence.": "如果您对 VT Global Markets 提供的服务不满意，请尽快联系您的客户经理或按照以下流程向我们发送电子邮件。这将有助于我们以最有效的方式解决您的投诉，重获您的信任。",
    'Gather all the relevant supporting documents relating to your complaint': '收集所有与您的投诉有关的支持性文件',
    'Consider the issues you would like to be addressed and what you would like us to do': '考虑您希望解决的问题以及您想要的处理方案',
    'Contact your Account Manager directly': '直接联系您的客户经理',
    'Should your issue persist, please contact us as early at your earliest convenience to allow us to resolve your complaint immediately. If your account manager is unable to resolve your complaint satisfactorily, please request that your complaint be forwarded to the management. You can also contact us directly by sending an email.': '如果您的问题仍然存在，请尽早联系我们，以便我们立即解决您的投诉。如果您的客户经理无法满意地解决您的投诉，请要求将您的投诉转发给管理层。您也可以通过发送电子邮件直接与我们联系。',
    'ALK TRADE GLOBAL LIMITED trading as VTGM, is authorized and regulated by the VANUATU FINANCIAL SERVICES COMMISSION, No.700557': 'VALK TRADE GLOBAL LIMITED以VTGM的名义进行交易，受瓦努阿图金融服务委员会授权和监管，编号700557。',

    //Research
    "Analytical tools": "分析工具",
    "We make sure you have access to the top fundamental and technical analysis tools so you can plan your trades with confidence.": "为帮助您更加得心应手地规划交易，我们为您提供了顶尖的基本面和技术面分析工具。",
    "Calculator": "交易计算器",
    "Symbol": "货币",
    "Leverage": "交易杠杆",
    "Account Currency": "存款货币",
    "Volume, Lots": "交易量，手数",
    "Calculate": "计算",
    "Calculation Results": "计算结果",
    "Pip Value": "点值",
    "Required Margin": "要求保证金",
    "Trading Fees": "交易手续费",
    "Economic Calendar": "经济日历",
    "Keep track of high impact news, market-moving economic events and data releases with our Economic Calendar.": "使用我们的经济日历，追踪重磅新闻、市场大事件和数据发布。",
    "We know it is important to be updated about economic and market events when trading, so the Real-time Economic Calendar is available to all VTGM clients.": "我们知道最新的金融事件和市场信息在交易时极为重要，因此所有 VTGM 客户都可以使用实时经济日历。",
    "Our calendar keeps VTGM clients informed and allow them to monitor market activities in 38 different countries, by day, week, month or selecting a time range.": "我们的日历可以帮助 VTGM 客户随时了解市场最新情况，客户可按日，周，月或选择时间范围筛选查看 38 个不同国家的市场活动。",
    "The calendar is available below and inside VTGM Client's Portal.": "该日历位于 VTGM 客户门户下方和内部。",
    "Market Sentiment Tool": "市场情绪",
    "Understanding the critical role of timely information, VTGM offers our clients access to the most sophisticated tools available. Market Buzz stands out with its intuitive design and user-friendly interface. It presents a clear and precise snapshot of market sentiment for each instrument by aggregating daily insights from professional news articles, social media and individual blogs.": "VTGM 了解及时信息的关键作用，为我们的客户提供最先进的可用工具。 Market Buzz 以其直观的设计和用户友好的界面而脱颖而出。它通过汇总来自专业新闻文章、社交媒体和个人博客的每日见解，清晰准确地呈现了每种工具的市场情绪。",
    "Unlock the full potential of Market Buzz by logging in or signing up today.": "立即登录或注册即可获得 Market Buzz 的使用权限。",
    "Sign Up": "注册",

    //Partnership
    "Partnership Opportunities": "合作机会",
    "Collaborate": "合作",
    "Develop": "发展",
    "Consistent": "一致",
    "Why Affiliate With Us": "为什么与我们合作",
    "VT provides a series of excellent reward programs for introducing brokers (IBs) who put effort into introducing business to us. Profit on reward has no restrictions for IBs, and it is open to anybody who wants to participate. This unique IBs program offered by us is strongly supported by our dedicated and experienced account managers.": "VTGM为致力于向我们介绍业务的介绍经纪人（IBs）提供一系列卓越的奖励计划。 奖励的利润对经纪人没有任何限制，并且开放给任何想要参与的人。 VTGM 提供的独特经纪人计划得到了我们专业和经验丰富的客户经理的大力支持。",
    'Instant Services Support from Us': '我们提供及时的支持服务',
    'We are constantly striving to provide better service and accomplish values in order to exceed our clients’ expectations with additional service assistance. Our service assistance comprises a robust customer service support team available 24 hours a day, 5.5 days a week, as well as customised account management for IBs, Market Outlook, Analysis Tools, Online Guides and Education.': '我们不断努力提供更好的服务、增加价值并通过附加服务超越客户的期望。我们的支持服务包括强大的一周 5.5 天 24 小时的客服协助团队和个性化的客户管理、市场洞察、分析工具、在线指南和 IB 培训。',
    'Seminars And Webinars by Professionals': '专业人士举办的研讨会和网络研讨会',
    'Growing a healthy business is always the goal of our company, and we provide our best assistance from all possible perspectives. We provides executive seminars and webinars to our valued investors on a regular basis. Furthermore, for events and webinars, We combines the strong outsourced support of subsidised personnel and experienced FX industry speakers.': '健康蓬勃发展业务始终是我们公司的目标，我们从各个可能的角度提供最好的帮助。定期为我们尊贵的投资者提供高管研讨会和网络研讨会。',
    'Exclusive Rewards & Privileges': '专属奖励和优惠',
    'All our clients and IBs are valuable assets in our long-term partnership. Hence, we provides a variety of rewards for introducing brokers which include commission rebates from trading, achievement rewards and other sorts of gifts for certain important occasions.': '我们所有的客户和经纪人都是我们长期合作关系中的宝贵资产。因此，我们为经纪人提供了多种奖励，包括交易佣金返利、业绩奖励以及在某些重要活动的赠品。',
    'To Be Part Of Our Family Member': '成为我们联盟成员的一份子',
    'Join our affiliate program to earn unlimited potential earnings': '加入我们的联盟计划，赚取无限的潜在收入',
    'Personalized account managers and instant service support': '个性化的客户经理和即时客服协助',
    'Attractive incentives & team support for IBs looking to expand their market and increase client conversion': '为寻求扩大市场和提高客户转化率的经纪人提供有吸引力的奖励和团队协助',
    "Surprising promotional plans and innovative marketing materials to facilitate affiliates' marketing efforts": "独特的促销计划和创新的营销资料，以促进联盟的营销活动",
    "It is simple to become an Introducing Broker (IBs)": "成为介绍经纪商 (IB) 非常简单",
    "It takes less than 5 minutes to GET STARTED! You may sign up online here or follow the steps below to sign up online.": "只需不到 5 分钟即可开始！您可以在此处在线注册或按照以下步骤在线注册",
    'Sign Up Here': '在此注册！',
    'Fill up all the relevant information': '填写所有相关信息',
    'Send your registration form to (Our email address)': '将您的注册表发送至（我们的电子邮件地址）',
    'Receive your agent code and start earning your commission': '收到您的代理代码并开始赚取佣金',
    "If you are interested in becoming one of our  Introducing Broker, please contact us!": "如果您有兴趣成为我们的经纪人之一，请与我们联系！",
    "Contact Us": "联系我们",

    //referral program
    "Introducing Broker Program": "经纪人计划",
    "Get up to 40% of our revenue. You will receive a portion of VTGM’s revenue from the trading activity of every active client you refer to us. This can go as high as 40% on every trade they make.": "最高可获得 40%的收益分成。您将从您推荐给我们的每个活跃客户的交易活动中获得最高40%的VTGM 收益分红。",
    "The more clients you sign up, the greater your income": "您推荐的客户越多，收益就越多。",
    "Drag the bar to see the average monthly income you can make based on the total amount of traders you introduce to VTGM.": "拖动滑块，根据你介绍给VTGM的交易者总数，查看你可以赚取的平均每月收入。",
    "Total Accumulated Clients": "累计客户总数",
    "Your income per month": "您每月的收入",
    "Why become an Introducing Broker?": "为什么要成为介绍经纪商（IB）？",
    'Daily payouts & instant withdrawals': '利润日支付，即时出金',
    'We pay your profit daily into your account and you can access your money instantly, whenever you want.': '我们每日结算并支付利润到您的账户，您可以随时立即提取资金',
    'Promotional tools & materials': "推广工具和素材",
    'We provide all the tools you need to promote our products, including website banners, advertising, landing pages.': "我们提供一切推广我们产品所需的工具与素材，包括网站横幅、广告，网页登陆页面",
    'Detailed, real-time statistics': '详尽、实时的数据',
    "We provide reporting tools that show detailed, real-time statistics on every client's transactions in your Personal Area.": "我们在个人专区提供报表制作工具，可为您提供您每位客户交易的具体实时数据",
    'Trusted global market leader': '值得信赖的全球市场领导者',
    'Join a team of professionals who operate in 150+ countries and offer a full range of financial instruments with competitive trading conditions.': '我们的专业团队遍布超过 150 个国家，提供各种金融品种和具有竞争力的交易条件。',
    "Not getting enough?": "还不够吗？",
    "Get More When You Become An Introducing Broker With VTGM": "成为 VTGM 的介绍经纪商（IB）后获得更多",
    'Add on additional spread': '添加额外的点差',
    'Aside from the rebate, IB can choose to add on additional spreads to obtain higher income.': '除了回扣之外，IB还可以选择添加额外的点差以获得更高的收入。',
    'Automated Rebate system': '自动回扣系统',
    "Strong IB Client management system, enabling rebate tracking in real-time, your rebate will arrive in with no commission cap.": "强大的IB客户管理系统，实时追踪返佣，返利到账无佣金上限。",
    '1 on 1 Dedicated account manager': '1对1专属客户经理',
    'Dedicated service, efficiently communicating all queries with your account manager.': '竭诚服务，与您的客户经理有效沟通所有疑问。',
    'Multi-level Rebate': '多级返利',
    'Customise your Rebate, set up multi-level rebate to obtain more clients.': '定制您的返利，设置多级返利以获得更多客户。',
    'Exclusive online promotions': '独家网上促销',
    'Prospero Provides a large variety of bonus and gifts, which both retail and IB can participate to stimulate their trading passion.': 'VTGM 提供种类丰富的奖金和礼品，零售和IB都可以参与，激发他们的交易热情。',
    "Affiliate Program": "联盟计划",
    "We are leading the industry with scheduled and attractive payouts. Drive traffic to VTGM and we'll give you commission up to $1850 for each user that becomes an active client.": "我们的佣金报酬丰厚，且定期支付，极具市场竞争力。为 VTGM引流，每成功转化一位活跃客户，即有机会获得高达 1850 美元的佣金。",
    "What We Offer": "我们提供什么",
    "We pay for first-time deposits (CPA)": "我们支付首笔存款 (CPA)佣金",
    "Up to $1850 based on the country, platform, and the size of deposit your referral makes": "最高可达 1850 美元，具体金额取决于国家/地区、平台和您推荐用户的存款金额。",
    "Minimum first-time deposit is $10": "首次存款最低金额为 10 美元",
    "We accept traffic from more than 130 countries": "我们接受来自 130 多个国家的流量",
    "Daily payouts": "每日支付",
    "Extra bonuses for the best performance": "最佳业绩还会有额外奖励",
    "Fund Manager": "基金经理",
    "Our industry leading MAM (Multi Account Manager) and PAMM (Percentage Allocation Management Module) program is designed for qualified and experienced fund managers and each account has dedicated senior account managers to assist you.": "我们行业领先的MAM（多账户经理）和PAMM（百分比分配管理模块）计划专为合格且经验丰富的基金经理而设计，每个账户都有专门的高级客户经理为您提供帮助。",
    'Trade Well & Get Rewarded': '易出色，获得回报',
    'Whether you run an investment firm or are a professional trader, you can utilize VTGM’s Multi Account Manager to manage funds on behalf of your clients. Our MT5 Multi Account Manager allows you to trade on your favourite MT5 while simultaneously trading on every one of your client’s accounts.': '无论您是经营投资公司还是专业交易员，您都可以利用VTGM的多账户管理器代表客户管理资金。我们的 ProTrader 多账户管理器允许您在 ProTrader 上进行交易，并同时为每个客户账户进行交易。',
    'Streamlined, paperless client onboarding': '精简高效、无纸化的客户注册流程',
    'Give your clients the absolute best experience with our streamlined, paperless onboarding process. Whether you’re bringing on 10 or 1,000 clients, our powerful onboarding system will ensure you can focus on trading while we handle the onboarding.': '通过我们高效、无纸化的注册流程，为您的客户提供最佳体验。无论您要引入10位还是1,000位客户，我们强大的注册系统将确保您专注于交易，而我们负责处理注册流程。',
    'Customisable Fee Structures': '可定制的费用结构',
    'We understand that every relationship is unique and every client has different expectations. Easily customise the fee structures to suit different clients and even create your own tiers for various groups of clients.': '我们理解每段关系都是独特的，每位客户都有不同的期望。轻松定制费用结构，以满足不同客户的需求，甚至为不同客户群体创建您自己的费用层级。',
    "Franchise Model": '特许经营模式',
    "Our franchise program enables ambitious individuals and organizations to partner with VTGM with an aim to bring clients and business to the table.": "我们的特许经营计划使雄心勃勃的个人和组织有机会与 VTGM 合作，旨在为客户和业务创造机会。",
    "The franchise would be entitled to set up their own office as per VTGM’s branding requirements and would be expected to provide personalised support to the clients that it has brought through the doors at VTGM.": "特许经营者有权按照VTGM的品牌要求建立自己的办公室，并应为通过VTGM引入的客户提供个性化支持。",
    "This would require the franchisee to have some business experience as well as capital. Franchisees can market VTGM’s entire range of products.": "这需要特许经营者具备一定的商业经验和资金。特许经营商可以销售 VTGM 的全系列产品。",
    "This partnership will be centred around the introduction of clients and development of business by the franchisee for VTGM which will be rewarded with healthy commissions and benefits based on business volume.": "此次合作将围绕 VTGM 特许经营商引入客户和开发业务为中心，特许经营商将根据业务量获得可观的佣金和福利。",

    //account-overview
    "Accounts Overview": "账户概述",
    "Which account is the best fit for me?": "哪个帐户最适合我？",
    "Your trading journey begins with a trading account. We offer different account types to suit your trading needs and experience. Refer to the table below to find out which account is best for you.": "您的交易之旅始于一个交易账户。我们提供不同的账户类型以满足您的交易需求和体验。请参阅下表，找出最适合您的帐户。",

    "STANDARD ACCOUNTS": "标准账户",
    "We designed a few categories of standard accounts comprising Silver, Gold and Diamond for investors. These standard accounts allow investors to trade smaller trade sizes in Forex and Precious Metals while enjoying cashback, bonuses and special trade.": "我们为投资者设计了几类标准账户，包括 白银、黄金 和 钻石。这些标准账户允许投资者在外汇和贵金属中进行小规模的交易，同时享受现金返还、赠金和特殊交易。",
    "Cashback": "返佣",
    "Bonus": "赠金",
    "Trade": "特殊交易",
    "Market execution with tight spreads.": "小点差的市场执行服务。",
    "SILVER": "白银",
    '1 USD/lot': "1美元/手",
    'Nil': '无',
    'GOLD': '黄金',
    '2 USD/lot': '2美元/手',
    'DIAMOND': '钻石',
    '3 USD/lot': '3美元/手',
    "Deposit and Withdrawal": "存款和提款",
    "Experience the convenience and security of VTGM deposit and withdrawal processes. We offer local, convenient, and safe payment methods, ensuring seamless transactions from start to finish.": "体验VTGM存款和提款交易的安全性和便捷性。 享受安全、便捷的无缝本地支付方式。",
    'Frictionless Transactions Every Step of the Way': '尽享全程丝滑零干扰体验',
    'Enjoy our unmatched payments ecosystem: seamless deposits through global and local payment systems, with 24/7 access and hassle-free fund release.': '享受我们无与伦比的支付生态系统：全球与本地支付系统确保无缝存款体验，24小时随时轻松提取资金。',
    'Diverse Payment Methods for Your Convenience': '多种支付方式更加便捷',
    'Choose from a variety of secure, global, and local payment options for straightforward deposits and withdrawals.': '从各种安全的全球和本地支付选项中进行选择，以实现简单的存款和提款。',
    'Unrestricted Access to Your Funds': '您的资金，您做主',
    "Withdraw your funds anytime, instantly with our instant withdrawal feature. Say goodbye to withdrawal fees, we cover third-party transaction costs for you.": "通过我们的即时提款功能，您可以随时提取您的资金。告别提款费用, 我们为您承担第三方交易手续费。",
    'Your Funds Are Secure With Us': '您的资金在我们这里安全无虞',
    "As the world's leading retail multi-asset broker, we implement multiple layers of security to ensure your funds are safe and readily accessible upon request.": "作为全球领先的多资产零售经纪商，我们实施多重安全措施，以确保您的资金安全并可根据要求随时访问。",
    'Segregated Accounts for Enhanced Security': '隔离帐户以增强安全性',
    'Your funds are held in segregated accounts across multiple tier-1 banks, offering you the highest level of security and peace of mind.': '您的资金将存放在多个相互独立的一级银行账户中，为您提供最高级别的安全性和安心感。',
    'Secure Withdrawal Transactions': '安全提款交易',
    'Rest assured, your withdrawals are protected with one-time password verification, ensuring safety and security.': '请放心，您的提款受到一次性密码验证的保护，确保安全可靠。',
    "Payment Options": "存款方式",
    "EFT | Electronic Fund Transfer (Recommended)": "EFT – 电子资金转账（推荐）",
    "Execution Time": "执行时间",
    "Up to two business days.": "最多两个工作日。",
    "Cost": "费用",
    "No charge .": "不收费。",
    "USDT Payment": "USDT支付",
    "Acceptance": "接受",
    "We accept USDT payments through BTC, ERC20, or TRC channels.": "我们接受通过 BTC、ERC20 或 TRC 渠道进行的 USDT 付款。",
    "Execution Time": "执行时间",
    'Deposits are processed within 5-10 minutes, while withdrawals take 1-2 working days.': '存款在 5-10 分钟内处理，提款则需要 1-2 个工作日。',
    "Fees": "费用",
    'No transaction fee is charged.': '不收取交易费。',

    //platform
    "Building on the esteemed legacy of its predecessor, MetaTrader 5 (MT5) enhances your trading experience by offering additional trading timeframes, improved order flexibility, and accelerated strategy testing speeds.": "ProTrader 是全世界最热门最被广泛使用的交易平台。具有更先进的功能，增加了可用的技术分析工具的数量，更多的图表和时间框架选项，同时提升了交易时的速度及稳定性，进一步优化了用户的交易体验。",
    "A platform for every device": '一个拥有极强通用兼容性的交易平台',
    "Echoing the versatility of its predecessor, MT5 facilitates trading both at your desk and on the move, ensuring seamless market access regardless of your location.": 'ProTrader 延续了其前身的多功能性，可以透过电脑，网页，手机，其他等移动版本，各个端口连接并进行交易，确保无论您身在何处都能无缝进入市场。',
    'Depth of Market for Enhanced Visibility': '提高市场深度可见度',
    'The MT5 Depth of Market (DoM) tool enhances market transparency, allowing traders to view the volume of trades available at different prices, thereby gaining deeper insights into market liquidity.': 'ProTrader的市场深度（DoM）提升了市场透明度，让交易者能够查看不同价格下可交易的成交量，从而更全面地了解市场的流动性情况。',
    'Advanced Strategy Tester for Optimal EA Performance': 'EA 智能交易策略优化测试程序',
    "Optimize your Expert Advisors (EAs) with MT5's multi-threaded strategy tester, designed for more efficient backtesting and refinement of your trading strategies.": "使用ProTrader的多线程策略测试优化您的专家顾问（EAs），更高效地进行交易策略的回测和优化。",
    'Expanded Timeframes for Comprehensive Market Analysis': '支援多個時間框架选择以更全面地了解市场动态',
    "With 21 different timeframes, MT5 broadens your market analysis capabilities, offering varied perspectives to inform your trading decisions.": "ProTrader 共有 21 个时间框架，交易者可以進行不同層次的技術分析更好地理解市場走勢，辅助您更好地完成您的交易决策。",
    'Enhanced Order Flexibility for Precise Trade Execution': '增强订单灵活性以实现精确的交易执行',
    "MT5 introduces two additional pending order types, granting you greater control over your trade entries and management.": "ProTrader增加了两种挂单类型，让交易者能够更好的管理及控制交易订单。",
    "WEBTRADER": "线上交易平台",
    "Trade up with our high-speed customized web app": "使用我们高速的线上交易平台进行交易",
    "Enjoy seamless and flexible trading on the Webtrader, a secure and streamlined web trader platform for trading over a hundred popular CFDs.": "在 Webtrader 上享受无缝、灵活的交易，这是一个安全、简化的网络交易平台，可交易一百多种热门的差价合约。",
    'Watchlist': '关注列表',
    'Access real-time prices and market movements with the Webtrader watchlist, featuring a comprehensive list of all available trading instruments.': '通过 Webtrader 观察列表访问实时价格和市场走势，其中包含所有可用交易工具的综合列表。',
    'One-Click Trading Mode': '一键交易模式',
    'Instantly open and close orders by selecting from a list of instruments and utilizing the Limit Order field for setting up pending orders, all with just one click.': '通过从工具列表中进行选择并利用限价订单模式设置挂单，立即打开和关闭订单，所有这些只需单击一下即可。',
    'Portfolio Area': '投资组合区',
    "Monitor your orders in the portfolio section, which provides detailed information such as order type, volume, prices, take profit, stop loss, open time, swap, and profit and loss.": "在投资组合区监控您的订单，该部分提供订单类型、交易量、价格、止盈、止损、开仓时间、隔夜利息以及损益等详细信息。",
    'Instruments Tabs': '工具列表',
    "Select from a variety of chart types and enhance them with indicators, drawing tools, and more. Charting capabilities are powered by TradingView, ensuring top-notch visualization and analysis tools.": "选择各种图表类型，并通过指标、绘图工具等进行增强。图表功能由TradingView提供支持，确保拥有最先进的可视化和分析工具。",
    'Trading Account Management': '交易账户管理',
    'Manage your account effortlessly, viewing details like trading history, balance, equity, margin, free margin, margin level, and leverage settings in real-time.': '轻松管理您的账户，实时查看交易历史、余额、净值、保证金、可用保证金、保证金水平和杠杆设置等详细信息。',
    'Account Types': '账户类型',
    'The Webtrader is designed to accommodate the flexibility required by traders, compatible with all trading account types supported by MetaTrader 5, ensuring a comprehensive trading experience across different strategies and preferences.': 'Webtrader 旨在满足交易者所需的灵活性，确保不同策略和偏好的全面交易体验。',
    "Provide an opportunity for clients to earn profits without the need to acquire technical skills.": "为客户提供一个无需掌握专业技能也能赚取收益的机会。",
    "Make your INVESTMENT Easier and Smarter": "让您的投资更简单、更明智",
    "Undoubtedly, the financial market has a huge potential for profit. However, it is understandable that it requires time and effort to polish your skills and acquire relevant experiences, as well as time to be spent on keeping track of the market. Do you know? It’s not a problem! If you don’t feel like doing it on your own, we have the perfect solution for you!": "毋庸置疑，金融市场具有巨大的盈利潜力。然而，可以理解的是，您需要时间和精力来打磨您的技能和获得相关的经验，同时也需要花时间来追踪市场。您知道吗？这不是一个问题！如果您不愿意自己动手，我们为您提供完美的解决方案！",
    "Our": "我们的",
    "PAMM System": "PAMM 系统",
    "We have launched a PAMM system that allows you to access different types of trading opportunities without the need to acquire technical skills, and it can be done in a short period of time. As soon as you have chosen a MASTER suited to your needs and invest in the pooled funds, you can enjoy your investment journey as your funds are managed by your chosen master, while you have complete control over your money. Most importantly, you’ll only have to pay a fraction of the profits generated by the master.": "我们推出了一个 PAMM 系统，使您无需掌握技术技能就能获得不同类型的交易机会，而且可以在短时间内完成。只要您选择了适合您的基金经理人，并投资于集合资金，您就可以享受您的投资之旅，因为您的资金将由您选择的基金经理人管理，而您可以完全的控制您的资金。最重要的是，您只需支付基金经理人产生的利润的一小部分。",
    "Our PAMM system is a bridge that connects both the Investor and the Master at the trading platform, allowing you to invest quickly and easily in only five steps.": "们的PAMM系统是在交易平台上连接投资者和基金经理人的桥梁，让您只需五个步骤就可以快速、轻松地进行投资。",
    "Open Account": "开立账户",
    "Make a Deposit": "进行存款",
    "Select Master": "选择专业基金经理人",
    "Cash in when profit": "盈利时套现",
    "Profit Sharing": "利润分享",
    "copy trading": "跟单交易",
    "START COPYING AND EARNING WITH PRO TRADERS": "开启跟单交易，跟随专业的交易员一起赚取盈利",
    "VTGM Copytrading lets you copy expert traders automatically, saving you the time and effort of creating your own trading strategy. Choose from the best Masters of Forex and diversify your trading portfolio.": "VTGM 的跟单交易可让您自动跟随专业交易员的交易策略，从而节省您创建自己的交易策略的时间和精力。从最优秀的外汇交易大师中进行选择，使您的交易投资组合多样化。",
    "Realise the advantages of being a Copier": "发现作为跟单者的好处",
    'Learn From The Best': '向最出色的交易员学习',
    'Enter the Forex market with pros.': '跟随专业交易员一起进入外汇市场。',
    'Diversify your portfolio': '多元化你的投资组合',
    'Choose professionals you trust from a large number of Master Traders.': '从众多交易大师中选择您信任的专业交易员。',
    'Enjoy fast order execution': '享受快速的订单执行',
    "Your order is executed less than 5ms after the Master Trader's one.": "您的订单将在交易大师下单后 5 毫秒内执行",
    'Keep everything under control': '让一切都在掌控之中',
    'You can stop copying, unsubscribe, or close an order anytime.': '您可以随时停止跟单、取消订阅或关闭订单。',
    'Set it running in a few quick steps': '只需几个步骤即可快速的设置并运行',
    'No additional verification required.': '无需额外验证。',
    'Invest and withdraw safely and quickly': '安全、快捷的投资和提款',
    'Popular payment methods to choose from.': '多种主流支付方式可供选择。',

    //product 
    "The Ultimate Trading Experience": "极致的交易体验",
    "A professional trading environment that priorities users trading experience, giving users access to opportunities across the financial markets. We offers various resources and tools needed for users to success in their trading careers. Registered and regulated in Vanuatu have made us the most trustworthy and secure brokers among others.": "我们提供专业的交易环境，优先考虑用户的交易体验，让用户能够在金融市场中获得机会。我们提供各种资源和工具，帮助用户在交易生涯中取得成功。作为瓦努阿图注册和监管的经纪商，我们是市场上最值得信赖和安全的选择。",
    "Diversify Your Trading Portfolio With Our Trading Products": "通过我们的交易产品实现投资组合多样化",
    "Trade CFDs on Forex, Indices, Commodities, Equities & ETFs, or subscribe to our PAMM service.": "通过我们的交易产品多样化您的交易投资组合，交易外汇、指数、商品、股票和 交易所交易基金的差价合约，或订阅我们的 PAMM 服务，增加灵活性和潜在回报。",
    "CFD": "CFD",
    "What Does CFD Stands For?": "CFD 是什么？",
    'It means "contract for difference". These contracts track spot markets and are attractive to small investor because they do not require you to purchase the underlying asset.': 'CFD意为“差价合约”（Contract for Difference）。这些合约跟踪现货市场，并且对小投资者具有吸引力，因为它们不需要您购买基础资产。',
    'You only have to pay the price difference from when you open a trading position to when you close it. Also, you can use leverage, which can help increase the size of your positions even if you have limited capital.': '您只需支付从开仓到平仓的价差。此外，您可以使用杠杆，即使您的资本有限，这也可以帮助增加您的头寸规模。',
    "But what are CFDs in trading? Here is a look at the benefits of CFD trading and the best ways to engage is CFD trading.": "但交易中的差价合约是什么？以下是差价合约交易的好处以及参与差价合约交易的最佳方法和介绍。",
    "What is CFD Trading?": "什么是差价合约交易？",
    "Going Short Or Long With CFDs": "做空或做多差价合约",
    "Going Long": "做多差价合约",
    'When you buy a CFD, you "go long", meaning you make profits when the underlying market rises. A CFD long position may be more common, but you can also take the other side of the trade.': '当您购买差价合约时，您选择“做多”，这意味着当基础市场上涨时您将获利。差价合约多头头寸可能更为常见，但您也可以选择另一侧进行交易。',
    "Going Short": "做空差价合约",
    'When you take the "sell" side, you "go short", taking a short CFD position. If you take a short CFD position, you can make profits if the underlying market goes down.': '您选择“做空”，持有空头差价合约头寸。如果您持有差价合约空头头寸，那么当基础市场下跌时，您也能获利。',
    "CFD Trading": "差价合约交易",
    "Long": "做多",
    "Short": "做空",
    "Trade": "交易",
    "Buy": "购买",
    "Sell": "卖出",
    "Profit": "获利",
    "Market Goes Down": "基础市场下跌",
    "24-hour Market": "24 小时的市场",
    "Yes": "是",
    "Automatic Trading": "自动交易",
    "Available": "支持",
    "The ability to easily go long or short can potentially double the number of trading opportunities for traders who would otherwise only be able to go long with other assets.": "能够轻松实现对多种资产进行做多或做空的能力，相对于其他交易者来说，等同于可以将交易的机会数量翻倍。",
    "CFD trading often involves leverage, which grants traders more significant exposure to large positions without having to hand over the full cost at the time of trading.": "差价合约交易通常涉及杠杆，这使交易者可以更大程度地持有大额头寸，而无需支付全部成本。",
    "This allows traders to increase the size of a CFD position even if they have limited capital. However, increasing the size of your position with borrowed capital can also expose you to magnified profits and losses alike.": "这使得交易者即使资金有限也可以增加差价合约头寸的规模。然而，利用借入资金增加头寸规模也会让您面临放大的利润和亏损。",
    "How CFDs work": "差价合约如何运作",
    "Here is what happens when you initiate a CFD trade on our website.": "以下是您在我们的网站上发起差价合约交易时会发生的情况。",
    'The trader opens a position and either pays or receives money based on the market movements.': '交易者开仓并根据市场走势支付或接收资金。',
    'If the market goes down, you must pay the difference between the opening and closing price.': '如果市场价格下跌，交易者需要支付开仓价和平仓价之间的差额。',
    'If the underlying asset increases in price, the broker pays the difference to the trader.': '如果基础资产的价格上涨，经纪商将向交易者支付差额。',
    "Benefits of CFD Trading": "差价合约交易的优势",
    "Low Trading Cost": "交易成本低",
    "A major benefit of CFD trading, especially when compared to stocks, is that they have a significantly lower cost. There are three costs involved in CFD trading:": "与股票相比，差价合约交易具有显著较低的成本优势，在差价合约交易中涉及以下三项成本：",
    'Spread': '点差',
    "This is the difference between the buy and sell price. The buying price is always lower than the selling price, leaving the market price in the middle. The tighter the spread, the more value you can get. That's why we provide products that spread from 0.0 pips.": "这是买入价和卖出价之间的差价。买入价始终低于卖出价，市场价格处于中间位置。点差越小，交易者可以获得的价值就越大。因此，我们提供的产品的点差从0.0点起。",
    'Commission': '佣金',
    'Providers will charge a commission when a trade takes place on their platform. Liquidity providers also charge brokers.': "当交易在其平台上发生时，供应商将收取佣金。而流动性提供者也会向经纪商收取佣金。",
    'Swaps': '隔夜利息',
    "This is the leverage cost, a daily cost that is added or subtracted from an account to pay for leverage.": "这是杠杆成本，是为支付杠杆而从账户中增加或减少的每日成本。",
    "Make Your Capital Go Further": "提高您的资金使用率",
    "Leverage trading is another advantage of CFDs. When trading with leverage, you can increase the size of your position, even if you don't have the required upfront capital. This means that if you were to buy 200 shares of anything, you wouldn't have to pay the full cost immediately.": "杠杆交易是差价合约的另一个优势。使用杠杆交易时，即使您没有足够的初始资本，也可以增加您的头寸规模。这意味着，即使您想购买200股的某个股票，您也不必立即支付全部成本。",
    "Leveraged CFD trading allows you to increase the volume of profits or losses without the initial cost that a traditional trade would require. In certain circumstances, you could control $30 for every $1 in your account. Other significant benefits CFDs have that other asset classes lacks including:": "通过杠杆差价合约交易，您可以增加利润或亏损的数量，而无需承担传统交易所需的初始成本。在某些情况下，您可以控制每1美元账户资金，交易价值高达30美元。差价合约具有其他资产类别所缺乏的其他显着优势，包括：",
    "Global accessibility to investors with limited capital.": "即使资金有限也能在全球范围进行投资。",
    "Access to automatic and algorithmic trading.": "可以访问自动和算法交易。",
    "Go long or short on a market's direction.": "可以根据市场方向做多或做空。",
    "Trade across various markets, including shares, forex, precious metals, energies, indices and stock indices. 24-hour, 5-day-a-week trading across several markets.": "可以跨多个市场进行交易，包括股票、外汇、贵金属、能源、指数和股票指数。每周5天、每天24小时都有交易市场。",
    "To learn more about how CFDs stack up against other assets classes, like stocks, check out Our Blog.": "如欲了解CFD与其他资产类别（如股票）的比较，可联系我们的客户经理。",
    "Assets to trade with CFDs": "可用于差价合约交易的资产",
    "What is a CFD trading market option?": "什么是差价合约交易市场期权？",
    "CFDs track many different asset classes. Here is what you can trade with a quality broker:": "差价合约交易市场提供了多种不同的资产类别。以下是您可以通过优质经纪商进行交易的资产：",
    'Forex': '外汇',
    'Currency CFDs track forex pairs. A regulated CFD broker like TMGM offers access to major pairs like EUR/USD and AUD/USD, as well as minor pairs and exotic currencies.': '货币差价合约追踪外汇货币对。如欧元/美元和澳元/美元等主要货币对，像 VT Global Markets 这样受监管的差价合约经纪商提供了对主要货币对以及次要货币对和外国货币的交易。',
    'Shares': '股票',
    'Share CFDs track major stocks like Apple, Tesla, Amazon, Netflix and Baidu. Since they track spot markets, CFDs do not have the same divergennt pricing features that make futures and options challenging to trade..': '股票差价合约追踪苹果、特斯拉、亚马逊、Netflix 和百度等主要股票。由于它们追踪现货市场，差价合约不具有使期货和期权交易具有挑战性的不同定价特征。差价合约可用于日内交易或用于防御通胀和波动。',
    'Metals': '金属 ',
    "Gold and Silver CFDs offer access to precious metal spot markets. These CFDs can work for day trading or protecting against inflation and volatility.": "黄金和白银差价合约提供进入贵金属现货市场的机会。这些差价合约可用于日内交易或抵御通货膨胀和波动。",
    'Energies': '能源',
    "Crude oil CFDs offer access to volatile global energy markets. You can trade these popular commodities using both technical analysis and fundamental research.": '原油差价合约提供进入波动的全球能​​源市场的机会。您可以使用技术分析和基本面研究来交易这些受欢迎的商品。',
    'Cryptocurrencies': '加密货币',
    'Crypto CFDs allows you to invest in digital currency spot markets without the hassle of purchasing the underlying asset.': '加密货币差价合约让您可以投资数字货币现货市场，而无需购买实际的数字货币。',
    'Indices': '指数',
    'Index CFDs track major global indices, including the S&P 500 and Dow Jones Industrial Average(DJIA) in the US, the S&P/ASX 200 in Australia, and the Nikkei 225 in Japan.': '指数差价合约追踪全球主要指数，包括美国的标准普尔 500 指数和道琼斯工业平均指数 (DJIA)、澳大利亚的标准普尔/澳大利亚证券交易所 200 指数以及日本的日经 225 指数。',
    "Get in touch with us to begin trading in CFDs, or open an account now and gain access to global markets in under three minutes.": "联系我们开进行差价合约交易，或立即开设账户并在三分钟内进入全球市场。",
    "Get In Touch": "联系我们",
    "Trade a huge selection of currency pairs in the largest financial market with high leverage.": "在最大的金融市场上以高杠杆交易大量货币对。",
    "What is Forex?": "什么是外汇？",
    "Foreign exchange, commonly known as Forex or FX, relates to the buy and sell on currencies with the aim of making profits from the difference of prices.": "外汇，通常称为 Forex 或 FX，是指购买和出售货币，目的是从价格差异中获利。",
    "The forex market is the world’s largest financial market, which is also the most active and liquid market with over $5.5 trillion worth of currency volume processed each day.": "外汇市场是全球最大的金融市场，也是最活跃和流动性最高的市场之一，每日的成交量超过5.5万亿美元。",
    "Live Chart": "实时图表",
    "indices": "证劵指数",
    "Trade the movements of an industry or sector's growth or trajectory in the form of CFD, with the ability to buy or sell.": "以差价合约的形式交易一个行业或部门的增长或轨迹波动，可随时进行买入或卖出。",
    "What Are Indices?": "什么是指数？",
    "Indices are a collection of stocks and instruments that are used to track an industry or sector’s growth or trajectory.": "指数是股票和工具的集合，用于追踪特定行业或部门的增长或轨迹，不同的指数追踪不同的资产。",
    "For example, the US500, also known as the S&P 500 Index, is an American stock market index that tracks the performance of 500 large companies listed on US stock exchanges.": "例如，美国500指数，也称为标普500指数，是追踪美国股票交易所上市的500家大公司表现的指数。",
    "Commodities": "大宗商品",
    "Trade CFDs on commodities, ranging from gold and silver to WTI and Brent oil.": "交易大宗商品差价合约，范围从黄金到白银和原油。",
    "What Are Commodities?": "什么是大宗商品？",
    "Commodities are commercial products that appear naturally in the ground or are agriculturally cultivated.": "大宗商品可分为硬商品和软商品。硬商品是指通过开采或提取所得的自然资源，如石油、黄金和橡胶。",
    "Changes in commodities price also tend to affect the entire supply chain. At our platform, commodities such as precious metals and energies are available for CFD trading.": "软商品是指农产品，如咖啡、小麦或玉米。大宗商品价格的变化也往往会影响整个供应链。在我们的平台上，贵金属和能源等大宗商品均可进行差价合约交易。",
    "What Are Precious Metal?": "什么是贵金属？",
    "Precious metals are metals commodities that are often considered by investors seeking diversification during periods of market volatility or bear markets.": "贵金属是金属商品，在市场波动或熊市期间，寻求多样化的投资者经常会考虑这些商品。",
    "Precious metals are also known as reliable metals with real and conveyable value which can be used as a hedge against periods of high inflation or currency devaluation.": "贵金属也被称为具有实际价值和可转让价值的可靠金属，可用于对冲高通胀或货币贬值时期的风险。",
    "Equities": "美股指数",
    "Trade price movement of a wide range of diverse global equities including Amazon, Apple, Facebook and Tesla Motors.": "交易全球范围内包括亚马逊、苹果、Facebook和特斯拉等各种不同类型的股票的价格波动",
    "What Are Equities?": "什么是美股指数？",
    "Equities are shares in a company. When you trade equities in the form of CFDs with VT, you are not actually purchasing the specific share, but rather trading the price movements, providing you with the opportunity to profit in both rising and falling markets.": "股票是一家公司的股份。当您在 VTGM 以差价合约的形式交易股票时，您实际上并没有购买具体的股份，而是在交易价格的变动，为您提供在市场上涨和下跌中获利的机会。",
    "ETFs": "交易所交易基金",
    "Trade ETFs like ishares Core S&P 500 and SPDR Gold Shares in the form of CFD.": "以差价合约形式交易 ishares Core S&P 500 和 SPDR Gold Share 等交易所交易基金。",
    "What Is ETF?": "什么是交易所交易基金",
    "An exchange traded fund (ETF) is an investment instrument that tracks the performance of an asset or a group of assets that may include indices, commodities, bonds, and other assets. When you trade ETFs in the form of CFDs with VT, you are not actually purchasing the specific ETF, but rather investing in its price.": "交易所交易基金 (ETF) 是一种追踪一项资产或一组资产（可能包括指数、商品、债券和其他资产）表现的投资工具。当您在 VTGM 以差价合约形式交易 ETF 时，您实际上并不是购买特定的 ETF，而是对其价格进行投资。",
    "For example, the SPDR S&P 500 ETF Trust (SPY) tracks the performance of the S&P 500 index while the SPDR Gold Shares (GLD) tracks the performance of gold.": "例如，SPDR S&P 500 ETF Trust (SPY) 追踪 S&P 500 指数的表现，而 SPDR Gold Shares (GLD) 则追踪黄金的表现。",

    //navbar
    "Partnership": "合作伙伴",
    "Referral Program": "推荐计划",
    "Product": "交易产品",
    "Platform": "交易平台",
    "Account Overview": "账户概述",

    //footer
    "Copyright © VTGM All Rights Reserved.": "版权所有 © VTGM 保留所有权利。",
    "Quicklinks": "快速链接",
    "Our Core": "核心价值",
    "Trading": "交易",
    "Research & Education": "资讯",
    "Company": "公司",
    "Disclaimer": "免责声明",
    "This website’s content is only of a general nature and does not constitute personal financial advice. Trading Forex and Leveraged Financial Instruments may not be suitable for all investors. Trading leveraged products involves a significant level of risk and can result in the loss of your invested capital. We recommend that you seek independent advice and ensure that you fully understand the risks involved before trading.": "本网站的内容仅为一般性质，不构成个人理财建议。 交易外汇和杠杆金融工具可能不适合所有投资者。 交易杠杆产品涉及很大的风险，并可能导致您的投资资本损失。 我们建议您寻求独立的建议，并确保您在交易前充分了解所涉及的风险。 ",
    "Before acting on any material on this page, you should consider the appropriateness of such information with regard to your objectives, financial situation and needs. It is the responsibility of the Client to ascertain whether he/she is permitted to use the service of VT Global markets based on the legal requirements in his/her country of residence. VT Global markets has no liability to any person or entity for any loss or damage in whole.": "在对本页的任何资料采取行动之前，您应该考虑这些资料是否符合您的目标、财务状况和需要。 客户有责任根据其居住国的法律要求，确定他/她是否被允许使用 VT Global Markets 的服务。 VT Global Markets 对任何个人或实体的任何损失或损害概不负责。",

    //sidebar
    "Take A Look At PAMM": "了解更多关于PAMM",
    "Join·Grow·Multiply": "加入·成长·倍增",
    "Become An Introducing Broker (IBS)": "成为介绍经纪商 (IBS)",

    //menu
    "OUR-CORE": " 核心价值 ",
    "TRADING": "  交易  ",
    "PARTNERSHIP": " 合作伙伴 ",
    "RESEARCH": "资讯",
    "COMPANY": "  公司  ",
}

export default ZH;