import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function ETFHeader(props) {
    return (
        <>
            <div className='forexHeader' id="ETFs">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-around align-items-center px-0 mx-0 w-100 h-100 forexHeaderRow'>
                        <Col lg={4} md={5} sm={7} xs={7} className='mb-4 mb-lg-0'>
                            <img src={require("../../../assets/img/product/etfImg.webp").default} alt="" className="d-block w-100 floating3" />
                        </Col>
                        <Col lg={5} md={9} sm={11} xs={11}>
                            <span className='font-Black sectionHeaderTitle text-white d-block mb-4 text-center text-lg-left'>
                                {translate(props.lang, "ETFs")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center text-lg-left mb-3'>
                                {translate(props.lang, "Trade ETFs like ishares Core S&P 500 and SPDR Gold Shares in the form of CFD.")}
                            </span>
                            <span className='font-Bold sectionDivTitle text-white d-block text-left mb-3 mt-4'>
                                {translate(props.lang, "What Is ETF?")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "An exchange traded fund (ETF) is an investment instrument that tracks the performance of an asset or a group of assets that may include indices, commodities, bonds, and other assets. When you trade ETFs in the form of CFDs with VT, you are not actually purchasing the specific ETF, but rather investing in its price.")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "For example, the SPDR S&P 500 ETF Trust (SPY) tracks the performance of the S&P 500 index while the SPDR Gold Shares (GLD) tracks the performance of gold.")}
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(ETFHeader);