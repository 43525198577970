import React from 'react'
import { FaArrowLeft, FaArrowRight, FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const franchiseArray = [
    "The franchise would be entitled to set up their own office as per VTGM’s branding requirements and would be expected to provide personalised support to the clients that it has brought through the doors at VTGM.",
    "This would require the franchisee to have some business experience as well as capital. Franchisees can market VTGM’s entire range of products.",
    "This partnership will be centred around the introduction of clients and development of business by the franchisee for VTGM which will be rewarded with healthy commissions and benefits based on business volume."
]

function FranchiseModel(props) {
    const [imageIndex, setImageIndex] = React.useState(0);
    const slider2 = React.useRef(null);

    const settings = {
        dots: true,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: 1,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };


    return (
        <>
            <div className='franchiseModel' id="franchise-model">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={7} md={11} sm={11} xs={11} className=''>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center'>{translate(props.lang, "Franchise Model")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                {translate(props.lang, "Our franchise program enables ambitious individuals and organizations to partner with VTGM with an aim to bring clients and business to the table.")}
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='franchiseSlider px-4 px-lg-0'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-stretch mx-0 w-100 h-100 franchiseBox'>
                        <Col lg={5} md={9} sm={12} xs={12} className='mb-5 mb-lg-4 text-center text-lg-left'>
                            <img src={require("../../../assets/img/referralProgram/franchiseImg.webp").default} className='franchiseBoxImg floating3' />
                        </Col>
                        <Col lg={7} md={10} sm={12} xs={12} className='mb-4'>
                            <div className='sliderContentBox position-relative mt-4 h-100'>
                                <Slider {...settings} arrows={false} ref={slider2} >
                                    {
                                        franchiseArray.map((item, i) => {
                                            return (
                                                <div className="">
                                                    <span className='sectionDesc font-Regular text-light-purple d-block'>{translate(props.lang, item)}</span>
                                                </div>
                                            );
                                        })}
                                </Slider>
                                <div className="d-flex align-items-center justify-content-start sliderArrowAbsolute">
                                    <div className='d-flex justify-content-start align-items-center arrow-btn mr-3 mr-lg-4' onClick={() => slider2?.current?.slickPrev()}>
                                        <div className='btn'>
                                            <FaArrowLeft className='sectionDivTitle text-white' />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center arrow-btn' onClick={() => slider2?.current?.slickNext()}>
                                        <div className='btn'>
                                            <FaArrowRight className='sectionDivTitle text-white' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(FranchiseModel);