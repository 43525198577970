import React, { useEffect } from "react";
import "../../assets/scss/home.scss";
import IndexNavbar from "components/IndexNavbar";
import HomeHeader from "./HomeHeader";
import PowerExperience from "./PowerExperience";
import WhyChooseUs from "./WhyChooseUs";
import PartJourney from "./PartJourney";
import Pamm from "./Pamm";
import StartTrading from "./StartTrading";
import DarkFooter from "components/DarkFooter";
import SideBar from "components/SideBar";

const Home = () => {

    return (
        <>
            <IndexNavbar />
            <SideBar />
            <div className="home">
                <HomeHeader />
                <PowerExperience />
                <WhyChooseUs />
                <PartJourney />
                <Pamm />
                <StartTrading />
            </div>
            <DarkFooter />
        </>
    );
};

export default Home;
