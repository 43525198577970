import React from 'react'
import { FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import complainIcon1 from "../../assets/img/company/complainIcon1.webp";
import complainIcon2 from "../../assets/img/company/complainIcon2.webp";
import complainIcon3 from "../../assets/img/company/complainIcon3.webp";

const complainArray = [
    { img: complainIcon1, index: '01.', desc: 'Gather all the relevant supporting documents relating to your complaint' },
    { img: complainIcon2, index: '02.', desc: 'Consider the issues you would like to be addressed and what you would like us to do' },
    { img: complainIcon3, index: '03.', desc: 'Contact your Account Manager directly' },
]

function Complain(props) {
    const [imageIndex, setImageIndex] = React.useState(0);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 767 ? 2 : 1.2,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='complain'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-end align-items-center mx-0 w-100 h-100'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100'>
                                <Col lg={6} md={11} sm={11} xs={11} className='mb-3'>
                                    <span className='font-Black sectionHeaderTitle2 text-white d-block text-left text-uppercase mb-2'>{translate(props.lang, "If I have a complaint, what should I do?")}</span>
                                </Col>
                                <Col lg={6} md={11} sm={11} xs={11} className='mb-3'>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-start'>
                                        {translate(props.lang, "In the unlikely event that you are dissatisfied with the services provided by VT Global markets, please contact your Account Manager as soon as possible or email us with the process outlined below. This will help us to resolve your complaint in the most efficient way possible in order to regain your confidence.")}
                                    </span>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                                    <div className='d-none d-lg-block'>
                                        <Row className='justify-content-around align-items-stretch px-0 mx-0 w-100 complainBoxSection'>
                                            {
                                                complainArray.map((item, i) => (
                                                    <Col lg={4} md={9} sm={12} xs={12} className=''>
                                                        <Row className='justify-content-center align-items-center complainBox mx-0 h-100'>
                                                            <Col lg={6} md={6} sm={6} xs={6}>
                                                                <div className='complainImgDiv' id={'complainImgDiv' + i}>
                                                                    <img src={item.img} className='' />
                                                                </div>
                                                            </Col>
                                                            <Col lg={4} md={5} sm={5} xs={5}>
                                                                <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                                            </Col>
                                                            <Col lg={10} md={11} sm={11} xs={11} className='descSpacing'>
                                                                <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, item.desc)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </div>

                                    <div className='d-block d-lg-none my-4 px-3'>
                                        <Slider {...settings} arrows={false} >
                                            {
                                                complainArray.map((item, i) => (
                                                    <div className='px-2 mx-2 h-100'>
                                                        <Row className='justify-content-center align-items-center complainBox mx-0 h-100'>
                                                            <Col lg={6} md={6} sm={6} xs={6}>
                                                                <div className='complainImgDiv' id={'complainImgDiv' + i}>
                                                                    <img src={item.img} className='' />
                                                                </div>
                                                            </Col>
                                                            <Col lg={4} md={5} sm={5} xs={5}>
                                                                <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                                            </Col>
                                                            <Col lg={10} md={11} sm={11} xs={11} className='descSpacing'>
                                                                <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, item.desc)}</span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                            }
                                        </Slider>
                                    </div>

                                </Col>
                                <Col lg={7} md={11} sm={12} xs={12} className=''>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-center'>{translate(props.lang, "Should your issue persist, please contact us as early at your earliest convenience to allow us to resolve your complaint immediately. If your account manager is unable to resolve your complaint satisfactorily, please request that your complaint be forwarded to the management. You can also contact us directly by sending an email.")}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(Complain);