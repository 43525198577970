import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import whyChooseUsIcon1 from "../../assets/img/home/whyChooseUsIcon1.webp";
import whyChooseUsIcon2 from "../../assets/img/home/whyChooseUsIcon2.webp";
import whyChooseUsIcon3 from "../../assets/img/home/whyChooseUsIcon3.webp";
import whyChooseUsIcon4 from "../../assets/img/home/whyChooseUsIcon4.webp";
import whyChooseUsIcon5 from "../../assets/img/home/whyChooseUsIcon5.webp";
import whyChooseUsIcon6 from "../../assets/img/home/whyChooseUsIcon6.webp";

const whyChooseUsArray = [
    { index: '01.', img: whyChooseUsIcon1, title: 'New Generation & Innovative Trading Platform', desc: 'MT5 is the latest and most advanced trading platform developed by MetaQuotes Software.' },
    { index: '02.', img: whyChooseUsIcon2, title: 'Lightning-Fast Trading Experience', desc: 'Take advantage of ultra-fast STP execution via MT5' },
    { index: '03.', img: whyChooseUsIcon3, title: 'Regulated Broker Connected With Multi Liquidity Providers', desc: 'Connect to multiple LPs worldwide through MT5’s advanced technology to get the best price' },
    { index: '04.', img: whyChooseUsIcon4, title: 'Quick Withdrawal & Deposit Promises', desc: 'We support deposit and withdrawal of funds via a variety of international and local payment systems' },
    { index: '05.', img: whyChooseUsIcon5, title: 'Free Daily Technical Analysis', desc: 'Daily technical analysis provided by our professionals to our valued users / traders for free' },
    { index: '06.', img: whyChooseUsIcon6, title: 'Dedicated Account Managers', desc: 'Enjoy professional & excellent customer service from our account manager to valued users / traders' },
]

function WhyChooseUs(props) {
    const [selectedInfo, setSelectedInfo] = React.useState(0);

    return (
        <>
            <div className='whyChooseUs' id="why-choose-us">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center mx-0 w-100 h-100'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-5 mb-md-3 text-center'>{translate(props.lang, "Why Choose Us")}</span>

                            <Row className='justify-content-center align-items-stretch mx-0 w-100 h-100'>
                                <Col lg={7} md={10} sm={3} xs={3} className='px-1 px-lg-4'>
                                    <div className=''>
                                        <Row className='justify-content-start align-items-center px-0 mx-0 w-100 h-100 whyChooseUsOptionSection'>
                                            {
                                                whyChooseUsArray.map((item, i) => (
                                                    <Col lg={2} md={2} sm={12} xs={12} className='cursor-pointer mb-2 px-2' onClick={() => setSelectedInfo(i)}>
                                                        <div className={selectedInfo === i ? 'whyChooseUsOptionBoxActive' : 'whyChooseUsOptionBox'} id={selectedInfo === i ? "whyChooseUsOptionBox" + i : ""}>
                                                            <img src={item.img} className='' />
                                                        </div>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </div>
                                </Col>

                                <Col lg={10} md={11} sm={9} xs={9} className='pl-0 pl-md-4'>
                                    <Row className='justify-content-around justify-content-lg-center align-items-start align-items-md-center whyChooseUsContentBox w-100 h-100 mx-auto'>
                                        <Col lg={2} md={3} sm={12} xs={12} className='mb-4 mb-md-0'>
                                            <img src={whyChooseUsArray[selectedInfo].img} className='floating4' />
                                        </Col>
                                        <Col lg={7} md={6} sm={11} xs={11}>
                                            <span className='font-Bold sectionDivTitle text-white d-block'>{translate(props.lang, whyChooseUsArray[selectedInfo].title)}</span>
                                        </Col>
                                        <Col lg={2} md={2} sm={10} xs={10} className='mb-2 mb-md-4 mb-lg-0 order-1 order-md-2'>
                                            <span className='font-Bold sectionDivTitle text-light-purple text-left text-lg-right d-none d-md-block'>{whyChooseUsArray[selectedInfo].index}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(WhyChooseUs);