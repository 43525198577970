import React from 'react'
import { FaArrowRight } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";
import { StockHeatmap } from "react-ts-tradingview-widgets";

function DiscoverTools(props) {
    return (
        <>
            <div className='apiSection discoverTools'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-around justify-content-lg-between align-items-start px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={11} xs={11} className=''>
                            <Row className='justify-content-start align-items-center mx-0 w-100 h-100 discoverToolsBanner'>
                                <Col lg={10} md={12} sm={12} xs={12} className=''>
                                    <div className=''>
                                        <span className='font-Black sectionHeaderTitle2 text-white text-uppercase d-block mb-2 text-start'>{translate(props.lang, "Unlock the full potential of Market Buzz by logging in or signing up today.")}</span>
                                    </div>
                                    <div className='mt-4 d-flex justify-content-start align-items-center vtgm-btn-transparent'>
                                        <div className='btn'>
                                            <FaArrowRight className='sectionDivTitle text-white' />
                                        </div>
                                        <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Sign Up")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12} md={11} sm={11} xs={11} className='mt-5'>
                            <div className='w-100 apiSectionBox d-flex w-100 justify-content-center align-items-center'>
                                <StockHeatmap colorTheme="dark" width="100%" isTransparent={true} dataSource='SPX500'
                                    grouping='sector' exchanges={[]}
                                    blockSize="market_cap_basic"
                                    blockColor="change"
                                    locale={props.lang === "ZH" ? "zh_CN": "en"}
                                    symbolUrl=""
                                    hasTopBar={true}
                                    isDataSetEnabled={false}
                                    isZoomEnabled={true}
                                    hasSymbolTooltip={true}
                                    height="500"
                                ></StockHeatmap>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(DiscoverTools);