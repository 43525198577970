import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function CompanyHeader(props) {
    return (
        <>
            <div className='header' id="about-us">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={8} md={9} sm={11} xs={11} className=''>
                            <span className='font-Black headerTitle text-white text-uppercase d-block mb-4 text-center'>{translate(props.lang, "About Us")}</span>
                            <span className='font-Regular sectionDesc text-light-purple mb-3 d-block text-center'>
                                {translate(props.lang, "VT Global markets is a leading global financial institution which offers clients the opportunity to trade the financial markets via contract for differences (CFDs)")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                {translate(props.lang, "Our goal is to continually explore and discover innovative trading technologies in order to provide our clients with an unparalleled value and trading experience. We aim to provide exceptional services in currency trading, indices, commodities, equities and ETFs, to an extent that our clients remain satisfied with their trading experience.")}
                            </span>

                            {/* <img src={require("../../assets/img/company/aboutUsImg.png").default} alt="" className="d-block w-100 mt-5" /> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(CompanyHeader);