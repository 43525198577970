import React from 'react'
import { FaArrowRight, FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import brokerStepIcon1 from "../../../assets/img/partnership/brokerStepIcon1.webp";
import brokerStepIcon2 from "../../../assets/img/partnership/brokerStepIcon2.webp";
import brokerStepIcon3 from "../../../assets/img/partnership/brokerStepIcon3.webp";
import brokerStepIcon4 from "../../../assets/img/partnership/brokerStepIcon4.webp";

const brokerStepArray = [
    { img: brokerStepIcon1, index: '01.', desc: 'Sign Up Here' },
    { img: brokerStepIcon2, index: '02.', desc: 'Fill up all the relevant information' },
    { img: brokerStepIcon3, index: '03.', desc: 'Send your registration form to (Our email address)' },
    { img: brokerStepIcon4, index: '04.', desc: 'Receive your agent code and start earning your commission' }
]

function BrokerStep(props) {
    const [imageIndex, setImageIndex] = React.useState(0);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 767 ? 1.8 : 1.2,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='brokerStep' id="become-an-IBS">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center mx-0 w-100 h-100'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            <Row className='justify-content-center align-items-stretch mx-0 w-100 h-100'>
                                <Col lg={8} md={10} sm={12} xs={12} className='mb-3'>
                                    <span className='font-Black sectionHeaderTitle2 text-white d-block text-center text-uppercase mb-2'>
                                        {translate(props.lang, "It is simple to become an Introducing Broker (IBs)")}
                                    </span>
                                </Col>
                                <Col lg={5} md={10} sm={11} xs={11} className='mb-3'>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                        {translate(props.lang, "It takes less than 5 minutes to GET STARTED! You may sign up online here or follow the steps below to sign up online.")}
                                    </span>
                                </Col>
                            </Row>
                            <Row className='justify-content-end align-items-stretch mx-0 w-100 h-100'>
                                <Col lg={12} md={11} sm={11} xs={11} className='px-0'>

                                    <div className='d-none d-lg-block'>
                                        <Row className='justify-content-around align-items-stretch px-0 mx-0 w-100 brokerStepBoxSection'>
                                            {
                                                brokerStepArray.map((item, i) => (
                                                    <Col lg={3} md={6} sm={12} xs={12} className='mb-4'>
                                                        <Row className='justify-content-center align-items-center brokerStepBox mx-0 h-100' id={"brokerStepBox" + i}>
                                                            <Col lg={6} md={6} sm={6} xs={6}>
                                                                <div className='brokerStepImgDiv' id={'brokerStepImgDiv' + i}>
                                                                    <img src={item.img} className='' />
                                                                </div>
                                                            </Col>
                                                            <Col lg={5} md={5} sm={5} xs={5}>
                                                                <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                                            </Col>
                                                            <Col lg={11} md={11} sm={11} xs={11} className='descSpacing'>
                                                                <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, item.desc)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </div>

                                    <div className='d-block d-lg-none my-4'>
                                        <Slider {...settings} arrows={false} >
                                            {
                                                brokerStepArray.map((item, i) => (
                                                    <div className='px-3 h-100'>
                                                        <Row className='justify-content-center align-items-center brokerStepBox mx-0 h-100' id={"brokerStepBox" + i}>
                                                            <Col lg={6} md={6} sm={6} xs={6}>
                                                                <div className='brokerStepImgDiv' id={'brokerStepImgDiv' + i}>
                                                                    <img src={item.img} className='' />
                                                                </div>
                                                            </Col>
                                                            <Col lg={5} md={5} sm={5} xs={5}>
                                                                <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                                            </Col>
                                                            <Col lg={11} md={11} sm={11} xs={11} className='descSpacing'>
                                                                <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, item.desc)}</span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                            }
                                        </Slider>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='justify-content-center align-items-stretch mx-0 mt-4 mt-lg-0 w-100 h-100'>
                                <Col lg={12} md={11} sm={11} xs={11} className=''>
                                    <Row className='justify-content-start align-items-center mx-0 w-100 h-100 brokerContactBaner'>
                                        <Col lg={1} md={1} sm={12} xs={12} className='d-none d-lg-block' />
                                        <Col lg={8} md={12} sm={12} xs={12} className=''>
                                            <div className=''>
                                                <span className='font-Black sectionHeaderTitle2 text-white text-uppercase d-block mb-2 text-start'>{translate(props.lang, "If you are interested in becoming one of our  Introducing Broker, please contact us!")}</span>
                                            </div>
                                            <div className='mt-4 d-flex justify-content-start align-items-center vtgm-btn-transparent'>
                                                <div className='btn'>
                                                    <FaArrowRight className='sectionDivTitle text-white' />
                                                </div>
                                                <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Contact Us")}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(BrokerStep);