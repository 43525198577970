import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import webTraderIcon1 from "../../../assets/img/platform/webTraderIcon1.webp";
import webTraderIcon2 from "../../../assets/img/platform/webTraderIcon2.webp";
import webTraderIcon3 from "../../../assets/img/platform/webTraderIcon3.webp";
import webTraderIcon4 from "../../../assets/img/platform/webTraderIcon4.webp";
import webTraderIcon5 from "../../../assets/img/platform/webTraderIcon5.webp";
import webTraderIcon6 from "../../../assets/img/platform/webTraderIcon6.webp";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const webTraderArray = [
    { img: webTraderIcon1, title: 'Watchlist', desc: 'Access real-time prices and market movements with the Webtrader watchlist, featuring a comprehensive list of all available trading instruments.' },
    { img: webTraderIcon2, title: 'One-Click Trading Mode', desc: 'Instantly open and close orders by selecting from a list of instruments and utilizing the Limit Order field for setting up pending orders, all with just one click.' },
    { img: webTraderIcon3, title: 'Portfolio Area', desc: "Monitor your orders in the portfolio section, which provides detailed information such as order type, volume, prices, take profit, stop loss, open time, swap, and profit and loss." },
    { img: webTraderIcon4, title: 'Instruments Tabs', desc: "Select from a variety of chart types and enhance them with indicators, drawing tools, and more. Charting capabilities are powered by TradingView, ensuring top-notch visualization and analysis tools." },
    { img: webTraderIcon5, title: 'Trading Account Management', desc: 'Manage your account effortlessly, viewing details like trading history, balance, equity, margin, free margin, margin level, and leverage settings in real-time.' },
    { img: webTraderIcon6, title: 'Account Types', desc: 'The Webtrader is designed to accommodate the flexibility required by traders, compatible with all trading account types supported by MetaTrader 5, ensuring a comprehensive trading experience across different strategies and preferences.' },
]

function WebTrader(props) {
    const [imageIndex, setImageIndex] = React.useState(0);
    const slider2 = React.useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 991 ? 3 : window.innerWidth > 767 ? 2 : 1,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='webTrader' id="webtrader">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={8} md={11} sm={11} xs={11} className=''>
                            <span className='font-Regular sectionDivTitle text-light-purple mb-3 d-block text-center'>
                                {translate(props.lang, "WEBTRADER")}
                            </span>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center'>{translate(props.lang, "Trade up with our high-speed customized web app")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                {translate(props.lang, "Enjoy seamless and flexible trading on the Webtrader, a secure and streamlined web trader platform for trading over a hundred popular CFDs.")}
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='webTraderSlider'>
                <Container className='w-100 h-100'>
                    <Slider {...settings} arrows={false} ref={slider2} className='py-4 px-4 px-lg-0' >
                        {
                            webTraderArray.map((item, i) => {
                                return (
                                    <div className="w-100 px-2 h-100 webTraderBoxSection">
                                        <Row className='justify-content-center align-items-start webTraderBox mx-0' id={"webTraderBox" + i}>
                                            <Col lg={12} md={10} sm={10} xs={10}>
                                                <div className='webTraderImgDiv mx-auto' id={'webTraderImgDiv' + i}>
                                                    <img src={item.img} className='mx-auto' />
                                                </div>

                                                <span className='font-Regular sectionDivTitle text-white text-center d-block my-4'>{translate(props.lang, item.title)}</span>
                                                <span className='font-Regular sectionDesc text-white text-center d-block'>{translate(props.lang, item.desc)}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                    </Slider>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className='d-flex justify-content-start align-items-center arrow-btn mr-3 mr-lg-4' onClick={() => slider2?.current?.slickPrev()}>
                            <div className='btn'>
                                <FaArrowLeft className='sectionDivTitle text-white' />
                            </div>
                        </div>
                        <div className='d-flex justify-content-start align-items-center arrow-btn' onClick={() => slider2?.current?.slickNext()}>
                            <div className='btn'>
                                <FaArrowRight className='sectionDivTitle text-white' />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(WebTrader);