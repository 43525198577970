import React from 'react'
import { FaArrowLeft, FaArrowRight, FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const standardCharacterArray = [
    { category: 'SILVER', amount: '1,000', cashback: '1 USD/lot', bonus: '10%', trade: 'Nil' },
    { category: 'GOLD', amount: '5,000', cashback: '2 USD/lot', bonus: '20%', trade: 'X1' },
    { category: 'DIAMOND', amount: '10,000', cashback: '3 USD/lot', bonus: '30%', trade: 'X3' },
]

function StandardAccount(props) {
    const [imageIndex, setImageIndex] = React.useState(0);
    const slider2 = React.useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: 1,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='standardCharacter'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={8} md={11} sm={11} xs={11} className=''>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center'>{translate(props.lang, "Accounts Overview")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                {translate(props.lang, "We designed a few categories of standard accounts comprising Silver, Gold and Diamond for investors. These standard accounts allow investors to trade smaller trade sizes in Forex and Precious Metals while enjoying cashback, bonuses and special trade.")}
                            </span>
                        </Col>
                    </Row>
                </Container>
                <div className='standardCharacterContent'>
                    <Container className='w-100 h-100'>
                        <div className='d-none d-lg-block'>
                            <Row className='justify-content-center justify-content-md-start justify-content-lg-around align-items-stretch px-0 mx-0 w-100 h-100 standardCharacterBoxSection'>
                                {
                                    standardCharacterArray.map((item, i) => (
                                        <Col lg={4} md={6} sm={11} xs={11} className='mb-4'>
                                            <Row className='justify-content-center align-items-start standardCharacterBox mx-0 h-100' id={"standardCharacterBox" + i}>
                                                <Col lg={10} md={9} sm={11} xs={11}>
                                                    <span className='font-Black sectionHeaderTitle3 text-index-style d-block text-left text-capitalize mb-5'>{translate(props.lang, item.category)}</span>
                                                    <span className='font-Bold sectionDesc text-white d-block text-left mb-1'>{translate(props.lang, "Cashback")}</span>
                                                    <span className='font-Regular sectionDesc text-white d-block text-left mb-4'>{translate(props.lang, item.cashback)}</span>
                                                    <span className='font-Bold sectionDesc text-white d-block text-left mb-1'>{translate(props.lang, "Bonus")}</span>
                                                    <span className='font-Regular sectionDesc text-white d-block text-left mb-4'>{translate(props.lang, item.bonus)}</span>
                                                    <span className='font-Bold sectionDesc text-white d-block text-left mb-1'>{translate(props.lang, "Trade")}</span>
                                                    <span className='font-Regular sectionDesc text-white d-block text-left mb-2'>{translate(props.lang, item.trade)}</span>
                                                    <span className='font-Regular sectionDesc text-light-purple d-block text-left mt-5'>{translate(props.lang, "Market execution with tight spreads.")}</span>
                                                </Col>
                                                <Col lg={11} md={11} sm={11} xs={11} className=''>
                                                    <span className='font-Regular sectionDivTitle text-white d-block my-4'>{translate(props.lang, item.title)}</span>
                                                    <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, item.desc)}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </div>

                        <div className='d-block d-lg-none'>
                            <Row className='justify-content-center align-items-center'>
                                <Col lg={10} md={9} sm={10} xs={10}>
                                    <Slider {...settings} arrows={false} ref={slider2} >
                                        {
                                            standardCharacterArray.map((item, i) => (
                                                <div className='px-4'>
                                                    <Row className='justify-content-center align-items-start standardCharacterBox mx-0 px-2 h-100' id={"standardCharacterBox" + i}>
                                                        <Col lg={10} md={10} sm={11} xs={11}>
                                                            <span className='font-Black sectionHeaderTitle3 text-index-style d-block text-left text-capitalize mb-5'>{translate(props.lang, item.category)}</span>
                                                            <span className='font-Bold sectionDesc text-white d-block text-left mb-1'>{translate(props.lang, "Cashback")}</span>
                                                            <span className='font-Regular sectionDesc text-white d-block text-left mb-4'>{translate(props.lang, item.cashback)}</span>
                                                            <span className='font-Bold sectionDesc text-white d-block text-left mb-1'>{translate(props.lang, "Bonus")}</span>
                                                            <span className='font-Regular sectionDesc text-white d-block text-left mb-4'>{translate(props.lang, item.bonus)}</span>
                                                            <span className='font-Bold sectionDesc text-white d-block text-left mb-1'>{translate(props.lang, "Trade")}</span>
                                                            <span className='font-Regular sectionDesc text-white d-block text-left mb-2'>{translate(props.lang, item.trade)}</span>
                                                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mt-5'>{translate(props.lang, "Market execution with tight spreads.")}</span>
                                                        </Col>
                                                        <Col lg={11} md={11} sm={11} xs={11} className=''>
                                                            <span className='font-Regular sectionDivTitle text-white d-block my-4'>{translate(props.lang, item.title)}</span>
                                                            <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, item.desc)}</span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                </Col>
                                <Col lg={10} md={9} sm={11} xs={11}>
                                    <div className="d-flex align-items-center justify-content-center mt-5">
                                        <div className='d-flex justify-content-start align-items-center arrow-btn mr-3 mr-lg-4' onClick={() => slider2?.current?.slickPrev()}>
                                            <div className='btn'>
                                                <FaArrowLeft className='sectionDivTitle text-white' />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start align-items-center arrow-btn' onClick={() => slider2?.current?.slickNext()}>
                                            <div className='btn'>
                                                <FaArrowRight className='sectionDivTitle text-white' />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(StandardAccount);