import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function Resolution(props) {
    return (
        <>
            <div className='resolution' id="dispute-resolution">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-around align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={11} xs={11} className='mb-5 text-center'>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase text-center d-block mb-3'>{translate(props.lang, "Dispute Resolution")}</span>
                            <span className='font-Regular sectionDesc text-light-purple'>{translate(props.lang, "Your satisfaction is what we care about.")}</span>
                        </Col>
                        <Col lg={4} md={6} sm={11} xs={11} className='mb-5'>
                            <img src={require("../../assets/img/company/resolutionImg.webp").default} alt="" className="w-100 floating4" />
                        </Col>
                        <Col lg={6} md={10} sm={11} xs={11} className=''>
                            <span className='font-Black sectionDivTitle text-white d-block mb-3'>{translate(props.lang, "VT Global markets seeks to develop a stable and long-term relationship with its clients.")}</span>
                            <span className='font-Regular sectionDesc text-light-purple'>{translate(props.lang, "We are constantly striving to provide better service and accomplish values in order to exceed clients’ expectations with additional service assistance. In line with this, we regard our clients’ suggestions, opinions and concerns to be of the utmost significance. We also acknowledge that a client’s dissatisfaction is an opportunity for us to improve by enhancing our products and quality of services.")}</span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(Resolution);