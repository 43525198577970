import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function Calendar(props) {
    return (
        <>
            <div className='calendar' id="economic-calendar">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center justify-content-lg-between align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={6} md={6} sm={10} xs={10} className='order-2 order-md-1'>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase text-center text-md-left d-block mb-3'>{translate(props.lang, "Economic Calendar")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center text-md-left mb-3'>{translate(props.lang, "Keep track of high impact news, market-moving economic events and data releases with our Economic Calendar.")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center text-md-left mb-3'>{translate(props.lang, "We know it is important to be updated about economic and market events when trading, so the Real-time Economic Calendar is available to all VTGM clients.")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center text-md-left mb-3'>{translate(props.lang, "Our calendar keeps VTGM clients informed and allow them to monitor market activities in 38 different countries, by day, week, month or selecting a time range.")}</span>
                        </Col>
                        <Col lg={4} md={5} sm={7} xs={7} className='order-1 order-md-2 mb-4'>
                            <img src={require("../../assets/img/research/calendarImg.webp").default} alt="" className="w-100 pt-4 floating4" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(Calendar);