import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";
import Lottie from "lottie-react";
import pammAnim from "../../../assets/img/home/pamm.json";

function PlatformPamm(props) {

    return (
        <>
            <div className='platformPamm position-relative' id="PAMM/MAMM">
                <Lottie animationData={pammAnim} className='absoluteBgLottie' />
                <Container className='w-100 h-100 position-relative'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={8} md={9} sm={12} xs={12} className=''>
                            <span className='font-Regular sectionDivTitle text-light-purple mb-3 d-block text-center'>
                                PAMM / MAMM
                            </span>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center'>PAMM</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                            {translate(props.lang, "Provide an opportunity for clients to earn profits without the need to acquire technical skills.")}
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(PlatformPamm);