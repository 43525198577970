import React from 'react'
import { FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

const whatWeOfferArray = [
    "Up to $1850 based on the country, platform, and the size of deposit your referral makes",
    "Minimum first-time deposit is $10",
    "We accept traffic from more than 130 countries",
    "Daily payouts",
    "Extra bonuses for the best performance"
]

function AffiliateProgram(props) {
    return (
        <>
            <div className='affiliateProgram' id="affiliate-program">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={7} md={11} sm={11} xs={11} className=''>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center'>{translate(props.lang, "Affiliate Program")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                {translate(props.lang, "We are leading the industry with scheduled and attractive payouts. Drive traffic to VTGM and we'll give you commission up to $1850 for each user that becomes an active client.")}
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='whatWeOffer'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-around align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={4} md={6} sm={8} xs={8} className='mb-5 mb-lg-0'>
                            <img src={require("../../../assets/img/referralProgram/offerImg.webp").default} className='floating3' />
                        </Col>
                        <Col lg={6} md={10} sm={11} xs={11} className=''>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block text-left mb-2'>{translate(props.lang, "What We Offer")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-4'>
                                {translate(props.lang, "We pay for first-time deposits (CPA)")}
                            </span>
                            {
                                whatWeOfferArray.map((data, i) => (
                                    <Row className='justify-content-center align-items-start mt-3'>
                                        <Col lg={1} md={1} sm={1} xs={1}>
                                            <FaCheck className='sectionDesc mt-1' color={'#03FFE1'} />
                                        </Col>
                                        <Col lg={10} md={10} sm={10} xs={10}>
                                            <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, data)}</span>
                                        </Col>
                                    </Row>
                                ))
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(AffiliateProgram);