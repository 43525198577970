import React from "react";
import "../../../assets/scss/partnership.scss";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import PartnershipHeader from "./PartnershipHeader";
import FamilyMember from "./FamilyMember";
import BrokerStep from "./BrokerStep";
import SideBar from "components/SideBar";

const Partnership = () => {

    return (
        <>
            <IndexNavbar />
            <SideBar />
            <div className="partnership">
                <FamilyMember />
                <PartnershipHeader />
                <BrokerStep />
            </div>
            <DarkFooter />
        </>
    );
};

export default Partnership;
