import React from "react";
import "../../../assets/scss/platform.scss";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import PlatformHeader from "./PlatformHeader";
import PlatformDevice from "./PlatformDevice";
import WebTrader from "./WebTrader";
import PlatformPamm from "./PlatformPamm";
import PammContent from "./PammContent";
import CopyTrader from "./CopyTrader";
import AdvantageCopier from "./AdvantageCopier";
import SideBar from "components/SideBar";

const Platform = () => {

    return (
        <>
            <IndexNavbar />
            <SideBar />
            <div className="platform">
                <PlatformHeader />
                <PlatformDevice />
                <WebTrader />
                <PlatformPamm />
                <PammContent />
                <CopyTrader />
                <AdvantageCopier />
            </div>
            <DarkFooter />
        </>
    );
};

export default Platform;
