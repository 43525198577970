import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function EquitiesHeader(props) {
    return (
        <>
            <div className='indicesHeader' id="equities">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-around align-items-center px-0 mx-0 w-100 h-100 forexHeaderRow'>
                        <Col lg={5} md={9} sm={11} xs={11} className='order-2 order-lg-1'>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4  text-center text-lg-left'>
                                {translate(props.lang, "Equities")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center text-lg-left mb-3'>
                                {translate(props.lang, "Trade price movement of a wide range of diverse global equities including Amazon, Apple, Facebook and Tesla Motors.")}
                            </span>
                            <span className='font-Bold sectionDivTitle text-white d-block text-left mb-3 mt-4'>
                                {translate(props.lang, "What Are Equities?")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "Equities are shares in a company. When you trade equities in the form of CFDs with VT, you are not actually purchasing the specific share, but rather trading the price movements, providing you with the opportunity to profit in both rising and falling markets.")}
                            </span>
                        </Col>
                        <Col lg={4} md={6} sm={8} xs={8} className='mb-4 mb-lg-0 order-1 order-lg-2'>
                            <img src={require("../../../assets/img/product/equitiesImg.webp").default} alt="" className="d-block w-100 floating3" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(EquitiesHeader);