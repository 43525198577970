import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import advantageIcon1 from "../../assets/img/company/advantageIcon1.webp";
import advantageIcon2 from "../../assets/img/company/advantageIcon2.webp";
import advantageIcon3 from "../../assets/img/company/advantageIcon3.webp";
import { FaCheck } from 'react-icons/fa';

const advantageArray = [
    {
        img: advantageIcon1,
        title: 'Expansive Range of Instruments',
        content:
            [
                "A Selection of Trading Instruments",
                "Competitive Bid/Ask pip spreads",
                "Leverage Up to 1:400"
            ]
    },
    {
        img: advantageIcon2,
        title: 'Market-Leading Trading Platform',
        content:
            [
                "Fast execution",
                "Increased functionality and efficiency for traders"
            ]
    },
    {
        img: advantageIcon3,
        title: 'Exclusive Rewards for Traders',
        content:
            [
                "Rebates per lot for traders",
                "Promotional campaign from time to time"
            ]
    }
]

function OurAdvantage(props) {
    return (
        <>
            <div className='advantage' id="our-advantages">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100 advantage-row'>
                        <Col lg={4} md={11} sm={11} xs={11} className=''>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-left'>{translate(props.lang, "Our Advantages")}</span>
                            <span className='font-Regular sectionDesc text-light-purple mb-3 d-block text-left'>
                                {translate(props.lang, "The right place for online trading on financial markets.")}
                            </span>
                        </Col>
                        <Col lg={8} md={11} sm={11} xs={11} className='px-0'>
                            <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100 advantage-row2'>
                                {
                                    advantageArray.map((item, i) => (
                                        <Col lg={4} md={4} sm={11} xs={11} className='px-2'>
                                            <div className='advantageBox h-100' id={"advantageBox" + i}>
                                                <div className='advantageImgDiv' id={'advantageImgDiv' + i}>
                                                    <img src={item.img} className='' />
                                                </div>

                                                <span className='font-Bold sectionDivTitle text-white d-block text-left text-capitalize mb-3'>{translate(props.lang, item.title)}</span>

                                                {
                                                    (item.content).map((data, i) => (
                                                        <Row className='justify-content-center align-items-start mt-3'>
                                                            <Col lg={1} md={1} sm={1} xs={1}>
                                                                <FaCheck className='sectionDesc mt-1' color={'#03FFE1'} />
                                                            </Col>
                                                            <Col lg={10} md={10} sm={10} xs={10}>
                                                                <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, data)}</span>
                                                            </Col>
                                                        </Row>
                                                    ))
                                                }
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(OurAdvantage);