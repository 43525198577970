import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function CFDHeader(props) {
    return (
        <>
            <div className='cfdHeader' id="CFD">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={10} md={11} sm={11} xs={11} className='cfdTitleSection order-1'>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center'>{translate(props.lang, "Diversify Your Trading Portfolio With Our Trading Products")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                {translate(props.lang, "Trade CFDs on Forex, Indices, Commodities, Equities & ETFs, or subscribe to our PAMM service.")}
                            </span>
                        </Col>
                        <Col lg={7} md={11} sm={11} xs={11} className='order-3 order-lg-2'>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-left'>{translate(props.lang, "CFD")}</span>
                            <span className='font-Bold sectionDivTitle text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "What Does CFD Stands For?")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, 'It means "contract for difference". These contracts track spot markets and are attractive to small investor because they do not require you to purchase the underlying asset.')}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "You only have to pay the price difference from when you open a trading position to when you close it. Also, you can use leverage, which can help increase the size of your positions even if you have limited capital.")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "But what are CFDs in trading? Here is a look at the benefits of CFD trading and the best ways to engage is CFD trading.")}
                            </span>
                        </Col>
                        <Col lg={5} md={6} sm={9} xs={9} className='order-2 order-lg-3 mb-4 mb-lg-0'>
                            <img src={require("../../../assets/img/product/cfdImg.webp").default} alt="" className="d-block w-100 floating4 py-4" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(CFDHeader);