import React from 'react'
import { FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import whyBrokerIcon1 from "../../../assets/img/referralProgram/whyBrokerIcon1.webp";
import whyBrokerIcon2 from "../../../assets/img/referralProgram/whyBrokerIcon2.webp";
import whyBrokerIcon3 from "../../../assets/img/referralProgram/whyBrokerIcon3.webp";
import whyBrokerIcon4 from "../../../assets/img/referralProgram/whyBrokerIcon4.webp";

const whyBrokerArray = [
    { img: whyBrokerIcon1, index: '01.', title: 'Daily payouts & instant withdrawals', desc: 'We pay your profit daily into your account and you can access your money instantly, whenever you want.' },
    { img: whyBrokerIcon2, index: '02.', title: 'Promotional tools & materials', desc: 'We provide all the tools you need to promote our products, including website banners, advertising, landing pages.' },
    { img: whyBrokerIcon3, index: '03.', title: 'Detailed, real-time statistics', desc: "We provide reporting tools that show detailed, real-time statistics on every client's transactions in your Personal Area." },
    { img: whyBrokerIcon4, index: '04.', title: 'Trusted global market leader', desc: 'Join a team of professionals who operate in 150+ countries and offer a full range of financial instruments with competitive trading conditions.' },
]

function WhyBroker(props) {

    return (
        <>
            <div className='whyBroker' id="why-become-an-introducing-broker">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={11} xs={11} className='px-0'>
                            <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100'>
                                <Col lg={4} md={8} sm={12} xs={12} className='mb-3'>
                                    <span className='font-Black sectionHeaderTitle2 text-white d-block text-center text-lg-left text-uppercase mb-5'>
                                        {translate(props.lang, "Why become an Introducing Broker?")}
                                    </span>
                                    <img src={require("../../../assets/img/referralProgram/whyBrokerImg.webp").default} alt="" className="w-100 d-none d-lg-block floating3" />
                                </Col>
                                <Col lg={8} md={12} sm={12} xs={12} className=''>
                                    <Row className='justify-content-around align-items-stretch px-0 mx-0 w-100 h-100 whyBrokerBoxSection'>
                                        {
                                            whyBrokerArray.map((item, i) => (
                                                <Col lg={6} md={6} sm={12} xs={12} className='mb-4'>
                                                    <Row className='justify-content-center align-items-center whyBrokerBox mx-0 h-100' id={"whyBrokerBox" + i}>
                                                        <Col lg={6} md={6} sm={6} xs={6}>
                                                            <div className='whyBrokerImgDiv' id={'whyBrokerImgDiv' + i}>
                                                                <img src={item.img} className='' />
                                                            </div>
                                                        </Col>
                                                        <Col lg={5} md={5} sm={5} xs={5}>
                                                            <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                                        </Col>
                                                        <Col lg={11} md={11} sm={11} xs={11} className=''>
                                                            <span className='font-Regular sectionDivTitle text-white d-block my-4 text-capitalize'>{translate(props.lang, item.title)}</span>
                                                            <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, item.desc)}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(WhyBroker);