import React from 'react'
import { FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function FamilyMember(props) {
    return (
        <>
            <div className='familyMember' id="family-member">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-around align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={4} md={7} sm={9} xs={9} className='mb-4 mb-md-5 mb-lg-0'>
                            <img src={require("../../../assets/img/partnership/familyMemberImg.webp").default} alt="" className="w-100 floating4 py-4" />
                        </Col>
                        <Col lg={6} md={10} sm={11} xs={11} className=''>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-3'>{translate(props.lang, "To Be Part Of Our Family Member")}</span>

                            <Row className='justify-content-start align-items-start mt-3'>
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <FaCheck className='sectionDesc mt-1' color={'#03FFE1'} />
                                </Col>
                                <Col lg={10} md={10} sm={10} xs={10}>
                                    <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Join our affiliate program to earn unlimited potential earnings")}</span>
                                </Col>
                            </Row>

                            <Row className='justify-content-start align-items-start mt-3'>
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <FaCheck className='sectionDesc mt-1' color={'#03FFE1'} />
                                </Col>
                                <Col lg={10} md={10} sm={10} xs={10}>
                                    <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Personalized account managers and instant service support")}</span>
                                </Col>
                            </Row>

                            <Row className='justify-content-start align-items-start mt-3'>
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <FaCheck className='sectionDesc mt-1' color={'#03FFE1'} />
                                </Col>
                                <Col lg={10} md={10} sm={10} xs={10}>
                                    <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Attractive incentives & team support for IBs looking to expand their market and increase client conversion")}</span>
                                </Col>
                            </Row>

                            <Row className='justify-content-start align-items-start mt-3'>
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <FaCheck className='sectionDesc mt-1' color={'#03FFE1'} />
                                </Col>
                                <Col lg={10} md={10} sm={10} xs={10}>
                                    <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Surprising promotional plans and innovative marketing materials to facilitate affiliates' marketing efforts")}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(FamilyMember);