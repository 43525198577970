import React from "react";
import "../../../assets/scss/referralProgram.scss";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import ReferralProgramHeader from "./ReferralProgramHeader";
import IncomeCalculate from "./IncomeCalculate";
import WhyBroker from "./WhyBroker";
import WhyBrokerVTGM from "./WhyBrokerVTGM";
import AffiliateProgram from "./AffiliateProgram";
import FundManager from "./FundManager";
import FranchiseModel from "./FranchiseModel";
import SideBar from "components/SideBar";

const ReferralProgram = () => {

    return (
        <>
            <IndexNavbar />
            <SideBar />
            <div className="referralProgram">
                <WhyBroker />
                <IncomeCalculate />
                <WhyBrokerVTGM />
                <AffiliateProgram />
                <FundManager />
                <FranchiseModel />
            </div>
            <DarkFooter />
        </>
    );
};

export default ReferralProgram;
