import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import partnershipIcon1 from "../../../assets/img/partnership/partnershipIcon1.webp";
import partnershipIcon2 from "../../../assets/img/partnership/partnershipIcon2.webp";
import partnershipIcon3 from "../../../assets/img/partnership/partnershipIcon3.png";
import partnershipIcon4 from "../../../assets/img/partnership/partnershipIcon4.png";

const partnershipArray = [
    { img: partnershipIcon2, title: 'Instant Services Support from Us', desc: 'We are constantly striving to provide better service and accomplish values in order to exceed our clients’ expectations with additional service assistance. Our service assistance comprises a robust customer service support team available 24 hours a day, 5.5 days a week, as well as customised account management for IBs, Market Outlook, Analysis Tools, Online Guides and Education.' },
    { img: partnershipIcon3, title: 'Seminars And Webinars by Professionals', desc: 'Growing a healthy business is always the goal of our company, and we provide our best assistance from all possible perspectives. We provides executive seminars and webinars to our valued investors on a regular basis. Furthermore, for events and webinars, We combines the strong outsourced support of subsidised personnel and experienced FX industry speakers.' },
    { img: partnershipIcon4, title: 'Exclusive Rewards & Privileges', desc: 'All our clients and IBs are valuable assets in our long-term partnership. Hence, we provides a variety of rewards for introducing brokers which include commission rebates from trading, achievement rewards and other sorts of gifts for certain important occasions.' },
]

function PartnershipHeader(props) {
    return (
        <>
            <div className='header' id="why-affiliate-with-us">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={11} xs={11} className='h-100'>
                            <Row className='justify-content-center align-items-stretch mx-0 w-100 mt-5'>
                                <Col lg={7} md={10} sm={12} xs={12} className='mb-5'>
                                    <div className='h-100'>
                                        <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block text-center mb-3'>{translate(props.lang, "Why Affiliate With Us")}</span>
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-center'>{translate(props.lang, "VT provides a series of excellent reward programs for introducing brokers (IBs) who put effort into introducing business to us. Profit on reward has no restrictions for IBs, and it is open to anybody who wants to participate. This unique IBs program offered by us is strongly supported by our dedicated and experienced account managers.")}</span>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='justify-content-center align-items-stretch mx-0 w-100 mt-5 pt-5'>
                                {
                                    partnershipArray.map((item, i) => {
                                        return (
                                            <Col lg={4} md={9} sm={12} xs={12} className='partnershipHeaderBoxMB'>
                                                <Row className='justify-content-center align-items-stretch partnershipHeaderBox mx-0 mb-4 h-100' id={"partnershipHeaderBox" + (i + 1)}>
                                                    <Col lg={11} md={11} sm={11} xs={11} className='mb-3'>
                                                        <img src={item.img} alt="" className="floating3" />
                                                    </Col>
                                                    <Col lg={9} md={8} sm={9} xs={9}>
                                                        <span className='font-Black sectionDivTitle text-white text-capitalize d-block text-center'>{translate(props.lang, item.title)}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(PartnershipHeader);