import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

function IncomeCalculate(props) {
    const [value, setValue] = useState([0, 50]);
    const [totalAccumClients, setTotalAccumClients] = useState(0);
    const [incomePerMonth, setIncomePerMonth] = useState(0);

    return (
        <>
            <div className='incomeCalculate'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100 vh-80-row'>
                        <Col lg={12} md={11} sm={11} xs={11} className=''>
                            <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                                <Col lg={7} md={12} sm={12} xs={12} className='mb-3'>
                                    <span className='font-Black sectionHeaderTitle2 text-white d-block text-center text-uppercase mb-2'>{translate(props.lang, "The more clients you sign up, the greater your income")}</span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                        {translate(props.lang, "Drag the bar to see the average monthly income you can make based on the total amount of traders you introduce to VTGM.")}
                                    </span>
                                </Col>
                            </Row>

                            <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100 mt-5'>
                                <Col lg={3} md={12} sm={12} xs={12} className='mb-3'>
                                    <div className='accumulatedClientsDiv'>
                                        <span className='font-Black sectionHeaderTitle2 d-block text-left text-uppercase mb-2'>{value[1] * 10}</span>
                                        <span className='font-Regular smallText d-block text-left text-uppercase'>
                                            {translate(props.lang, "Total Accumulated Clients")}
                                        </span>
                                    </div>
                                </Col>

                                <Col lg={6} md={12} sm={12} xs={12} className='rangeSliderSection'>
                                    <RangeSlider
                                        className="single-thumb"
                                        defaultValue={[0, 100]}
                                        thumbsDisabled={[true, false]}
                                        rangeSlideDisabled={true}
                                        value={value}
                                        step={"10"}
                                        onInput={setValue}
                                    />
                                </Col>

                                <Col lg={3} md={12} sm={12} xs={12} className='mb-3'>
                                    <div className='incomeMonthDiv'>
                                        <span className='font-Black sectionHeaderTitle2 d-block text-left text-uppercase mb-2'>${(value[1] * 100).toLocaleString()}</span>
                                        <span className='font-Regular smallText d-block text-left text-uppercase'>
                                            {translate(props.lang, "Your income per month")}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(IncomeCalculate);