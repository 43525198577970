import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function PlatformHeader(props) {
    return (
        <>
            <div className='header' id="PROTRADER">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={8} md={11} sm={11} xs={11} className=''>
                            <span className='font-Black headerTitle text-white text-uppercase d-block mb-4 text-center'>PROTRADER</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                {translate(props.lang, "Building on the esteemed legacy of its predecessor, MetaTrader 5 (MT5) enhances your trading experience by offering additional trading timeframes, improved order flexibility, and accelerated strategy testing speeds.")}
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(PlatformHeader);