import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import powerExperienceIcon1 from "../../assets/img/home/powerExperienceIcon1.webp";
import powerExperienceIcon2 from "../../assets/img/home/powerExperienceIcon2.webp";
import powerExperienceIcon3 from "../../assets/img/home/powerExperienceIcon3.webp";
import powerExperienceIcon4 from "../../assets/img/home/powerExperienceIcon4.webp";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const powerExperienceArray = [
    { img: powerExperienceIcon1, desc: '$100 Welcome Bonus' },
    { img: powerExperienceIcon2, desc: 'Low Spread & Slippage' },
    { img: powerExperienceIcon3, desc: 'Low Minimum Deposit of $100' },
    { img: powerExperienceIcon4, desc: 'Over 100 Trading Products' },
]

function PowerExperience(props) {
    const [imageIndex, setImageIndex] = React.useState(0);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 767 ? 2 : 1.2,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='powerExperience' id="empowering-experience">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-end align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            <Row className='justify-content-center justify-content-lg-start align-items-center px-0 mx-0 w-100 h-100'>
                                <Col lg={7} md={9} sm={11} xs={11} className='px-2 px-lg-4'>
                                    <div className=''>
                                        <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-3'>{translate(props.lang, "Empowering Your Trading Experience")}</span>
                                        <span className='font-Regular sectionDesc text-light-purple'>{translate(props.lang, "Unlock unparalleled opportunities with our refined USP")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12} md={11} sm={11} xs={11} className='px-0'>
                            <div className='d-none d-lg-block'>
                                <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100'>
                                    {
                                        powerExperienceArray.map((item, i) => (
                                            <Col lg={3} md={5} sm={6} xs={6} className='infoBoxOuter'>
                                                <div className='infoBox h-100' id={"infoBox" + i}>
                                                    <img src={item.img} className={i % 2 > 0 ? 'floating3' : 'floating4'} />
                                                    <span className='font-Regular smallText text-light-purple d-block text-uppercase'>{translate(props.lang, item.desc)}</span>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>

                            <div className='d-block d-lg-none'>
                                <Slider {...settings} arrows={false} >
                                    {
                                        powerExperienceArray.map((item, i) => (
                                            <div className='infoBoxOuter px-2'>
                                                <div className='infoBox h-100' id={"infoBox" + i}>
                                                    <img src={item.img} className={i % 2 > 0 ? 'floating3' : 'floating4'} />
                                                    <span className='font-Regular smallText text-light-purple d-block'>{translate(props.lang, item.desc)}</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(PowerExperience);