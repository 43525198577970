import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import pammContentIcon1 from "../../../assets/img/platform/pammContentIcon.webp";
import pammContentIcon2 from "../../../assets/img/platform/pammContentIcon2.webp";
import pammContentIcon3 from "../../../assets/img/platform/pammContentIcon3.webp";
import pammContentIcon4 from "../../../assets/img/platform/pammContentIcon4.webp";
import pammContentIcon5 from "../../../assets/img/platform/pammContentIcon5.webp";

const pammContentArray = [
    { img: pammContentIcon1, index: "01.", title: "Open Account" },
    { img: pammContentIcon2, index: "02.", title: "Make a Deposit" },
    { img: pammContentIcon3, index: "03.", title: "Select Master" },
    { img: pammContentIcon4, index: "04.", title: "Cash in when profit" },
    { img: pammContentIcon5, index: "05.", title: "Profit Sharing" },
]

function CopyTrader(props) {

    return (
        <>
            <div className='copyTrader' id="copy-trading">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={8} md={11} sm={12} xs={12} className=''>
                            <span className='font-Regular sectionDivTitle text-light-purple mb-3 d-block text-center text-uppercase'>
                                {translate(props.lang, "copy trading")}
                            </span>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center'>{translate(props.lang, "START COPYING AND EARNING WITH PRO TRADERS")}</span>
                        </Col>
                        <Col lg={6} md={11} sm={12} xs={12} className=''>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                {translate(props.lang, "VTGM Copytrading lets you copy expert traders automatically, saving you the time and effort of creating your own trading strategy. Choose from the best Masters of Forex and diversify your trading portfolio.")}
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(CopyTrader);