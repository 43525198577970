import React from "react";
import { FaEnvelope, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { Col, Container, Row } from "reactstrap";
import "../assets/scss/footer.scss";
import { connect } from 'react-redux';
import { translate } from "views/utils/translate";

const DarkFooter = (props) => {

    return (
        <>
            <div className="footer px-4 px-lg-0">
                <Container className='w-100 h-100'>
                    <Row className="justify-content-center align-items-start mx-0 footerPadding">
                        <Col lg={4} md={12} sm={12} xs={12} className="mb-5">
                            <img src={require("../assets/img/logo/logo.webp").default} alt="" className="d-block footerLogoSize" />
                            <div className="mt-3">
                                <span className='font-Regular sectionDesc text-light-purple-2 d-block text-left'>{translate(props.lang, "Copyright © VTGM All Rights Reserved.")}</span>
                            </div>
                        </Col>

                        <Col lg={8} md={12} sm={12} xs={12} className="mb-4">
                            <Row className="justify-content-start align-items-start mx-0">
                                <Col lg={4} md={4} sm={6} xs={6} className="text-center mb-5 mb-md-4">
                                    <span className='font-Regular sectionDesc text-white d-block text-left mb-3'>{translate(props.lang, "Quicklinks")}</span>
                                    <a href="/our-core">
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>{translate(props.lang, "Our Core")}</span>
                                    </a>
                                    <a href="/product">
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>{translate(props.lang, "Trading")}</span>
                                    </a>
                                    <a href="/partnership">
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>{translate(props.lang, "Partnership")}</span>
                                    </a>
                                    <a href="/research">
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>{translate(props.lang, "Research & Education")}</span>
                                    </a>
                                    <a href="/company">
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>{translate(props.lang, "Company")}</span>
                                    </a>
                                    <a href="https://app.tradbot.xyz/" target="_blank">
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>{translate(props.lang, "Tradbot")}</span>
                                    </a>
                                </Col>
                                <Col lg={4} md={4} sm={6} xs={6} className="text-center mb-5 mb-md-4">
                                    <span className='font-Regular sectionDesc text-white d-block text-left mb-3'>{translate(props.lang, "Trading")}</span>
                                    <a href="/product">
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>{translate(props.lang, "Product")}</span>
                                    </a>
                                    <a href="/platform">
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>{translate(props.lang, "Platform")}</span>
                                    </a>
                                    <a href="/account-overview">
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>{translate(props.lang, "Account Overview")}</span>
                                    </a>
                                </Col>
                                <Col lg={4} md={4} sm={6} xs={6} className="text-center mb-5 mb-md-4">
                                    <span className='font-Regular sectionDesc text-white d-block text-left mb-3'>{translate(props.lang, "Partnership")}</span>
                                    <a href="/referral-program">
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>{translate(props.lang, "Referral Program")}</span>
                                    </a>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={12} md={12} sm={12} xs={12}>
                            <span className='font-Bold sectionDesc text-light-purple-3 d-block text-left mb-3'>{translate(props.lang, "Disclaimer")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <span className='font-Regular sectionDesc text-light-purple-3 d-block text-left mb-3'>{translate(props.lang, "This website’s content is only of a general nature and does not constitute personal financial advice. Trading Forex and Leveraged Financial Instruments may not be suitable for all investors. Trading leveraged products involves a significant level of risk and can result in the loss of your invested capital. We recommend that you seek independent advice and ensure that you fully understand the risks involved before trading.")}</span>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <span className='font-Regular sectionDesc text-light-purple-3 d-block text-left mb-3'>{translate(props.lang, "Before acting on any material on this page, you should consider the appropriateness of such information with regard to your objectives, financial situation and needs. It is the responsibility of the Client to ascertain whether he/she is permitted to use the service of VT Global markets based on the legal requirements in his/her country of residence. VT Global markets has no liability to any person or entity for any loss or damage in whole.")}</span>
                        </Col>
                    </Row>
                </Container>
            </div >
        </>
    );
};

const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        lang: i18n.lang,
    };
};

export default connect(mapStateToProps)(DarkFooter);
