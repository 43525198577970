import React from 'react'
import { FaArrowLeft, FaArrowRight, FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import depositWithdrawalIcon1 from "../../../assets/img/accountOverview/depositWithdrawalIcon1.webp";
import depositWithdrawalIcon2 from "../../../assets/img/accountOverview/depositWithdrawalIcon2.webp";
import depositWithdrawalIcon3 from "../../../assets/img/accountOverview/depositWithdrawalIcon3.webp";
import depositWithdrawalIcon4 from "../../../assets/img/accountOverview/depositWithdrawalIcon4.webp";
import depositWithdrawalIcon5 from "../../../assets/img/accountOverview/depositWithdrawalIcon5.webp";
import depositWithdrawalIcon6 from "../../../assets/img/accountOverview/depositWithdrawalIcon6.webp";
import depositWithdrawalIcon7 from "../../../assets/img/accountOverview/depositWithdrawalIcon7.webp";
import depositWithdrawalIcon8 from "../../../assets/img/accountOverview/depositWithdrawalIcon8.webp";

const depositWithdrawalArray = [
    { img: depositWithdrawalIcon1, index: "01.", title: 'Frictionless Transactions Every Step of the Way', desc: 'Enjoy our unmatched payments ecosystem: seamless deposits through global and local payment systems, with 24/7 access and hassle-free fund release.' },
    { img: depositWithdrawalIcon2, index: "02.", title: 'Diverse Payment Methods for Your Convenience', desc: 'Choose from a variety of secure, global, and local payment options for straightforward deposits and withdrawals.' },
    { img: depositWithdrawalIcon3, index: "03.", title: 'Unrestricted Access to Your Funds', desc: "Withdraw your funds anytime, instantly with our instant withdrawal feature. Say goodbye to withdrawal fees, we cover third-party transaction costs for you." },
    { img: depositWithdrawalIcon4, index: "04.", title: 'Your Funds Are Secure With Us', desc: "As the world's leading retail multi-asset broker, we implement multiple layers of security to ensure your funds are safe and readily accessible upon request." },
    { img: depositWithdrawalIcon5, index: "05.", title: 'Segregated Accounts for Enhanced Security', desc: 'Your funds are held in segregated accounts across multiple tier-1 banks, offering you the highest level of security and peace of mind.' },
    { img: depositWithdrawalIcon6, index: "06.", title: 'Secure Withdrawal Transactions', desc: 'Rest assured, your withdrawals are protected with one-time password verification, ensuring safety and security.' },
]

function DepositWithdrawal(props) {
    const [imageIndex, setImageIndex] = React.useState(0);
    const slider2 = React.useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 991 ? 3 : window.innerWidth > 767 ? 2 : 1,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='depositWithdrawal' id="deposit-and-withdrawal">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-stretch mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={11} xs={11} className='px-0'>
                            <Row className='justify-content-center align-items-stretch mx-0 w-100 h-100'>
                                <Col lg={8} md={12} sm={12} xs={12} className='mb-3 text-start'>
                                    <span className='font-Black sectionHeaderTitle text-white d-block text-center text-uppercase mb-3'>
                                        {translate(props.lang, "Deposit and Withdrawal")}
                                    </span>
                                    <span className='font-Regular sectionDesc text-white d-block text-center'>
                                        {translate(props.lang, "Experience the convenience and security of VTGM deposit and withdrawal processes. We offer local, convenient, and safe payment methods, ensuring seamless transactions from start to finish.")}
                                    </span>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className='py-5'>
                                    <Slider {...settings} arrows={false} ref={slider2} className='' >
                                        {
                                            depositWithdrawalArray.map((item, i) => {
                                                return (
                                                    <Row className='justify-content-around align-items-start d-flex depositWithdrawalBox mx-0 h-100' id={"depositWithdrawalBox" + i}>
                                                        <Col lg={6} md={6} sm={6} xs={6}>
                                                            <div className='depositWithdrawalImgDiv' id={'depositWithdrawalImgDiv' + i}>
                                                                <img src={item.img} className='' />
                                                            </div>
                                                        </Col>
                                                        <Col lg={4} md={5} sm={5} xs={5}>
                                                            <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                                        </Col>
                                                        <Col lg={11} md={11} sm={11} xs={11} className='mt-4 mt-lg-3'>
                                                            <span className='font-Bold sectionDivTitle text-white d-block text-left text-capitalize mb-3'>{translate(props.lang, item.title)}</span>
                                                            <span className='font-Regular sectionDesc text-light-purple d-block'>{translate(props.lang, item.desc)}</span>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                    </Slider>
                                    <div className="d-flex align-items-center justify-content-center mt-4">
                                        <div className='d-flex justify-content-start align-items-center arrow-btn mr-3 mr-lg-4' onClick={() => slider2?.current?.slickPrev()}>
                                            <div className='btn'>
                                                <FaArrowLeft className='sectionDivTitle text-white' />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start align-items-center arrow-btn' onClick={() => slider2?.current?.slickNext()}>
                                            <div className='btn'>
                                                <FaArrowRight className='sectionDivTitle text-white' />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(DepositWithdrawal);