import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function MarketingTools(props) {
    return (
        <>
            <div className='calendar' id="market-sentiment-tool">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-around align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={4} md={5} sm={7} xs={7} className='mb-4 mb-lg-0'>
                            <img src={require("../../assets/img/research/marketingToolsImg.webp").default} alt="" className="w-100 py-4 mx-auto floating3" />
                        </Col>
                        <Col lg={6} md={6} sm={11} xs={11} className=''>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-3'>{translate(props.lang, "Market Sentiment Tool")}</span>
                            <span className='font-Regular sectionDesc text-light-purple'>{translate(props.lang, "Understanding the critical role of timely information, VTGM offers our clients access to the most sophisticated tools available. Market Buzz stands out with its intuitive design and user-friendly interface. It presents a clear and precise snapshot of market sentiment for each instrument by aggregating daily insights from professional news articles, social media and individual blogs.")}</span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(MarketingTools);