import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function HomeHeader(props) {
    return (
        <>
            <div className='header' id="join-grow-multiply">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100 header-row'>
                        <Col lg={12} md={12} sm={12} xs={12} className=''>
                            <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                                <Col lg={10} md={11} sm={12} xs={12} className=''>
                                    {
                                        props.lang === "EN" ?
                                            <div className='d-block d-lg-flex justify-content-center align-items-center px-0 mx-0 w-100 mb-3 mb-lg-0'>
                                                <span className='font-Black headerTitle text-white text-uppercase d-block'>{translate(props.lang, "Join")}</span>
                                                <img src={require("../../assets/img/home/glowDot.webp").default} alt="" className="glowDot d-none d-lg-block" />
                                                <span className='font-Black headerTitle text-white text-uppercase d-block'>{translate(props.lang, "Glow")}</span>
                                                <img src={require("../../assets/img/home/glowDot.webp").default} alt="" className="glowDot d-none d-lg-block" />
                                                <span className='font-Black headerTitle text-white text-uppercase d-block'>{translate(props.lang, "Multiply")}</span>
                                            </div>
                                            :
                                            <div className='d-flex justify-content-center align-items-center px-0 mx-0 w-100 mb-3 mb-lg-0'>
                                                <span className='font-Black headerTitle text-white text-uppercase d-block'>{translate(props.lang, "Join")}</span>
                                                <img src={require("../../assets/img/home/glowDot.webp").default} alt="" className="glowDot d-none d-lg-block" />
                                                <span className='font-Black headerTitle text-white text-uppercase d-block mx-3 mx-lg-0'>{translate(props.lang, "Glow")}</span>
                                                <img src={require("../../assets/img/home/glowDot.webp").default} alt="" className="glowDot d-none d-lg-block" />
                                                <span className='font-Black headerTitle text-white text-uppercase d-block'>{translate(props.lang, "Multiply")}</span>
                                            </div>
                                    }

                                    {
                                        props.lang === "EN" ?
                                            <div className='text-left text-lg-center'>
                                                <span className='font-Regular sectionDesc text-light-purple'>{translate(props.lang, "Become a PAMM account investor, earn without having to trade yourself by entrusting your funds to an experienced trader.")}</span>
                                            </div>
                                            :
                                            <div className='text-center'>
                                                <span className='font-Regular sectionDesc text-light-purple'>{translate(props.lang, "Become a PAMM account investor, earn without having to trade yourself by entrusting your funds to an experienced trader.")}</span>
                                            </div>
                                    }
                                </Col>
                            </Row>

                            <Row className='justify-content-center align-items-center px-0 mx-0 w-100 mt-4 mt-lg-0 h-100 position-relative'>
                                <Col lg={3} md={2} sm={2} xs={2} className='headerImgPos1'>
                                    <img src={require("../../assets/img/home/headerImg2.webp").default} alt="" className="w-100 floating2" />
                                </Col>
                                <Col lg={6} md={9} sm={10} xs={10} className='mt-5 mt-lg-0 position-relative'>
                                    <img src={require("../../assets/img/home/headerImg1.png").default} alt="" className="w-100 floating" />
                                    <img src={require("../../assets/img/home/headerImgDeco1.png").default} alt="" className="floating2 decoImg1" />
                                    <img src={require("../../assets/img/home/headerImgDeco2.png").default} alt="" className="floating2 decoImg2" />
                                </Col>
                                <Col lg={3} md={2} sm={2} xs={2} className='headerImgPos2'>
                                    <img src={require("../../assets/img/home/headerImg3.webp").default} alt="" className="w-100 floating2" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(HomeHeader);