import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import "../assets/scss/navBar.scss";
import "../assets/scss/menu.scss";
import { Collapse, Navbar, NavItem, Nav, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import MenuMobile from "views/Menu/MenuMobile";
import { FaGlobe } from "react-icons/fa";
import { translate } from "views/utils/translate";
import MenuDesktop from "views/Menu/MenuDesktop";

const lang = [
  { name: 'English', code: 'EN' },
  { name: '中文', code: 'ZH' },
]

function IndexNavbar(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [menu, setMenu] = React.useState(true);
  const [path, setPath] = React.useState('/');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const toggle2 = () => setDropdownOpen2((prevState) => !prevState);

  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  const toggle3 = () => setDropdownOpen3((prevState) => !prevState);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  useLayoutEffect(() => {
    setMenu(sessionStorage && sessionStorage.getItem('vtgm-menuPage') ? sessionStorage.getItem('vtgm-menuPage') === "false" ? false : true : true);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('vtgm-menuPage', menu);
    if (menu === true) {
      window.scrollTo(0, 0);
      document.getElementById('menuPage').style.transform = 'translate(0, 0)';
      document.getElementById('menuPage').style.clipPath = 'none';
      document.getElementById('menuPage').style.borderRadius = '0%';
    }
    else {
      document.getElementById('menuPage').style.transform = 'translate(-120vw, 0)';
      document.getElementById('menuPage').style.clipPath = 'circle(40%)';
      document.getElementById('menuPage').style.borderRadius = '50%';
    }
  }, [menu]);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 30 ||
        document.body.scrollTop > 30
      ) {
        setNavbarColor("navbar-vtgm");
      } else if (
        document.documentElement.scrollTop < 30 ||
        document.body.scrollTop < 30
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const changeLanguage = (language) => {
    dispatch({
      type: 'UPDATE_LANGUAGE',
      lang: language
    });
    localStorage.setItem('vtgm-lang', language);
  }

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color={'black'}>
        <div className="navbar-translate d-flex justify-content-between align-items-center w-100 h-100">
          <img src={require('../assets/img/menu/menuBtn.webp').default} className="img-fluid menuToggleBtn cursor-pointer" alt="menu" onClick={() => setMenu(!menu)} />

          <a href="/our-core" className="mx-auto text-center">
            <img src={require('../assets/img/logo/logo.webp').default} className="img-fluid logo-header" alt="logo" />
          </a>

          {
            window.innerWidth > 991 ?
              null :
              window.location.pathname === "/our-core" || window.location.pathname === "/company" || window.location.pathname === "/research" ?
                <div className="languageDropdown">
                  <UncontrolledDropdown>
                    <Dropdown isOpen={dropdownOpen2} toggle={toggle2} className="h-100">
                      <DropdownToggle caret className="">
                        <FaGlobe className="sectionDivTitle" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        {lang.map((item, i) => (
                          <DropdownItem onClick={() => changeLanguage(item.code)}><span className="smallText">{item.name}</span></DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </UncontrolledDropdown>
                </div> :
                menu && menu ?
                  <div className="languageDropdown">
                    <UncontrolledDropdown>
                      <Dropdown isOpen={dropdownOpen3} toggle={toggle3} className="h-100">
                        <DropdownToggle caret className="">
                          <FaGlobe className="sectionDivTitle" />
                        </DropdownToggle>
                        <DropdownMenu right>
                          {lang.map((item, i) => (
                            <DropdownItem onClick={() => changeLanguage(item.code)}><span className="smallText">{item.name}</span></DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </UncontrolledDropdown>
                  </div>
                  :
                  <button
                    className="navbar-toggler navbar-toggler navBarBg"
                    onClick={() => {
                      document.documentElement.classList.toggle("nav-open");
                      setCollapseOpen(!collapseOpen);
                    }}
                    aria-expanded={collapseOpen}
                    type="button"
                  >
                    <span className="navbar-toggler-bar top-bar"></span>
                    <span className="navbar-toggler-bar middle-bar"></span>
                    <span className="navbar-toggler-bar bottom-bar"></span>
                  </button>
          }

        </div>

        <Collapse
          className="justify-content-between align-items-center"
          isOpen={collapseOpen}
          navbar
        >
          <Nav navbar className="align-items-center">
            {
              menu && menu ?
                null :
                window.location.pathname === "/referral-program" || window.location.pathname === "/partnership" ?
                  <>
                    <NavItem>
                      <a href="/partnership" className={"mx-auto text-center text-decoration-none"}>
                        <span className={window.location.pathname === "/partnership" ? "navbarText text-white" : "navbarText text-light-purple"}>
                          {translate(props.lang, "Partnership")}
                        </span>
                      </a>
                    </NavItem>
                    <NavItem>
                      <a href="/referral-program" className="mx-auto text-center text-decoration-none">
                        <span className={window.location.pathname === "/referral-program" ? "navbarText text-white" : "navbarText text-light-purple"}>
                          {translate(props.lang, "Referral Program")}
                        </span>
                      </a>
                    </NavItem>
                  </>
                  :
                  window.location.pathname === "/product" || window.location.pathname === "/platform" || window.location.pathname === "/account-overview" ?
                    <>
                      <NavItem>
                        <a href="/product" className="mx-auto text-center text-decoration-none">
                          <span className={window.location.pathname === "/product" ? "navbarText text-white" : "navbarText text-light-purple"}>
                            {translate(props.lang, "Product")}
                          </span>
                        </a>
                      </NavItem>
                      <NavItem>
                        <a href="/platform" className="mx-auto text-center text-decoration-none">
                          <span className={window.location.pathname === "/platform" ? "navbarText text-white" : "navbarText text-light-purple"}>
                            {translate(props.lang, "Platform")}
                          </span>
                        </a>
                      </NavItem>
                      <NavItem>
                        <a href="/account-overview" className="mx-auto text-center text-decoration-none">
                          <span className={window.location.pathname === "/account-overview" ? "navbarText text-white" : "navbarText text-light-purple"}>
                            {translate(props.lang, "Account Overview")}
                          </span>
                        </a>
                      </NavItem>
                    </>
                    :
                    null
            }

            <div className="languageDropdown d-none d-lg-block">
              <UncontrolledDropdown>
                <Dropdown isOpen={dropdownOpen} toggle={toggle} className="h-100">
                  <DropdownToggle caret className="">
                    <FaGlobe className="sectionDivTitle" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    {lang.map((item, i) => (
                      <DropdownItem onClick={() => changeLanguage(item.code)}><span className="smallText">{item.name}</span></DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </UncontrolledDropdown>
            </div>

            <div className="d-block d-lg-none">
              <hr className="borderWhite d-block mb-4"/>
              {lang.map((item, i) => (
                <div className="text-center" onClick={() => changeLanguage(item.code)}><span className={props.lang === item.code ? "navbarText text-white": "navbarText text-light-purple"}>{item.name}</span></div>
              ))}
            </div>
          </Nav>
        </Collapse>
      </Navbar>

      {
        window.innerWidth > 1200 ?
          <MenuDesktop />
          :
          <MenuMobile />
      }
    </>
  );
}

const mapStateToProps = (state) => {
  const { i18n } = state;
  return {
    lang: i18n.lang,
  };
};

export default connect(mapStateToProps)(IndexNavbar);