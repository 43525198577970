import React, { Suspense, useEffect, useLayoutEffect } from "react";
import Lottie from "lottie-react";
import { MouseParallax } from 'react-just-parallax';
import TextTransition, { presets } from 'react-text-transition';
import { useScrollDirection } from 'react-use-scroll-direction'
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import { translate } from "views/utils/translate";
import { connect, useDispatch } from 'react-redux';

import homeIcon from "../../assets/img/menu/home.json";
import companyIcon from "../../assets/img/menu/company.json";
import partnershipIcon from "../../assets/img/menu/partnership.json";
import researchIcon from "../../assets/img/menu/research.json";
import tradingIcon from "../../assets/img/menu/trading.json";

import { useHistory } from "react-router";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const MenuMobile = (props) => {
    const history = useHistory();
    const [textBgChanged, setTextBgChanged] = React.useState(0);
    const [sliderArray, setSliderArray] = React.useState(null);
    const [carouselState, setCarouselState] = React.useState({
        goToSlide: 0,
        offsetRadius: 1,
        showNavigation: false,
        enableSwipe: true,
        config: config.gentle
    })
    let sliderArrayTemp = [
        { name: translate(props.lang, "OUR-CORE") },
        { name: translate(props.lang, "TRADING") },
        { name: translate(props.lang, "PARTNERSHIP") },
        { name: translate(props.lang, "RESEARCH") },
        { name: translate(props.lang, "COMPANY") },
    ]

    useLayoutEffect(() => {
        if (window.location.pathname === "/our-core") {
            setTextBgChanged(0);
        }
        else if (window.location.pathname === "/product" || window.location.pathname === "/platform" || window.location.pathname === "/account-overview") {
            setTextBgChanged(1);
        }
        else if (window.location.pathname === "/partnership" || window.location.pathname === "/referral-program") {
            setTextBgChanged(2);
        }
        else if (window.location.pathname === "/research") {
            setTextBgChanged(3);
        }
        else if (window.location.pathname === "/company") {
            setTextBgChanged(4);
        }
    },[props.lang])

    useEffect(() => {
        setSliderArray(sliderArrayTemp);
    }, [props.lang]);

    const goToNextSlide = () => {
        let sliderIndex = textBgChanged;
        if (textBgChanged >= sliderArray.length - 1) {
            sliderIndex = -1;
        }
        setTextBgChanged(sliderIndex + 1);
    }

    const goToPrevSlide = () => {
        let sliderIndex = textBgChanged;
        if (textBgChanged <= 0) {
            sliderIndex = sliderArray.length;
        }
        setTextBgChanged(sliderIndex - 1);
    }

    const redirectToPage = () => {
        sessionStorage.setItem('vtgm-menuPage', false);
        document.getElementById('menuPage').style.transform = 'translate(0, 0)';
        document.getElementById('menuPage').style.clipPath = 'none';
        document.getElementById('menuPage').style.borderRadius = '0%';

        if (textBgChanged === 0) {
            if (window.location.pathname !== "/our-core") {
                history.push('/our-core');
            }
            else {
                window.location.reload();
            }
        }
        else if (textBgChanged === 1) {
            if (window.location.pathname === "/product") {
                window.location.reload();
            }
            else {
                history.push('/product');
            }
        }
        else if (textBgChanged === 2) {
            if (window.location.pathname === "/partnership") {
                window.location.reload();
            }
            else {
                history.push('/partnership');
            }
        }
        else if (textBgChanged === 3) {
            if (window.location.pathname !== "/research") {
                history.push('/research');
            }
            else {
                window.location.reload();
            }
        }
        else if (textBgChanged === 4) {
            if (window.location.pathname !== "/company") {
                history.push('/company');
            }
            else {
                window.location.reload();
            }
        }
    }

    let xDown = null;
    let yDown = null;

    const getTouches = (evt) => {
        return (
            evt.touches || evt.originalEvent.touches // browser API
        ); // jQuery
    };

    const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    const handleTouchMove = (evt) => {
        if (!xDown || !yDown) { return; }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                goToNextSlide();
            } else {
                goToPrevSlide();
            }
        }
        xDown = null;
        yDown = null;
    };

    const boxRef = React.useRef(null);

    return (
        <>
            <div
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                ref={boxRef}
            >
                <div className="menu" id="menuPage" draggable={false}>
                    <div id="homeHeader" className="position-relative w-100" draggable={false}>
                        <div className="staggerTextBg text-center w-100 inline" draggable={false}>
                            {`${sliderArray && sliderArray[textBgChanged].name}`.split("").map((txt, i) => (
                                <TextTransition key={i} delay={i * 100} springConfig={presets.gentle} inline className={textBgChanged === 2 ? 'partnershipText textTrans' + props.lang : 'textTrans' + props.lang}>
                                {txt}
                                </TextTransition>
                            ))}
                        </div>

                        <div className="menu-slider-3d w-100 h-100 position-relative">
                            <div className="menuMobile w-100 h-100 d-flex justify-content-center align-items-center">
                                {
                                    textBgChanged === 0 ?
                                        <Lottie animationData={homeIcon} className="lottieFile" onClick={() => redirectToPage()} />
                                        :
                                        textBgChanged === 1 ?
                                            <Lottie animationData={tradingIcon} className="lottieFile" onClick={() => redirectToPage()} />
                                            :
                                            textBgChanged === 2 ?
                                                <Lottie animationData={partnershipIcon} className="lottieFile" onClick={() => redirectToPage()} />
                                                :
                                                textBgChanged === 3 ?
                                                    <Lottie animationData={researchIcon} className="lottieFile" onClick={() => redirectToPage()} />
                                                    :
                                                    <Lottie animationData={companyIcon} className="lottieFile2" onClick={() => redirectToPage()} />
                                }
                            </div>

                            <div className="navigationPos w-100 h-100">
                                <div />
                                <div className='d-flex justify-content-start align-items-center arrow-btn leftArrowPos' >
                                    <div className='btn' onClick={() => goToPrevSlide()}>
                                        <FaArrowLeft className='sectionDivTitle text-white ' />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-start align-items-center arrow-btn rightArrowPos' >
                                    <div className='btn' onClick={() => goToNextSlide()}>
                                        <FaArrowRight className='sectionDivTitle text-white' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        lang: i18n.lang,
    };
};

export default connect(mapStateToProps)(MenuMobile);
