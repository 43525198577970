import React from 'react'
import { FaAngleDown, FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import faqIcon1 from "../../../assets/img/accountOverview/faqIcon1.png";
import faqIcon2 from "../../../assets/img/accountOverview/faqIcon2.png";

const paymentOptionsArray = [
    {
        icon: faqIcon1,
        title: "EFT | Electronic Fund Transfer (Recommended)",
        content: [
            {
                title: "Execution Time",
                desc: "Up to two business days."
            },
            {
                title: "Cost",
                desc: "No charge ."
            }
        ]
    },
    {
        icon: faqIcon2,
        title: "USDT Payment",
        content: [
            {
                title: "Acceptance",
                desc: "We accept USDT payments through BTC, ERC20, or TRC channels."
            },
            {
                title: "Execution Time",
                desc: 'Deposits are processed within 5-10 minutes, while withdrawals take 1-2 working days.'
            },
            {
                title: "Fees",
                desc: 'No transaction fee is charged.'
            }
        ]
    }
]

function PaymentOptions(props) {
    const [selectedContent, setSelectedContent] = React.useState(0);

    return (
        <>
            <div className='paymentOptions' id="payment-options">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-stretch mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={11} xs={11} className='px-0'>
                            <Row className='justify-content-start align-items-stretch mx-0 w-100 h-100'>
                                <Col lg={8} md={12} sm={12} xs={12} className='mb-3 text-start'>
                                    <span className='font-Black sectionHeaderTitle text-white d-block text-start text-uppercase mb-3'>
                                        {translate(props.lang, "Payment Options")}
                                    </span>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className='py-5'>
                                    <div className='accordionSection'>
                                        <Accordion preExpanded={[0]} className="text-left reveal fade-right">
                                            {paymentOptionsArray.map((item, i) => (
                                                <AccordionItem key={i} uuid={i} className={`${selectedContent === i ? "active accordion-item" : "accordion-item"}`} onClick={() => setSelectedContent(i)}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            <div className='accordionHeaderPadding'>
                                                                <Row className='justify-content-start align-items-center'>
                                                                    <Col lg={10} md={10} xs={10} sm={10}>
                                                                        <div className='d-flex justify-content-start align-items-center'>
                                                                            <img src={item.icon} className='' />
                                                                            <span className='font-Bold sectionDivTitle text-white d-block text-start'>
                                                                                {translate(props.lang, item.title)}
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} md={2} xs={2} sm={2}>
                                                                        <div className="text-right">
                                                                            <FaAngleDown className={`h3 my-0 accordionIcon sectionDivTitle ${i === selectedContent ? "active" : ""}`} />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel className='p-0'>
                                                        <div className='accordionHeaderPadding py-0'>
                                                            <Row className='justify-content-center align-items-center'>
                                                                <Col lg={12} md={12} sm={12} xs={12} className='pb-4'>
                                                                    {
                                                                        item.content.map((data, i) => (
                                                                            <div className='mb-4'>
                                                                                <span className='font-Bold sectionDesc text-light-purple d-block text-start mb-1'>
                                                                                    {translate(props.lang, data.title)}
                                                                                </span>
                                                                                <span className='font-Regular sectionDesc text-white d-block text-start mb-1'>
                                                                                    {translate(props.lang, data.desc)}
                                                                                </span>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            ))}
                                        </Accordion>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(PaymentOptions);