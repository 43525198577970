import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function CommoditiesHeader(props) {
    return (
        <>
            <div className='forexHeader' id="commodities">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-around align-items-center px-0 mx-0 w-100 h-100 forexHeaderRow'>
                        <Col lg={4} md={5} sm={7} xs={7} className='mb-4 mb-lg-0'>
                            <img src={require("../../../assets/img/product/commoditiesImg.webp").default} alt="" className="d-block w-100 floating3" />
                        </Col>
                        <Col lg={5} md={9} sm={11} xs={11}>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center text-lg-left'>
                            {translate(props.lang, "Commodities")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center text-lg-left mb-3'>
                                {translate(props.lang, "Trade CFDs on commodities, ranging from gold and silver to WTI and Brent oil.")}
                            </span>

                            <span className='font-Bold sectionDivTitle text-white d-block text-left mb-3 mt-4'>
                                {translate(props.lang, "What Are Commodities?")}
                            </span>

                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "Commodities are commercial products that appear naturally in the ground or are agriculturally cultivated.")}
                            </span>

                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "Changes in commodities price also tend to affect the entire supply chain. At our platform, commodities such as precious metals and energies are available for CFD trading.")}
                            </span>

                            <span className='font-Bold sectionDivTitle text-white d-block text-left mb-3 mt-4'>
                                {translate(props.lang, "What Are Precious Metal?")}
                            </span>

                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "Precious metals are metals commodities that are often considered by investors seeking diversification during periods of market volatility or bear markets.")}
                            </span>

                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "Precious metals are also known as reliable metals with real and conveyable value which can be used as a hedge against periods of high inflation or currency devaluation.")}
                            </span>

                        </Col>
                    </Row>

                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(CommoditiesHeader);