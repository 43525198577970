import React from 'react'
import { FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

const fundManagerArray = [
    { index: '01.', title: 'Trade Well & Get Rewarded', desc: 'Whether you run an investment firm or are a professional trader, you can utilize VTGM’s Multi Account Manager to manage funds on behalf of your clients. Our MT5 Multi Account Manager allows you to trade on your favourite MT5 while simultaneously trading on every one of your client’s accounts.' },
    { index: '02.', title: 'Streamlined, paperless client onboarding', desc: 'Give your clients the absolute best experience with our streamlined, paperless onboarding process. Whether you’re bringing on 10 or 1,000 clients, our powerful onboarding system will ensure you can focus on trading while we handle the onboarding.' },
    { index: '03.', title: 'Customisable Fee Structures', desc: 'We understand that every relationship is unique and every client has different expectations. Easily customise the fee structures to suit different clients and even create your own tiers for various groups of clients.' },
]

function FundManager(props) {
    return (
        <>
            <div className='fundManager' id="fund-manager">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={7} md={11} sm={11} xs={11} className=''>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center'>{translate(props.lang, "Fund Manager")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                {translate(props.lang, "Our industry leading MAM (Multi Account Manager) and PAMM (Percentage Allocation Management Module) program is designed for qualified and experienced fund managers and each account has dedicated senior account managers to assist you.")}
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='fundManagerContent px-4 px-lg-0'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center justify-content-md-start justify-content-lg-around align-items-stretch px-0 mx-0 w-100 h-100 fundManagerBoxSection'>
                        {
                            fundManagerArray.map((item, i) => (
                                <Col lg={4} md={6} sm={11} xs={11} className='mb-4'>
                                    <Row className='justify-content-center align-items-start fundManagerBox mx-0 h-100' id={"fundManagerBox" + i}>
                                        <Col lg={11} md={11} sm={11} xs={11}>
                                            <span className='font-Bold sectionDivTitle text-index-style d-block text-left text-capitalize'>{item.index}</span>
                                        </Col>
                                        <Col lg={11} md={11} sm={11} xs={11} className=''>
                                            <span className='font-Regular sectionDivTitle text-white d-block my-4 text-capitalize'>{translate(props.lang, item.title)}</span>
                                            <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, item.desc)}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(FundManager);