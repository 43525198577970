import React from 'react'
import { FaArrowRight, FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";
import Lottie from "lottie-react";
import pammAnim from "../../assets/img/home/pamm.json";

function Pamm(props) {
    return (
        <>
            <div className='pamm' id="pamm">
                <Container className='w-100 h-100 position-relative'>
                    <Lottie animationData={pammAnim} className='absoluteBgLottie' />
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100 header-row'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                                <Col lg={9} md={10} sm={11} xs={11} className=''>
                                    <span className='font-Black sectionHeaderTitle text-white d-block text-center text-uppercase mb-4'>{translate(props.lang, "Take a look at")} <span className='linearType1'>{translate(props.lang, "PAMM")}</span></span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-center mb-3'>
                                        {translate(props.lang, "Subscribe PAMM to obtain a competitive advantage from our team of professionals, who are all traders with a track record of success.")}
                                    </span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                        {translate(props.lang, "With our exclusive PAMM service, you may now profitably trade Forex and CFDs even if you have no prior trading experience. Run out of time to trade? Our PAMM will take care of everything. Invest and relax.")}
                                    </span>

                                    <div className='mt-4 d-flex justify-content-center align-items-center vtgm-btn mx-auto'>
                                        <div className='btn'>
                                            <FaArrowRight className='sectionDivTitle text-white' />
                                        </div>
                                        <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Subscribe")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Pamm);