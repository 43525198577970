import React from "react";
import "../../assets/scss/research.scss";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import ResearchHeader from "./ResearchHeader";
import Calculator from "./Calculator";
import Calendar from "./Calendar";
import APISection from "./APISection";
import MarketingTools from "./MarketingTools";
import DiscoverTools from "./DiscoverTools";
import SideBar from "components/SideBar";


const Research = () => {

    return (
        <>
            <IndexNavbar />
            <SideBar />
            <div className="research">
                <ResearchHeader />
                <Calculator />
                <Calendar />
                <APISection />
                <MarketingTools />
                <DiscoverTools />
            </div>
            <DarkFooter />
        </>
    );
};

export default Research;
