import React from 'react'
import { FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";
import platformDeviceIcon5 from "../../assets/img/company/platformDeviceIcon1.png";
import platformDeviceIcon6 from "../../assets/img/company/platformDeviceIcon2.png";

function LiquidityPartner(props) {
    return (
        <>
            <div className='liquidityPartner' id="liquidity-partner">
                <Container className='w-100 h-100'>
                    {/* <Row className='justify-content-center align-items-stretch mx-0 w-100 mb-5'>
                        <Col lg={6} md={11} sm={11} xs={11} className='mb-4'>
                            <Row className='justify-content-around align-items-stretch platformDevice2Box mx-0 h-100' id={"platformDevice2Box0"}>
                                <Col lg={11} md={11} sm={11} xs={11}>
                                    <img src={platformDeviceIcon5} alt="" className="mb-5 floating3" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs={11}>
                                    <span className='font-Black sectionDivTitle text-white text-uppercase d-block mb-5'>{translate(props.lang, "Client money held in segregated accounts with top-tier bank")}</span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-left'>{translate(props.lang, "Client money is held in segregated accounts. Valk Trade banks with Westpac, which is one of four major banking organisations in Australia and has over 200 years of continuous service.")}</span>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={6} md={11} sm={11} xs={11} className='mb-4'>
                            <Row className='justify-content-around align-items-stretch platformDevice2Box mx-0 h-100' id={"platformDevice2Box1"}>
                                <Col lg={11} md={11} sm={11} xs={11}>
                                    <img src={platformDeviceIcon6} alt="" className="mb-5 floating4" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs={11}>
                                    <span className='font-Black sectionDivTitle text-white text-uppercase d-block mb-5'>{translate(props.lang, "Professional indemnity insurance to protect client's financial security")}</span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-left'>{translate(props.lang, "VALK TRADE operates with adequate Professional Indemnity Insurance (PI insurance) in place. Our PI insurance is underwritten by Falcon Insurance, which received its insurance license in 2013. PI insurance is a safe net that covers the costs and expenses of responding to claims arising from professional services.")}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}

                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={11} xs={11} className='px-0'>
                            <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                                <Col lg={6} md={12} sm={12} xs={12} className='mb-3'>
                                    <span className='font-Black sectionHeaderTitle text-white d-block text-center text-uppercase mb-2'>{translate(props.lang, "Liquidity Partner")}</span>
                                    <span className='font-Regular sectionDesc text-light-purple d-block text-center text-lg-start'>
                                        {translate(props.lang, "Trusted liquidity partner to ensure your trading comfort and speed")}
                                    </span>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className=''>
                                    <Row className='justify-content-center align-items-start px-0 mx-0 w-100 h-100 liquidityPartnerBox'>
                                        <Col lg={12} md={12} sm={12} xs={12} className='mb-3 text-center'>
                                            <img src={require("../../assets/img/company/partnerImg2.webp").default} alt="" className="mb-5 announcementImg mx-auto" />
                                        </Col>
                                        <Col lg={8} md={11} sm={11} xs={11} className='mb-3'>
                                            <span className='font-Black sectionDivTitle text-white d-block text-center mb-2'>{translate(props.lang, "VT is pleased to announce the alliance with")} <span className='text-orange'>{translate(props.lang, "GBE Prime")}</span> {translate(props.lang, "in terms of strategic STP liquidity partner.")}</span>
                                        </Col>
                                        <Col lg={8} md={11} sm={11} xs={11} className='mb-3'>
                                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                                {translate(props.lang, "GBE Prime is a fully regulated broker and liquidity provider offering aggregated, institutional liquidity to brokers, banks, funds and asset managers operating in a regulated environment.")}
                                            </span>
                                        </Col>
                                        <Col lg={8} md={11} sm={11} xs={11} className='mb-3'>
                                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                                {translate(props.lang, "GBE Prime is a brand of GBE brokers and focuses exclusively on institutional business activities.")}
                                            </span>
                                        </Col>
                                        <Col lg={8} md={11} sm={11} xs={11} className='mb-3'>
                                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                                {translate(props.lang, "GBE Prime is offering advanced, aggregated liquidity in the Equinix Datacenters in LD4 (London), NY4 (New York) and TY3 (Tokyo), using state of the art bridging and aggregation software, ultra-low-latency connectivity and institutional grade hosting solutions.")}
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(LiquidityPartner);