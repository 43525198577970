import React from 'react'
import { FaArrowLeft, FaArrowRight, FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import whyBrokerIcon1 from "../../../assets/img/referralProgram/whyBrokerVTGMIcon.webp";
import whyBrokerIcon2 from "../../../assets/img/referralProgram/whyBrokerVTGMIcon2.webp";
import whyBrokerIcon3 from "../../../assets/img/referralProgram/whyBrokerVTGMIcon3.webp";
import whyBrokerIcon4 from "../../../assets/img/referralProgram/whyBrokerVTGMIcon4.webp";
import whyBrokerIcon5 from "../../../assets/img/referralProgram/whyBrokerVTGMIcon5.webp";
import whyBrokerIcon6 from "../../../assets/img/referralProgram/whyBrokerVTGMIcon6.webp";

const whyBrokerVTGMArray = [
    { img: whyBrokerIcon1, title: 'Add on additional spread', desc: 'Aside from the rebate, IB can choose to add on additional spreads to obtain higher income.' },
    { img: whyBrokerIcon3, title: 'Automated Rebate system', desc: "Strong IB Client management system, enabling rebate tracking in real-time, your rebate will arrive in with no commission cap." },
    { img: whyBrokerIcon4, title: '1 on 1 Dedicated account manager', desc: 'Dedicated service, efficiently communicating all queries with your account manager.' },
    { img: whyBrokerIcon5, title: 'Multi-level Rebate', desc: 'Customise your Rebate, set up multi-level rebate to obtain more clients.' },
    { img: whyBrokerIcon6, title: 'Exclusive online promotions', desc: 'Prospero Provides a large variety of bonus and gifts, which both retail and IB can participate to stimulate their trading passion.' },
]

function WhyBrokerVTGM(props) {
    const [imageIndex, setImageIndex] = React.useState(0);
    const slider2 = React.useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 991 ? 3 : window.innerWidth > 767 ? 2 : 1,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='whyBrokerVTGM'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-stretch mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={11} xs={11} className='px-0'>
                            <Row className='justify-content-center align-items-stretch mx-0 w-100 h-100'>
                                <Col lg={8} md={12} sm={12} xs={12} className='mb-3 text-center'>
                                    <span className='font-Black sectionHeaderTitle2 text-white d-block text-center text-uppercase mb-3'>
                                        {translate(props.lang, "Not getting enough?")}
                                    </span>
                                    <span className='font-Regular sectionDesc text-white d-block'>
                                        {translate(props.lang, "Get More When You Become An Introducing Broker With VTGM")}
                                    </span>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className=''>
                                    <Slider {...settings} arrows={false} ref={slider2} className='' >
                                        {
                                            whyBrokerVTGMArray.map((item, i) => {
                                                return (
                                                    <div className="w-100 px-2 h-100 whyBrokerVTGMBoxSection">
                                                        <Row className='justify-content-center align-items-start whyBrokerVTGMBox mx-0 h-100' id={"whyBrokerVTGMBox" + i}>
                                                            <Col lg={12} md={10} sm={10} xs={10}>
                                                                <div className='whyBrokerVTGMImgDiv mx-auto' id={'whyBrokerVTGMImgDiv' + i}>
                                                                    <img src={item.img} className='mx-auto' />
                                                                </div>

                                                                <span className='font-Regular sectionDivTitle text-white text-center d-block my-4'>{translate(props.lang, item.title)}</span>
                                                                <span className='font-Regular sectionDesc text-white text-center d-block'>{translate(props.lang, item.desc)}</span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                    </Slider>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className='d-flex justify-content-start align-items-center arrow-btn mr-3 mr-lg-4'  onClick={() => slider2?.current?.slickPrev()}>
                                            <div className='btn'>
                                                <FaArrowLeft className='sectionDivTitle text-white' />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start align-items-center arrow-btn'  onClick={() => slider2?.current?.slickNext()}>
                                            <div className='btn'>
                                                <FaArrowRight className='sectionDivTitle text-white' />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(WhyBrokerVTGM);