import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import startTradeIcon1 from "../../assets/img/home/startTradeIcon1.webp";
import startTradeIcon2 from "../../assets/img/home/startTradeIcon2.webp";
import startTradeIcon3 from "../../assets/img/home/startTradeIcon3.webp";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { FaArrowRight } from 'react-icons/fa';

const startTradeArray = [
    { index: '01.', img: startTradeIcon1, title: 'Register', desc: 'Fill in your details and submit documents for verification' },
    { index: '02.', img: startTradeIcon2, title: 'Fund', desc: 'Deposit funds by online banking or cryptocurrency method' },
    { index: '03.', img: startTradeIcon3, title: 'Trade', desc: 'Start trading by choosing your desired account type' },
]

function StartTrading(props) {
    const [imageIndex, setImageIndex] = React.useState(0);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 767 ? 1.4 : 1.1,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='startTrade' id="start-trade">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-end align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                                <Col lg={9} md={10} sm={11} xs={11} className=''>
                                    <div className=''>
                                        <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-2 text-center'>{translate(props.lang, "Start Trading With Us")}</span>
                                        <span className='font-Regular sectionDesc text-light-purple d-block text-center'>{translate(props.lang, "Open an account in just 3 STEPS")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={12} md={12} sm={12} xs={12} className=''>
                            <div className='d-none d-lg-block'>
                                <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100 startTradeInfoSection'>
                                    {
                                        startTradeArray.map((item, i) => (
                                            <Col lg={4} md={10} sm={12} xs={12} className='h-100 mb-3 mb-lg-0'>
                                                <div className='startTradeInfoBox h-100' id={"startTradeInfoBox" + i}>
                                                    <Row className="justify-content-center align-items-center">
                                                        <Col lg={5} md={5} sm={5} xs={5} className='mb-5'>
                                                            <img src={item.img} className={i % 2 > 0 ? 'floating3' : 'floating4'} />
                                                        </Col>
                                                        <Col lg={5} md={5} sm={5} xs={5} className='mb-5'>
                                                            <span className='font-Bold sectionHeaderTitle text-infoIndex-purple d-block text-right'>{item.index}</span>
                                                        </Col>
                                                        <Col lg={10} md={10} sm={10} xs={10}>
                                                            <span className='font-Bold sectionDesc text-white d-block text-left mb-2'>{translate(props.lang, item.title)}</span>
                                                            <span className='font-Regular sectionDesc text-light-purple d-block text-left'>{translate(props.lang, item.desc)}</span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        </Col>

                        <Col lg={12} md={11} sm={11} xs={11} className='px-0'>
                            <div className='d-block d-lg-none my-5'>
                                <Slider {...settings} arrows={false} >
                                    {
                                        startTradeArray.map((item, i) => (
                                            <div className='h-100 mb-3 mb-lg-0 px-2'>
                                                <div className='startTradeInfoBox h-100' id={"startTradeInfoBox" + i}>
                                                    <Row className="justify-content-center align-items-center">
                                                        <Col lg={5} md={5} sm={5} xs={5} className='mb-5'>
                                                            <img src={item.img} className={i % 2 > 0 ? 'floating3' : 'floating4'} />
                                                        </Col>
                                                        <Col lg={5} md={5} sm={5} xs={5} className='mb-5'>
                                                            <span className='font-Bold sectionHeaderTitle text-infoIndex-purple d-block text-right'>{item.index}</span>
                                                        </Col>
                                                        <Col lg={10} md={10} sm={10} xs={10}>
                                                            <span className='font-Bold sectionDesc text-white d-block text-left mb-2'>{translate(props.lang, item.title)}</span>
                                                            <span className='font-Regular sectionDesc text-light-purple d-block text-left'>{translate(props.lang, item.desc)}</span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </Col>
                    </Row>

                    <Row className='justify-content-center justify-content-lg-start align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={12} xs={12} className=''>
                            <Row className='justify-content-center justify-content-lg-start align-items-center px-0 mx-0 w-100 h-100 startTradeBanner'>
                                <Col lg={1} md={1} sm={12} xs={12} className='d-none d-lg-block' />
                                <Col lg={8} md={11} sm={11} xs={11} className=''>
                                    <div className=''>
                                        <span className='font-Black sectionHeaderTitle2 text-white text-uppercase d-block mb-2 text-start'>{translate(props.lang, "Start your trading journey with us and unleash your potential!")}</span>
                                    </div>
                                    <div className='mt-4 d-flex justify-content-start align-items-center vtgm-btn-transparent'>
                                        <div className='btn'>
                                            <FaArrowRight className='sectionDivTitle text-white' />
                                        </div>
                                        <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Trade With Us")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(StartTrading);