import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row, Table } from 'reactstrap'
import { MarketData } from "react-ts-tradingview-widgets";
import { translate } from "views/utils/translate";

const MarketDataSymbolsGroup = [
    {
        "name": "Indices",
        "originalName": "Indices",
        "symbols": [
            {
                "name": "FOREXCOM:SPXUSD",
                "displayName": "S&P 500"
            },
            {
                "name": "FOREXCOM:NSXUSD",
                "displayName": "Nasdaq 100"
            },
            {
                "name": "FOREXCOM:DJI",
                "displayName": "Dow 30"
            },
            {
                "name": "INDEX:NKY",
                "displayName": "Nikkei 225"
            },
            {
                "name": "INDEX:DEU30",
                "displayName": "DAX Index"
            },
            {
                "name": "FOREXCOM:UKXGBP",
                "displayName": "UK 100"
            }
        ]
    }
]

function IndicesContent(props) {

    return (
        <>
            <div className='forexContent px-4 px-lg-0'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-stretch px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={12} sm={12} xs={12} className='mb-3 mt-4'>
                            <span className='font-Bold sectionDivTitle text-white d-block text-left mb-3'>
                                {translate(props.lang, "Live Chart")}
                            </span>
                            <div className='contentLiveChartBox'>
                                <MarketData colorTheme="dark" locale={props.lang === "ZH" ? "zh_CN": "en"} isTransparent={true} symbolsGroups={MarketDataSymbolsGroup} width="100%" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(IndicesContent);