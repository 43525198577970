
const customSelectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: (base, state) => ({
        ...base,
        background: "transparent",
        border: "none",
        boxShadow: state.isFocused ? null : null,
        color: "black",
        padding: "0px 0px",
        width: '100%',
        minHeight: '35px',
        height: '100%'
    }),
    valueContainer: (base) => ({
        ...base,
        paddingLeft: '3%',
        width: '100%',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem'
    }),
    menu: (base) => ({
        ...base,
        marginTop: 0,
        color: "#000000",
        padding: "0px 0px",
        overflow: 'hidden',
        // height: '10em',
        borderRadius: "8px",
        background: "#323241",
        boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        backdropFilter: "blur(10px)",
    }),
    menuList: (base) => ({
        ...base,
        padding: "0px 0px",
    }),
    singleValue: (base) => ({
        ...base,
        color: "#FFFFFF",
        padding: "0px 0px",
        alignItems: "center",
        fontFamily: "40218451935",
        fontWeight: '900',
        display: "flex",
        justifyContent: "start",
        fontSize: window.innerWidth > 991 ? "1vw" : window.innerWidth > 767 ? "2vh" :  "1vh",
        overflow: 'visible',
        top: window.innerWidth > 2000 ? '50%' :'46%'

    }),
    placeholder: (base) => ({
        ...base,
        color: '#fff',
        fontWeight: '900',
        top: window.innerWidth > 2000 ? '50%' :'46%',
        fontFamily: "40218451935",
        fontSize: window.innerWidth > 991 ? "1vw" : window.innerWidth > 767 ? "1.3vh" : "1.4vh",
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? "#323241" : state.isFocused ? "#323241" : "#323241", // Background color when selected or focused
        color: '#fff',
        fontWeight: "500",
        ':hover': {
            background: "#000", // Hover background color in red
            color: '#fff',
            fontWeight: "500"
        },
    }),
};

export default customSelectStyles;