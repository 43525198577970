import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function ForexHeader(props) {
    return (
        <>
            <div className='forexHeader' id="forex">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-around align-items-center px-0 mx-0 w-100 h-100 forexHeaderRow'>
                        <Col lg={5} md={6} sm={9} xs={9} className='mb-4 mb-lg-0'>
                            <img src={require("../../../assets/img/product/forexImg.webp").default} alt="" className="d-block w-100 floating3" />
                        </Col>
                        <Col lg={5} md={9} sm={11} xs={11}>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase d-block mb-4 text-center text-lg-left'>
                                {translate(props.lang, "Forex")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center text-lg-left mb-3'>
                                {translate(props.lang, "Trade a huge selection of currency pairs in the largest financial market with high leverage.")}
                            </span>
                            <span className='font-Bold sectionDivTitle text-white d-block text-left mb-3 mt-4'>
                                {translate(props.lang, "What is Forex?")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "Foreign exchange, commonly known as Forex or FX, relates to the buy and sell on currencies with the aim of making profits from the difference of prices.")}
                            </span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left mb-3'>
                                {translate(props.lang, "The forex market is the world’s largest financial market, which is also the most active and liquid market with over $5.5 trillion worth of currency volume processed each day.")}
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(ForexHeader);