import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../assets/scss/navBar.scss";
import "../assets/scss/menu.scss";
import { connect } from 'react-redux';
import { translate } from "views/utils/translate";

// reactstrap components
import {
    Col,
    Row,
} from "reactstrap";
import "../assets/scss/navBar.scss";
import { FaAngleDown, FaArrowDown, FaArrowRight, FaChevronDown, FaChevronRight, FaGlobe, FaTimes } from "react-icons/fa";
import $ from 'jquery';
import { HashLink } from 'react-router-hash-link';
import { FaList } from "react-icons/fa";

const homeArray = [
    {
        name: "Join·Grow·Multiply",
        href: "join-grow-multiply"
    },
    {
        name: "Empowering Your Trading Experience",
        href: "empowering-experience"
    },
    {
        name: "Why Choose Us",
        href: "why-choose-us"
    },
    {
        name: "Be A Part Of Our Journey",
        href: "our-journey"
    },
    {
        name: "Take A Look At PAMM",
        href: "pamm"
    },
    {
        name: "Start Trading With Us",
        href: "start-trade"
    }
]

const companyArray = [
    {
        name: "About Us",
        href: "about-us"
    },
    {
        name: "Our Advantages",
        href: "our-advantages"
    },
    {
        name: "Regulation and License",
        href: "regulation-and-license"
    },
    {
        name: "Liquidity Partner",
        href: "liquidity-partner"
    },
    {
        name: "Dispute Resolution",
        href: "dispute-resolution"
    },
]

const researchArray = [
    {
        name: "Analytical tools",
        href: "analytical-tools"
    },
    {
        name: "Economic Calendar",
        href: "economic-calendar"
    },
    {
        name: "Market Sentiment Tool",
        href: "market-sentiment-tool"
    }
]

const referralProgramArray = [
    {
        name: "Why become an Introducing Broker?",
        href: "why-become-an-introducing-broker"
    },
    {
        name: "Affiliate Program",
        href: "affiliate-program"
    },
    {
        name: "Fund Manager",
        href: "fund-manager"
    },
    {
        name: "Franchise Model",
        href: "franchise-model"
    }
]

const partnershipArray = [
    {
        name: "To Be Part Of Our Family Member",
        href: "family-member"
    },
    {
        name: "Why Affiliate With Us",
        href: "why-affiliate-with-us"
    },
    {
        name: "Become An Introducing Broker (IBS)",
        href: "become-an-IBS"
    },
]

const accountOverviewArray = [
    {
        name: "Accounts Overview",
        href: "accounts-overview"
    },
    {
        name: "Deposit and Withdrawal",
        href: "deposit-and-withdrawal"
    },
    {
        name: "Payment Options",
        href: "payment-options"
    },
]

const platformArray = [
    {
        name: "PROTRADER",
        href: "PROTRADER"
    },
    {
        name: "WEBTRADER",
        href: "webtrader"
    },
    {
        name: "PAMM/MAMM",
        href: "PAMM/MAMM"
    },
    {
        name: "copy trading",
        href: "copy-trading"
    }
]

const productArray = [
    {
        name: "CFD",
        href: "CFD"
    },
    {
        name: "Forex",
        href: "forex"
    },
    {
        name: "Indices",
        href: "indices"
    },
    {
        name: "Commodities",
        href: "commodities"
    },
    {
        name: "Equities",
        href: "equities"
    },
    {
        name: "ETFs",
        href: "ETFs"
    }
]



function SideBar(props) {
    const history = useHistory();
    const [sectionArray, setSectionArray] = React.useState(homeArray);

    useEffect(() => {
        if (window.location.pathname === "/our-core") {
            setSectionArray(homeArray)
        } else if (window.location.pathname === "/research") {
            setSectionArray(researchArray)
        } else if (window.location.pathname === "/company") {
            setSectionArray(companyArray)
        } else if (window.location.pathname === "/referral-program") {
            setSectionArray(referralProgramArray)
        } else if (window.location.pathname === "/partnership") {
            setSectionArray(partnershipArray)
        } else if (window.location.pathname === "/account-overview") {
            setSectionArray(accountOverviewArray)
        } else if (window.location.pathname === "/platform") {
            setSectionArray(platformArray)
        } else if (window.location.pathname === "/product") {
            setSectionArray(productArray)
        }
    }, []);

    useEffect(() => {
        $(".sideBarSectionHover").hover(function () { // on mouse enter
            $(".sectionNameTextSection").css("display", 'block');
            $(".sideSectionBarSection").css("background", 'linear-gradient(90deg, #000 53.4%, rgba(0, 0, 0, 0.00) 100%)');
            $(".sectionNameText").css("opacity", 1);
            $(".sideSectionBarSection").css("zIndex", 8);
        }, function () { // on mouse leave
            $(".sectionNameText").css("opacity", 0);
            $(".sideSectionBarSection").css("zIndex", 0);
            $(".sideSectionBarSection").css("background", 'transparent');
            $(".sectionNameTextSection").css("display", 'none');
        });
    }, []);

    useEffect(() => {
        document.getElementById('')
    }, []);

    const toggleSectionSideBar = () => {
        document.getElementById('sideSectionBarSection').style.transform = "translateX(0)";
        document.getElementById('pageSectionToggleIcon').style.display = "none";
        document.getElementById('sectionNameTextSection').style.display = "block";
        document.getElementById('sideSectionBarSection').style.display = "block";
    }

    const setSideBarVisible = () => {
        if (window.innerWidth < 991) {
            document.getElementById('sideSectionBarSection').style.transform = "translateX(-100vw)";
            document.getElementById('pageSectionToggleIcon').style.display = "block";
            document.getElementById('sectionNameTextSection').style.display = "block";
            document.getElementById('sideSectionBarSection').style.display = "none";
        }
    }

    return (
        <>
            <div className="sideSectionBarSection" id="sideSectionBarSection">
                <div className="sideSectionBar">
                    {
                        sectionArray.map((item, i) => (
                            <div className="">
                                <HashLink smooth to={window.location.pathname + "#" + item.href} className="text-decoration-none" onClick={() => setSideBarVisible()}>
                                    <Row className="justify-content-start align-items-start sideBarSectionHover">
                                        <Col lg={2} md={2} sm={2} xs={2}>
                                            <div className="sideBarSectionNumber smallText mx-auto">
                                                {i + 1}
                                            </div>
                                            {
                                                i < sectionArray.length - 1 ?
                                                    <>
                                                        <div className="sideDotDeco mx-auto" />
                                                        <div className="sideDotDeco mx-auto" />
                                                        <div className="sideDotDeco mx-auto" />
                                                    </>
                                                    : null
                                            }
                                        </Col>
                                        <Col lg={10} md={10} sm={10} xs={10} className="sectionNameTextSection" id="sectionNameTextSection">
                                            <div className="sectionDesc text-light-purple mt-2 sectionNameText text-capitalize" id="">
                                                {translate(props.lang, item.name)}
                                            </div>
                                        </Col>
                                    </Row>
                                </HashLink>
                            </div>
                        ))
                    }
                </div>
                <div className="d-block d-lg-none">
                    <div className="closeSideBarBtn sectionDivTitle" onClick={() => setSideBarVisible()}>
                        <FaTimes />
                    </div>
                </div>
            </div>
            <div className="d-block d-lg-none">
                <div className="pageSectionToggleIcon" id="pageSectionToggleIcon" onClick={() => toggleSectionSideBar()}>
                    <FaList />
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        lang: i18n.lang,
    };
};

export default connect(mapStateToProps)(SideBar);