import React from "react";
import "../../assets/scss/company.scss";
import IndexNavbar from "components/IndexNavbar";
import DarkFooter from "components/DarkFooter";
import CompanyHeader from "./CompanyHeader";
import MissionVision from "./MissionVision";
import OurAdvantage from "./OurAdvantage";
import License from "./License";
import LiquidityPartner from "./LiquidityPartner";
import EdgePrime from "./EdgePrime";
import Resolution from "./Resolution";
import Complain from "./Complain";
import Certificate from "./Certificate";
import SideBar from "components/SideBar";

const Company = () => {

    return (
        <>
            <IndexNavbar />
            <SideBar />
            <div className="company">
                <CompanyHeader />
                <MissionVision />
                <OurAdvantage />
                <License />
                <LiquidityPartner />
                <EdgePrime />
                <Resolution />
                <Complain />
                <Certificate />
            </div>
            <DarkFooter />
        </>
    );
};

export default Company;
