import React from 'react'
import { FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import platformDeviceIcon1 from "../../../assets/img/platform/platformDeviceIcon1.webp";
import platformDeviceIcon2 from "../../../assets/img/platform/platformDeviceIcon2.webp";
import platformDeviceIcon3 from "../../../assets/img/platform/platformDeviceIcon3.webp";
import platformDeviceIcon4 from "../../../assets/img/platform/platformDeviceIcon4.webp";
import platformDeviceIcon5 from "../../../assets/img/platform/platformDeviceIcon5.webp";
import platformDeviceIcon6 from "../../../assets/img/platform/platformDeviceIcon6.webp";

const platformDeviceArray = [
    { img: platformDeviceIcon1, index: "01.", title: 'Depth of Market for Enhanced Visibility', desc: 'The MT5 Depth of Market (DoM) tool enhances market transparency, allowing traders to view the volume of trades available at different prices, thereby gaining deeper insights into market liquidity.' },
    { img: platformDeviceIcon2, index: "02.", title: 'Advanced Strategy Tester for Optimal EA Performance', desc: "Optimize your Expert Advisors (EAs) with MT5's multi-threaded strategy tester, designed for more efficient backtesting and refinement of your trading strategies." },
    { img: platformDeviceIcon3, index: "03.", title: 'Expanded Timeframes for Comprehensive Market Analysis', desc: "With 21 different timeframes, MT5 broadens your market analysis capabilities, offering varied perspectives to inform your trading decisions." },
    { img: platformDeviceIcon4, index: "04.", title: 'Enhanced Order Flexibility for Precise Trade Execution', desc: "MT5 introduces two additional pending order types, granting you greater control over your trade entries and management." },
]

function PlatformDevice(props) {

    return (
        <>
            <div className='platformDevice'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center justify-content-lg-between align-items-stretch mx-0 w-100 h-100'>
                        <Col lg={6} md={11} sm={11} xs={11} className='mb-5 mb-lg-0'>
                            <div className='h-100 d-flex justify-content-start align-items-center'>
                                <div>
                                    <span className='font-Black sectionHeaderTitle text-white d-block text-start text-uppercase mb-3'>
                                        {translate(props.lang, "A platform for every device")}
                                    </span>
                                    <span className='font-Regular sectionDesc text-white d-block text-start'>
                                        {translate(props.lang, "Echoing the versatility of its predecessor, MT5 facilitates trading both at your desk and on the move, ensuring seamless market access regardless of your location.")}
                                    </span>
                                </div>
                            </div>
                        </Col>
                        {
                            platformDeviceArray.map((item, i) => {
                                return (
                                    <Col lg={4} md={6} sm={11} xs={11} className='mb-4'>
                                        <Row className='justify-content-around align-items-start d-flex platformDeviceBox mx-0 h-100' id={"platformDeviceBox" + i}>
                                            <Col lg={6} md={6} sm={6} xs={6}>
                                                <div className='platformDeviceImgDiv' id={'platformDeviceImgDiv' + i}>
                                                    <img src={item.img} className='' />
                                                </div>
                                            </Col>
                                            <Col lg={4} md={5} sm={5} xs={5}>
                                                <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                            </Col>
                                            <Col lg={11} md={11} sm={11} xs={11} className='mt-3'>
                                                <span className='font-Bold sectionDivTitle text-white d-block text-left text-capitalize mb-3'>{translate(props.lang, item.title)}</span>
                                                <span className='font-Regular sectionDesc text-light-purple d-block'>{translate(props.lang, item.desc)}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                    </Row>

                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(PlatformDevice);