import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row, Input } from 'reactstrap'
import { translate } from "views/utils/translate";
import Select from "react-select";
import customSelectStyles from '../utils/customSelectStyles';
import { FaArrowRight, FaMinus, FaPlus } from 'react-icons/fa';

const symbolArray = [
    { currency: 'EURUSD', currentPrice: '1.08776', pipsValue: '0.9' },
    { currency: 'GBPUSD', currentPrice: '1.27397', pipsValue: '1.3' },
    { currency: 'USDJPY', currentPrice: '148.289', pipsValue: '1.8' },
    { currency: 'USDCHF', currentPrice: '0.88456', pipsValue: '1.3' },
    { currency: 'AUDUSD', currentPrice: '0.65686', pipsValue: '1.4' },
    { currency: 'NZDUSD', currentPrice: '0.61028', pipsValue: '1.4' },
    { currency: 'USDCAD', currentPrice: '1.35348', pipsValue: '1.4' },
    { currency: 'EURGBP', currentPrice: '0.85373', pipsValue: '2' },
    { currency: 'EURJPY', currentPrice: '161.309', pipsValue: '1.9' },
    { currency: 'EURCHF', currentPrice: '0.9622', pipsValue: '2.1' },
    { currency: 'EURAUD', currentPrice: '1.65578', pipsValue: '2.7' },
    { currency: 'EURNZD', currentPrice: '1.78211', pipsValue: '4.1' },
    { currency: 'EURCAD', currentPrice: '1.47227', pipsValue: '2.2' },
    { currency: 'GBPJPY', currentPrice: '188.928', pipsValue: '2' },
    { currency: 'GBPCHF', currentPrice: '1.12695', pipsValue: '1.8' },
    { currency: 'GBPAUD', currentPrice: '1.93929', pipsValue: '2.1' },
    { currency: 'GBPNZD', currentPrice: '2.0873', pipsValue: '2.5' },
    { currency: 'GBPCAD', currentPrice: '1.72433', pipsValue: '2' },
    { currency: 'CHFJPY', currentPrice: '167.623', pipsValue: '3.3' },
    { currency: 'AUDJPY', currentPrice: '97.408', pipsValue: '2.3' },
    { currency: 'AUDCHF', currentPrice: '0.58106', pipsValue: '1.5' },
    { currency: 'AUDNZD', currentPrice: '1.07618', pipsValue: '2.5' },
    { currency: 'AUDCAD', currentPrice: '0.88899', pipsValue: '3.3' },
    { currency: 'NZDJPY', currentPrice: '90.501', pipsValue: '2' },
    { currency: 'NZDCHF', currentPrice: '0.53983', pipsValue: '1.6' },
    { currency: 'NZDCAD', currentPrice: '0.82592', pipsValue: '3.9' },
    { currency: 'CADJPY', currentPrice: '109.552', pipsValue: '2.5' },
    { currency: 'CADCHF', currentPrice: '0.65349', pipsValue: '1.5' },
    { currency: 'XAGUSD', currentPrice: '25.104', pipsValue: '2.5' },
    { currency: 'XAUUSD', currentPrice: '2168.69', pipsValue: '2.4' },
    { currency: 'XBRUSD', currentPrice: '84.79', pipsValue: '0.7' },
    { currency: 'XTIUSD', currentPrice: '80.58', pipsValue: '0.6' },
    { currency: 'AUS200', currentPrice: '7667.9', pipsValue: '3.6' },
    { currency: 'UK100', currentPrice: '7753.9', pipsValue: '5' },
    { currency: 'FRA40', currentPrice: '8150.1', pipsValue: '10.1' },
    { currency: 'GER40', currentPrice: '17958.7', pipsValue: '3.4' },
    { currency: 'EUSTX50', currentPrice: '5001.4', pipsValue: '3.9' },
    { currency: 'US30', currentPrice: '38922.5', pipsValue: '7.1' },
    { currency: 'NAS100', currentPrice: '17980.3', pipsValue: '3.3' },
    { currency: 'SPX500', currentPrice: '5154', pipsValue: '1.5' },
    { currency: 'ADAUSD', currentPrice: '0.722', pipsValue: '1.8' },
    { currency: 'AXSUSD', currentPrice: '11.23', pipsValue: '2.9' },
    { currency: 'BCHUSD', currentPrice: '411', pipsValue: '0.2' },
    { currency: 'BNBUSD', currentPrice: '579.28', pipsValue: '13.1' },
    { currency: 'BTCUSD', currentPrice: '68244', pipsValue: '2.4' },
    { currency: 'DOTUSD', currentPrice: '10.867', pipsValue: '5.3' },
    { currency: 'ETCUSD', currentPrice: '33.257', pipsValue: '6.2' },
    { currency: 'ETHUSD', currentPrice: '3729.1', pipsValue: '1.9' },
    { currency: 'EURMXN', currentPrice: '18.1797', pipsValue: '4.2' },
    { currency: 'EURZAR', currentPrice: '20.38709', pipsValue: '82.5' },
    { currency: 'FILUSD', currentPrice: '9.575', pipsValue: '14' },
    { currency: 'FTMUSD', currentPrice: '0.7877', pipsValue: '7.7' },
    { currency: 'FTTUSD', currentPrice: '2.395', pipsValue: '10' },
    { currency: 'GBPMXN', currentPrice: '21.2902', pipsValue: '8.2' },
    { currency: 'GBPZAR', currentPrice: '23.87689', pipsValue: '90' },
    { currency: 'GRTUSD', currentPrice: '0.4057', pipsValue: '2.3' },
    { currency: 'ICPUSD', currentPrice: '12.885', pipsValue: '16.1' },
    { currency: 'JPN225', currentPrice: '38657', pipsValue: '2.4' },
    { currency: 'LRCUSD', currentPrice: '0.4799', pipsValue: '12.6' },
    { currency: 'LTCUSD', currentPrice: '89.13', pipsValue: '3.4' },
    { currency: 'SOLUSD', currentPrice: '177.94', pipsValue: '3' },
    { currency: 'TRXUSD', currentPrice: '0.12684', pipsValue: '2.7' },
    { currency: 'UNIUSD', currentPrice: '13.1', pipsValue: '5' },
    { currency: 'USDMXN', currentPrice: '16.7121', pipsValue: '3.2' },
    { currency: 'USDZAR', currentPrice: '18.74157', pipsValue: '71.8' },
    { currency: 'XNGUSD', currentPrice: '1.848', pipsValue: '0.9' },
    { currency: 'XRPUSD', currentPrice: '0.63698', pipsValue: '28.1' },
    { currency: 'ZARJPY', currentPrice: '7.9', pipsValue: '2.5' },
    { currency: 'ZECUSD', currentPrice: '32.15', pipsValue: '2.8' },
    { currency: 'AAVEUSD', currentPrice: '130.9', pipsValue: '6.5' },
    { currency: 'ALGOUSD', currentPrice: '0.2934', pipsValue: '6' },
    { currency: 'ATOMUSD', currentPrice: '12.745', pipsValue: '8.6' },
    { currency: 'AVAXUSD', currentPrice: '53.704', pipsValue: '20.3' },
    { currency: 'DASHUSD', currentPrice: '38.53', pipsValue: '2' },
    { currency: 'DOGEUSD', currentPrice: '0.16764', pipsValue: '4' },
    { currency: 'IOTAUSD', currentPrice: '0.3587', pipsValue: '2.6' },
    { currency: 'LINKUSD', currentPrice: '19.502', pipsValue: '7.5' },
    { currency: 'MANAUSD', currentPrice: '0.6846', pipsValue: '16.1' },
    { currency: 'NEARUSD', currentPrice: '8.207', pipsValue: '11.4' },
    { currency: 'MATICUSD', currentPrice: '1.1423', pipsValue: '12.8' },
]

const leverageArray = [
    { label: "1:500", value: "500" },
    { label: "1:200", value: "200" },
    { label: "1:100", value: "100" },
    { label: "1:50", value: "50" },
    { label: "1:30", value: "30" },
    { label: "1:25", value: "25" },
    { label: "1:15", value: "15" },
    { label: "1:5", value: "5" },
    { label: "1:1", value: "1" }
]

const currencyArray = [
    "USD"
]

function Calculator(props) {
    const [formData, setFormData] = React.useState({
        symbol: { currency: 'EURUSD', currentPrice: '1.08776', pipsValue: '0.9' },
        leverage: '500',
    });
    let errorsObj = {
        symbol: '',
        leverage: '',
    };
    const [errors, setErrors] = React.useState(errorsObj);
    const [count, setCount] = React.useState(1.0);
    const [pipValue, setPipValue] = React.useState(0);
    const [requiredMargin, setRequiredMargin] = React.useState(0);

    const handleOptionSelected = async (e, name) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[name] = value;
        setFormData(newFormData);
    }

    const decrementCount = () => {
        if (count > 0.1) setCount(count - 0.1);
    };

    const incrementCount = () => {
        setCount(count + 0.1);
    };

    const calculateResult = () => {
        let requiredMarginTemp = (count * 100000) / formData.leverage * formData.symbol.currentPrice;
        let pipValueTemp = (0.0001 * (count * 100000)) / formData.symbol.currentPrice;

        setRequiredMargin(requiredMarginTemp);
        setPipValue(pipValueTemp);
    }

    return (
        <>
            <div className='calculator'>
                <Container className='w-100 h-100'>
                    <div className='mx-4 mx-lg-0'>
                        <Row className='justify-content-start align-items-center mx-0 w-100 h-100 calculatorBoxDiv'>
                            <Col lg={12} md={12} sm={12} xs={12} className='mb-4'>
                                <span className='font-Black sectionHeaderTitle2 text-white text-uppercase d-block'>{translate(props.lang, "Calculator")}</span>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className='mb-3'>
                                <span className='font-Regular sectionDesc text-light-purple d-block mb-2'>{translate(props.lang, "Symbol")}</span>
                                <div className={`groupLabel-select w-100 mb-1  ${errors.symbol ? 'errorBorder' : ''}`}>
                                    <div className={`filter-input-group text-end`}>
                                        <Select
                                            options={
                                                symbolArray.map((item, i) => ({ value: item, label: item.currency }))
                                            }
                                            placeholder={"EURUSD"}
                                            className="input-transparent sectionDesc w-100 text-capitalize px-0"
                                            styles={customSelectStyles}
                                            name="symbol"
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            onChange={(e) => handleOptionSelected(e, "symbol")}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className='mb-3'>
                                <span className='font-Regular sectionDesc text-light-purple d-block mb-2'>{translate(props.lang, "Leverage")}</span>
                                <div className={`groupLabel-select w-100 mb-1  ${errors.leverage ? 'errorBorder' : ''}`}>
                                    <div className={`filter-input-group text-end`}>
                                        <Select
                                            options={
                                                leverageArray.map((item, i) => ({ value: item.value, label: item.label }))
                                            }
                                            placeholder={"1:500"}
                                            className="input-transparent sectionDesc w-100 text-capitalize px-0"
                                            styles={customSelectStyles}
                                            name="leverage"
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            onChange={(e) => handleOptionSelected(e, "leverage")}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className='mb-3'>
                                <span className='font-Regular sectionDesc text-light-purple d-block mb-2'>{translate(props.lang, "Account Currency")}</span>
                                <div className={`groupLabel-select w-100 mb-1  ${errors.currency ? 'errorBorder' : ''}`}>
                                    <div className={`filter-input-group text-end`}>
                                        <Select
                                            options={
                                                currencyArray.map((item, i) => ({ value: item, label: item }))
                                            }
                                            placeholder={"USD"}
                                            className="input-transparent sectionDesc w-100 text-capitalize px-0"
                                            styles={customSelectStyles}
                                            name="currency"
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            isDisabled
                                            onChange={(e) => handleOptionSelected(e, "currency")}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className='mb-3'>
                                <span className='font-Regular sectionDesc text-light-purple d-block mb-2'>{translate(props.lang, "Volume, Lots")}</span>
                                <div className={`groupLabel-select w-100 mb-1  ${errors.leverage ? 'errorBorder' : ''}`}>
                                    <div className={`filter-input-group text-end text-white position-relative inputNumPos`}>
                                        <Input
                                            type="number"
                                            name="count"
                                            placeholder={"1.0"}
                                            step="0.1"
                                            min="0.0"
                                            className="input-transparent sectionDesc w-100 text-capitalize text-white text-center px-3"
                                            value={(count.toFixed(1))}
                                            autoComplete="off"
                                            disabled
                                        />
                                        <div id="inc-button" className="spinner-button cursor-pointer" onClick={incrementCount}><FaPlus className='smallText text-white' /></div>
                                        <div id="dec-button" className="spinner-button cursor-pointer" onClick={decrementCount}><FaMinus className='smallText text-white' /></div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className='d-flex justify-content-start align-items-center vtgm-btn' onClick={() => calculateResult()}>
                                    <div className='btn'>
                                        <FaArrowRight className='sectionDivTitle text-white' />
                                    </div>
                                    <span className='font-Regular sectionDesc text-white'>{translate(props.lang, "Calculate")}</span>
                                </div>
                                <hr className='borderLine' />
                            </Col>
                            <Col lg={7} md={12} sm={12} xs={12}>
                                <Row className='justify-content-center align-items-center mx-0'>
                                    <Col lg={12} md={12} sm={12} xs={12} className='mb-4'>
                                        <span className='font-Bold sectionDivTitle text-white'>{translate(props.lang, "Calculation Results")}</span>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12} className='mb-3 mb-md-0'>
                                        <span className='font-Bold sectionHeaderTitle2 text-white d-block'>{pipValue && pipValue ? pipValue.toFixed(2) : 0}</span>
                                        <span className='font-Regular sectionDesc text-light-purple d-block mb-2'>{translate(props.lang, "Pip Value")}</span>
                                    </Col>

                                    <Col lg={4} md={4} sm={12} xs={12} className='mb-3 mb-md-0'>
                                        <span className='font-Bold sectionHeaderTitle2 text-white d-block'>{requiredMargin && requiredMargin ? requiredMargin.toFixed(2) : 0}</span>
                                        <span className='font-Regular sectionDesc text-light-purple d-block mb-2'>{translate(props.lang, "Required Margin")}</span>
                                    </Col>

                                    <Col lg={4} md={4} sm={12} xs={12} className='mb-3 mb-md-0'>
                                        <span className='font-Bold sectionHeaderTitle2 text-white d-block'>0</span>
                                        <span className='font-Regular sectionDesc text-light-purple d-block mb-2'>{translate(props.lang, "Trading Fees")}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(Calculator);