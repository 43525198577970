const EN = {
    //home
    "Join": "Join",
    "Glow": "Grow",
    "Multiply": "Multiply",
    "Become a PAMM account investor, earn without having to trade yourself by entrusting your funds to an experienced trader.": "Become a PAMM account investor, earn without having to trade yourself by entrusting your funds to an experienced trader.",
    "Empowering Your Trading Experience": "Empowering Your Trading Experience",
    "Unlock unparalleled opportunities with our refined USP": "Unlock unparalleled opportunities with our refined USP",
    '$100 Welcome Bonus': '$100 Welcome Bonus',
    'Low Spread & Slippage': 'Low Spread & Slippage',
    'Low Minimum Deposit of $100': 'Low Minimum Deposit of $100',
    'Over 100 Trading Products': 'Over 100 Trading Products',
    'Why Choose Us': 'Why Choose Us',
    'New Generation & Innovative Trading Platform': 'New Generation & Innovative Trading Platform',
    'Lightning-Fast Trading Experience': 'Lightning-Fast Trading Experience',
    'Regulated Broker Connected With Multi Liquidity Providers': 'Regulated Broker Connected With Multi Liquidity Providers',
    'Quick Withdrawal & Deposit Promises': 'Quick Withdrawal & Deposit Promises',
    'Free Daily Technical Analysis': 'Free Daily Technical Analysis',
    'Dedicated Account Managers': 'Dedicated Account Managers',
    "Be A Part Of Our Journey": "Be A Part Of Our Journey",
    "Connect with a trustworthy partner who will assist you in every way": "Connect with a trustworthy partner who will assist you in every way",
    "Expansive Range of Instruments": "Expansive Range of Instruments",
    "Competitive Bid/Ask pip spreads": "Competitive Bid/Ask pip spreads",
    "Leverage up to 1:400": "Leverage up to 1:500",
    "Rebates per lot": "Rebates per lot",
    "Redemption points for every traded lot": "Redemption points for every traded lot",
    "Open Live Account": "Open Live Account",
    "Take a look at": "Take a look at",
    "PAMM": "PAMM",
    "Subscribe PAMM to obtain a competitive advantage from our team of professionals, who are all traders with a track record of success.": "Subscribe PAMM to obtain a competitive advantage from our team of professionals, who are all traders with a track record of success.",
    "With our exclusive PAMM service, you may now profitably trade Forex and CFDs even if you have no prior trading experience. Run out of time to trade? Our PAMM will take care of everything. Invest and relax.": "With our exclusive PAMM service, you may now profitably trade Forex and CFDs even if you have no prior trading experience. Run out of time to trade? Our PAMM will take care of everything. Invest and relax.",
    "Subscribe": "Subscribe",
    "Start Trading With Us": "Start Trading With Us",
    "Open an account in just 3 STEPS": "Open an account in just 3 STEPS",
    'Register': 'Register',
    'Fill in your details and submit documents for verification': 'Fill in your details and submit documents for verification',
    'Fund': 'Fund',
    'Deposit funds by online banking or cryptocurrency method': 'Deposit funds by online banking or cryptocurrency method',
    'Trade': 'Trade',
    'Start trading by choosing your desired account type': 'Start trading by choosing your desired account type',
    "Start your trading journey with us and unleash your potential!": "Start your trading journey with us and unleash your potential!",
    "Trade With Us": "Trade With Us",

    //Company
    "About Us": "About Us",
    "VT Global markets is a leading global financial institution which offers clients the opportunity to trade the financial markets via contract for differences (CFDs)": "VT Global Markets is a leading global financial institution which offers clients the opportunity to trade the financial markets via contract for differences (CFDs)",
    "Our goal is to continually explore and discover innovative trading technologies in order to provide our clients with an unparalleled value and trading experience. We aim to provide exceptional services in currency trading, indices, commodities, equities and ETFs, to an extent that our clients remain satisfied with their trading experience.": "Our goal is to continually explore and discover innovative trading technologies in order to provide our clients with an unparalleled value and trading experience. We aim to provide exceptional services in currency trading, indices, commodities, equities and ETFs, to an extent that our clients remain satisfied with their trading experience.",
    "Mission & Vision of VT Global markets": "Mission & Vision of VT Global Markets",
    "With years of expertise and experience in foreign exchange and financial products, our management team truly understands the demands of every trader, resulting in VT being a platform built for users by constantly improving on product optimization and service quality.": "With years of expertise and experience in foreign exchange and financial products, our management team truly understands the demands of every trader, resulting in VT being a platform built for users by constantly improving on product optimization and service quality.",
    "Mission": "Mission",
    "Our mission is to become the world class brokerage to provide the best trading environment for our users. We always value our users as our primary assets because VT is designed for users to have access to all the tools you need to succeed and to maximize your wealth and potential.": "Our mission is to become the world class brokerage to provide the best trading environment for our users. We always value our users as our primary assets because VT is designed for users to have access to all the tools you need to succeed and to maximize your wealth and potential.",
    "Vision": "Vision",
    "VT Global markets provides our valued customers with a leading-edge trading platform (MT5), offering them a great trading experience and environment. MT5 is the latest advancement in MetaQuotes technology that not only provides upgraded functionality and charting tools, but also allows brokers to connect with a few liquidity providers in order to provide the best available price feed to our investors.": "VT Global Markets provides our valued customers with a leading-edge trading platform (ProTrader), offering them a great trading experience and environment, not only provides upgraded functionality and charting tools, but also allows brokers to connect with a few liquidity providers in order to provide the best available price feed to our investors.",
    "Our Advantages": "Our Advantages",
    "The right place for online trading on financial markets.": "The right place for online trading on financial markets.",
    'Expansive Range of Instruments': 'Expansive Range of Instruments',
    "A Selection of Trading Instruments": "A Selection of Trading Instruments",
    "Competitive Bid/Ask pip spreads": "Competitive Bid/Ask pip spreads",
    "Leverage Up to 1:400": "Leverage Up to 1:500",
    'Market-Leading Trading Platform': 'Market-Leading Trading Platform',
    "Fast execution": "Fast execution",
    "Increased functionality and efficiency for traders": "Increased functionality and efficiency for traders",
    'Exclusive Rewards for Traders': 'Exclusive Rewards for Traders',
    "Rebates per lot for traders": "Rebates per lot for traders",
    "Promotional campaign from time to time": "Promotional campaign from time to time",
    "Regulation and License": "Regulation and License",
    "Regulated and trusted, allowing you to trade with complete piece of mind": "Regulated and trusted, allowing you to trade with complete piece of mind",
    "VT Global Markets is authorized and regulated by multiple regulators across the globe": "VT Global Markets is authorized and regulated by multiple regulators across the globe",
    "VT Global Markets is authorised and regulated by Vanuatu Financial Services Commission (VFSC) (Financial Services License ：VFSC 700 557 ).": "VT Global Markets is authorised and regulated by Vanuatu Financial Services Commission (VFSC) (Financial Services License ：VFSC700557 ).",
    "Client money held in segregated accounts with top-tier bank": "Client money held in segregated accounts with top-tier bank",
    "Client money is held in segregated accounts. Valk Trade banks with Westpac, which is one of four major banking organisations in Australia and has over 200 years of continuous service.": "Client money is held in segregated accounts. VT Global Markets banks with Westpac, which is one of four major banking organisations in Australia and has over 200 years of continuous service.",
    "Professional indemnity insurance to protect client's financial security": "Professional indemnity insurance to protect client's financial security.",
    "VALK TRADE operates with adequate Professional Indemnity Insurance (PI insurance) in place. Our PI insurance is underwritten by Falcon Insurance, which received its insurance license in 2013. PI insurance is a safe net that covers the costs and expenses of responding to claims arising from professional services.": "VT Global Markets operates with adequate Professional Indemnity Insurance (PI insurance) in place. Our PI insurance is underwritten by Falcon Insurance, which received its insurance license in 2013. PI insurance is a safe net that covers the costs and expenses of responding to claims arising from professional services.",
    "Liquidity Partner": "Liquidity Partner",
    "Trusted liquidity partner to ensure your trading comfort and speed": 'Trusted liquidity partner to ensure your trading comfort and speed',
    "VT is pleased to announce the alliance with": "VT is pleased to announce the alliance with",
    "GBE Prime": "GBE Prime",
    "in terms of strategic STP liquidity partner.": "in terms of strategic STP liquidity partner.",
    "GBE Prime is a fully regulated broker and liquidity provider offering aggregated, institutional liquidity to brokers, banks, funds and asset managers operating in a regulated environment.": "GBE Prime is a fully regulated broker and liquidity provider offering aggregated, institutional liquidity to brokers, banks, funds and asset managers operating in a regulated environment.",
    "GBE Prime is a brand of GBE brokers and focuses exclusively on institutional business activities.": "GBE Prime is a brand of GBE brokers and focuses exclusively on institutional business activities.",
    "GBE Prime is offering advanced, aggregated liquidity in the Equinix Datacenters in LD4 (London), NY4 (New York) and TY3 (Tokyo), using state of the art bridging and aggregation software, ultra-low-latency connectivity and institutional grade hosting solutions.": "GBE Prime is offering advanced, aggregated liquidity in the Equinix Datacenters in LD4 (London), NY4 (New York) and TY3 (Tokyo), using state of the art bridging and aggregation software, ultra-low-latency connectivity and institutional grade hosting solutions.",
    "The Edge of GBE Prime": "The Edge of GBE Prime",
    "One of the TOP 50 players in the worldwide eFX market": "One of the TOP 50 players in the worldwide Forex market",
    "Excellent trading conditions": "Excellent trading conditions",
    "Fully regulated investment firm": "Fully regulated investment firm",
    "German management with German understanding of operating in business": "German management with German understanding of operating in business",
    "Ultra-flexible account setups and individual conditions": "Ultra-flexible account setups and individual conditions",
    "World-wide liquidity hubs with server locations in Equinix LD4 (London), NY4 (New York) and TY3 (Tokyo) datacenters.": "World-wide liquidity hubs with server locations in Equinix LD4 (London), NY4 (New York) and TY3 (Tokyo) datacenters.",
    "Dispute Resolution": "Dispute Resolution",
    "Your satisfaction is what we care about.": "Your satisfaction is what we care about.",
    "VT Global markets seeks to develop a stable and long-term relationship with its clients.": "VT Global Markets seeks to develop a stable and long-term relationship with its clients.",
    "We are constantly striving to provide better service and accomplish values in order to exceed clients’ expectations with additional service assistance. In line with this, we regard our clients’ suggestions, opinions and concerns to be of the utmost significance. We also acknowledge that a client’s dissatisfaction is an opportunity for us to improve by enhancing our products and quality of services.": "We are constantly striving to provide better service and accomplish values in order to exceed clients’ expectations with additional service assistance. In line with this, we regard our clients’ suggestions, opinions and concerns to be of the utmost significance. We also acknowledge that a client’s dissatisfaction is an opportunity for us to improve by enhancing our products and quality of services.",
    "If I have a complaint, what should I do?": "If I have a complaint, what should I do?",
    "In the unlikely event that you are dissatisfied with the services provided by VT Global markets, please contact your Account Manager as soon as possible or email us with the process outlined below. This will help us to resolve your complaint in the most efficient way possible in order to regain your confidence.": "In the unlikely event that you are dissatisfied with the services provided by VT Global Markets, please contact your Account Manager as soon as possible or email us with the process outlined below. This will help us to resolve your complaint in the most efficient way possible in order to regain your confidence.",
    'Gather all the relevant supporting documents relating to your complaint': 'Gather all the relevant supporting documents relating to your complaint',
    'Consider the issues you would like to be addressed and what you would like us to do': 'Consider the issues you would like to be addressed and what you would like us to do',
    'Contact your Account Manager directly': 'Contact your Account Manager directly',
    'Should your issue persist, please contact us as early at your earliest convenience to allow us to resolve your complaint immediately. If your account manager is unable to resolve your complaint satisfactorily, please request that your complaint be forwarded to the management. You can also contact us directly by sending an email.': 'Should your issue persist, please contact us as early at your earliest convenience to allow us to resolve your complaint immediately. If your account manager is unable to resolve your complaint satisfactorily, please request that your complaint be forwarded to the management. You can also contact us directly by sending an email.',
    'ALK TRADE GLOBAL LIMITED trading as VTGM, is authorized and regulated by the VANUATU FINANCIAL SERVICES COMMISSION, No.700557': 'VALK TRADE GLOBAL LIMITED trading as VTGM, is authorized and regulated by the VANUATU FINANCIAL SERVICES COMMISSION, No.700557',
    
    //Research
    "Analytical tools": "Analytical tools",
    "We make sure you have access to the top fundamental and technical analysis tools so you can plan your trades with confidence.": "We make sure you have access to the top fundamental and technical analysis tools so you can plan your trades with confidence.",
    "Calculator": "Calculator",
    "Symbol": "Symbol",
    "Leverage": "Leverage",
    "Account Currency": "Account Currency",
    "Volume, Lots": "Volume, Lots",
    "Calculate": "Calculate",
    "Calculation Results": "Calculation Results",
    "Pip Value": "Pip Value",
    "Required Margin": "Required Margin",
    "Trading Fees": "Trading Fees",
    "Economic Calendar": "Economic Calendar",
    "Keep track of high impact news, market-moving economic events and data releases with our Economic Calendar.": "Keep track of high impact news, market-moving economic events and data releases with our Economic Calendar.",
    "We know it is important to be updated about economic and market events when trading, so the Real-time Economic Calendar is available to all VTGM clients.": "We know it is important to be updated about economic and market events when trading, so the Real-time Economic Calendar is available to all VTGM clients.",
    "Our calendar keeps VTGM clients informed and allow them to monitor market activities in 38 different countries, by day, week, month or selecting a time range.": "Our calendar keeps VTGM clients informed and allow them to monitor market activities in 38 different countries, by day, week, month or selecting a time range.",
    "The calendar is available below and inside VTGM Client's Portal.": "The calendar is available below and inside VTGM Client's Portal.",
    "Market Sentiment Tool": "Market Sentiment Tool",
    "Understanding the critical role of timely information, VTGM offers our clients access to the most sophisticated tools available. Market Buzz stands out with its intuitive design and user-friendly interface. It presents a clear and precise snapshot of market sentiment for each instrument by aggregating daily insights from professional news articles, social media and individual blogs.": "Understanding the critical role of timely information, VTGM offers our clients access to the most sophisticated tools available. Market Buzz stands out with its intuitive design and user-friendly interface. It presents a clear and precise snapshot of market sentiment for each instrument by aggregating daily insights from professional news articles, social media and individual blogs.",
    "Unlock the full potential of Market Buzz by logging in or signing up today.": "Unlock the full potential of Market Buzz by logging in or signing up today.",
    "Sign Up": "Sign Up",

    //Partnership
    "Partnership Opportunities": "Partnership Opportunities",
    "Collaborate": "Collaborate",
    "Develop": "Develop",
    "Consistent": "Consistent",
    "Why Affiliate With Us": "Why Affiliate With Us",
    "VT provides a series of excellent reward programs for introducing brokers (IBs) who put effort into introducing business to us. Profit on reward has no restrictions for IBs, and it is open to anybody who wants to participate. This unique IBs program offered by us is strongly supported by our dedicated and experienced account managers.": "VT provides a series of excellent reward programs for introducing brokers (IBs) who put effort into introducing business to us. Profit on reward has no restrictions for IBs, and it is open to anybody who wants to participate. This unique IBs program offered by us is strongly supported by our dedicated and experienced account managers.",
    'Instant Services Support from Us': 'Instant Services Support from Us',
    'We are constantly striving to provide better service and accomplish values in order to exceed our clients’ expectations with additional service assistance. Our service assistance comprises a robust customer service support team available 24 hours a day, 5.5 days a week, as well as customised account management for IBs, Market Outlook, Analysis Tools, Online Guides and Education.': 'We are constantly striving to provide better service and accomplish values in order to exceed our clients’ expectations with additional service assistance. Our service assistance comprises a robust customer service support team available 24 hours a day, 5.5 days a week, as well as customised account management for IBs, Market Outlook, Analysis Tools, Online Guides and Education.',
    'Seminars And Webinars by Professionals': 'Seminars And Webinars by Professionals',
    'Growing a healthy business is always the goal of our company, and we provide our best assistance from all possible perspectives. We provides executive seminars and webinars to our valued investors on a regular basis. Furthermore, for events and webinars, We combines the strong outsourced support of subsidised personnel and experienced FX industry speakers.': 'Growing a healthy business is always the goal of our company, and we provide our best assistance from all possible perspectives. We provides executive seminars and webinars to our valued investors on a regular basis.',
    'Exclusive Rewards & Privileges': 'Exclusive Rewards & Privileges',
    'All our clients and IBs are valuable assets in our long-term partnership. Hence, we provides a variety of rewards for introducing brokers which include commission rebates from trading, achievement rewards and other sorts of gifts for certain important occasions.': 'All our clients and IBs are valuable assets in our long-term partnership. Hence, we provides a variety of rewards for introducing brokers which include commission rebates from trading, achievement rewards and other sorts of gifts for certain important occasions.',
    'To Be Part Of Our Family Member': 'To Be Part Of Our Family Member',
    'Join our affiliate program to earn unlimited potential earnings': 'Join our affiliate program to earn unlimited potential earnings',
    'Personalized account managers and instant service support': 'Personalized account managers and instant service support',
    'Attractive incentives & team support for IBs looking to expand their market and increase client conversion': 'Attractive incentives & team support for IBs looking to expand their market and increase client conversion',
    "Surprising promotional plans and innovative marketing materials to facilitate affiliates' marketing efforts": "Surprising promotional plans and innovative marketing materials to facilitate affiliates' marketing efforts",
    "It is simple to become an Introducing Broker (IBs)": "It is simple to become an Introducing Broker (IBs)",
    "It takes less than 5 minutes to GET STARTED! You may sign up online here or follow the steps below to sign up online.": "It takes less than 5 minutes to GET STARTED! You may sign up online here or follow the steps below to sign up online.",
    'Sign Up Here': 'Sign Up Here',
    'Fill up all the relevant information': 'Fill up all the relevant information',
    'Send your registration form to (Our email address)': 'Send your registration form to (Our email address)',
    'Receive your agent code and start earning your commission': 'Receive your agent code and start earning your commission',
    "If you are interested in becoming one of our  Introducing Broker, please contact us!": "If you are interested in becoming one of our  Introducing Broker, please contact us!",
    "Contact Us": "Contact Us",

    //referral program
    "Introducing Broker Program": "Introducing Broker Program",
    "Get up to 40% of our revenue. You will receive a portion of VTGM’s revenue from the trading activity of every active client you refer to us. This can go as high as 40% on every trade they make.": "Get up to 40% of our revenue. You will receive a portion of VTGM’s revenue from the trading activity of every active client you refer to us. This can go as high as 40% on every trade they make.",
    "The more clients you sign up, the greater your income": "The more clients you sign up, the greater your income",
    "Drag the bar to see the average monthly income you can make based on the total amount of traders you introduce to VTGM.": "Drag the bar to see the average monthly income you can make based on the total amount of traders you introduce to VTGM.",
    "Total Accumulated Clients": "Total Accumulated Clients",
    "Your income per month": "Your income per month",
    "Why become an Introducing Broker?": "Why become an Introducing Broker?",
    'Daily payouts & instant withdrawals': 'Daily payouts & instant withdrawals',
    'We pay your profit daily into your account and you can access your money instantly, whenever you want.': 'We pay your profit daily into your account and you can access your money instantly, whenever you want.',
    'Promotional tools & materials': 'Promotional tools & materials',
    'We provide all the tools you need to promote our products, including website banners, advertising, landing pages.': 'We provide all the tools you need to promote our products, including website banners, advertising, landing pages.',
    'Detailed, real-time statistics': 'Detailed, real-time statistics',
    "We provide reporting tools that show detailed, real-time statistics on every client's transactions in your Personal Area.": "We provide reporting tools that show detailed, real-time statistics on every client's transactions in your Personal Area.",
    'Trusted global market leader': 'Trusted global market leader',
    'Join a team of professionals who operate in 150+ countries and offer a full range of financial instruments with competitive trading conditions.': 'Join a team of professionals who operate in 150+ countries and offer a full range of financial instruments with competitive trading conditions.',
    "Not getting enough?": "Not getting enough?",
    "Get More When You Become An Introducing Broker With VTGM": "Get More When You Become An Introducing Broker With VTGM",
    'Add on additional spread': 'Add on additional spread',
    'Aside from the rebate, IB can choose to add on additional spreads to obtain higher income.': 'Aside from the rebate, IB can choose to add on additional spreads to obtain higher income.',
    'Automated Rebate system': 'Automated Rebate system',
    "Strong IB Client management system, enabling rebate tracking in real-time, your rebate will arrive in with no commission cap.": "Strong IB Client management system, enabling rebate tracking in real-time, your rebate will arrive in with no commission cap.",
    '1 on 1 Dedicated account manager': '1 on 1 Dedicated account manager',
    'Dedicated service, efficiently communicating all queries with your account manager.': 'Dedicated service, efficiently communicating all queries with your account manager.',
    'Multi-level Rebate': 'Multi-level Rebate',
    'Customise your Rebate, set up multi-level rebate to obtain more clients.': 'Customise your Rebate, set up multi-level rebate to obtain more clients.',
    'Exclusive online promotions': 'Exclusive online promotions',
    'Prospero Provides a large variety of bonus and gifts, which both retail and IB can participate to stimulate their trading passion.': 'VTGM Provides a large variety of bonus and gifts, which both retail and IB can participate to stimulate their trading passion.',
    "Affiliate Program": "Affiliate Program",
    "We are leading the industry with scheduled and attractive payouts. Drive traffic to VTGM and we'll give you commission up to $1850 for each user that becomes an active client.": "We are leading the industry with scheduled and attractive payouts. Drive traffic to VTGM and we'll give you commission up to $1850 for each user that becomes an active client.",
    "What We Offer": "What We Offer",
    "We pay for first-time deposits (CPA)": "We pay for first-time deposits (CPA)",
    "Up to $1850 based on the country, platform, and the size of deposit your referral makes": "Up to $1850 based on the country, platform, and the size of deposit your referral makes",
    "Minimum first-time deposit is $10": "Minimum first-time deposit is $10",
    "We accept traffic from more than 130 countries": "We accept traffic from more than 130 countries",
    "Daily payouts": "Daily payouts",
    "Extra bonuses for the best performance": "Extra bonuses for the best performance",
    "Fund Manager": "Fund Manager",
    "Our industry leading MAM (Multi Account Manager) and PAMM (Percentage Allocation Management Module) program is designed for qualified and experienced fund managers and each account has dedicated senior account managers to assist you.": "Our industry leading MAM (Multi Account Manager) and PAMM (Percentage Allocation Management Module) program is designed for qualified and experienced fund managers and each account has dedicated senior account managers to assist you.",
    'Trade Well & Get Rewarded': 'Trade Well & Get Rewarded',
    'Whether you run an investment firm or are a professional trader, you can utilize VTGM’s Multi Account Manager to manage funds on behalf of your clients. Our MT5 Multi Account Manager allows you to trade on your favourite MT5 while simultaneously trading on every one of your client’s accounts.': 'Whether you run an investment firm or are a professional trader, you can utilize VTGM’s Multi Account Manager to manage funds on behalf of your clients. Our ProTrader Multi Account Manager allows you to trade on your favourite ProTrader while simultaneously trading on every one of your client’s accounts.',
    'Streamlined, paperless client onboarding': 'Streamlined, paperless client onboarding',
    'Give your clients the absolute best experience with our streamlined, paperless onboarding process. Whether you’re bringing on 10 or 1,000 clients, our powerful onboarding system will ensure you can focus on trading while we handle the onboarding.': 'Give your clients the absolute best experience with our streamlined, paperless onboarding process. Whether you’re bringing on 10 or 1,000 clients, our powerful onboarding system will ensure you can focus on trading while we handle the onboarding.',
    'Customisable Fee Structures': 'Customisable Fee Structures',
    'We understand that every relationship is unique and every client has different expectations. Easily customise the fee structures to suit different clients and even create your own tiers for various groups of clients.': 'We understand that every relationship is unique and every client has different expectations. Easily customise the fee structures to suit different clients and even create your own tiers for various groups of clients.',
    "Franchise Model": 'Franchise Model',
    "Our franchise program enables ambitious individuals and organizations to partner with VTGM with an aim to bring clients and business to the table.": "Our franchise program enables ambitious individuals and organizations to partner with VTGM with an aim to bring clients and business to the table.",
    "The franchise would be entitled to set up their own office as per VTGM’s branding requirements and would be expected to provide personalised support to the clients that it has brought through the doors at VTGM.": "The franchise would be entitled to set up their own office as per VTGM’s branding requirements and would be expected to provide personalised support to the clients that it has brought through the doors at VTGM.",
    "This would require the franchisee to have some business experience as well as capital. Franchisees can market VTGM’s entire range of products.": "This would require the franchisee to have some business experience as well as capital. Franchisees can market VTGM’s entire range of products.",
    "This partnership will be centred around the introduction of clients and development of business by the franchisee for VTGM which will be rewarded with healthy commissions and benefits based on business volume.": "This partnership will be centred around the introduction of clients and development of business by the franchisee for VTGM which will be rewarded with healthy commissions and benefits based on business volume.",

    //account-overview
    "Accounts Overview": "Accounts Overview",
    "Which account is the best fit for me?": "Which account is the best fit for me?",
    "Your trading journey begins with a trading account. We offer different account types to suit your trading needs and experience. Refer to the table below to find out which account is best for you.": "Your trading journey begins with a trading account. We offer different account types to suit your trading needs and experience. Refer to the table below to find out which account is best for you.",
    "STANDARD ACCOUNTS": "STANDARD ACCOUNTS",
    "We designed a few categories of standard accounts comprising Silver, Gold and Diamond for investors. These standard accounts allow investors to trade smaller trade sizes in Forex and Precious Metals while enjoying cashback, bonuses and special trade.": "We designed a few categories of standard accounts comprising Silver, Gold and Diamond for investors. These standard accounts allow investors to trade smaller trade sizes in Forex and Precious Metals while enjoying cashback, bonuses and special trade.",
    "Cashback": "Cashback",
    "Bonus": "Bonus",
    "Trade": "Trade",
    "Market execution with tight spreads.": "Market execution with tight spreads.",
    "SILVER": "SILVER",
    '1 USD/lot': "1 USD/lot",
    'Nil': 'Nil',
    'GOLD': 'GOLD',
    '2 USD/lot': '2 USD/lot',
    'DIAMOND': 'DIAMOND',
    '3 USD/lot': '3 USD/lot',
    "Deposit and Withdrawal": "Deposit and Withdrawal",
    "Experience the convenience and security of VTGM deposit and withdrawal processes. We offer local, convenient, and safe payment methods, ensuring seamless transactions from start to finish.": "Experience the convenience and security of VTGM deposit and withdrawal processes. We offer local, convenient, and safe payment methods, ensuring seamless transactions from start to finish.",

    'Frictionless Transactions Every Step of the Way': 'Frictionless Transactions Every Step of the Way',
    'Enjoy our unmatched payments ecosystem: seamless deposits through global and local payment systems, with 24/7 access and hassle-free fund release.': 'Enjoy our unmatched payments ecosystem: seamless deposits through global and local payment systems, with 24/7 access and hassle-free fund release.',
    'Diverse Payment Methods for Your Convenience': 'Diverse Payment Methods for Your Convenience',
    'Choose from a variety of secure, global, and local payment options for straightforward deposits and withdrawals.': 'Choose from a variety of secure, global, and local payment options for straightforward deposits and withdrawals.',
    'Unrestricted Access to Your Funds': 'Unrestricted Access to Your Funds',
    "Withdraw your funds anytime, instantly with our instant withdrawal feature. Say goodbye to withdrawal fees, we cover third-party transaction costs for you.": "Withdraw your funds anytime, instantly with our instant withdrawal feature. Say goodbye to withdrawal fees, we cover third-party transaction costs for you.",
    'Your Funds Are Secure With Us': 'Your Funds Are Secure With Us',
    "As the world's leading retail multi-asset broker, we implement multiple layers of security to ensure your funds are safe and readily accessible upon request.": "As the world's leading retail multi-asset broker, we implement multiple layers of security to ensure your funds are safe and readily accessible upon request.",
    'Segregated Accounts for Enhanced Security': 'Segregated Accounts for Enhanced Security',
    'Your funds are held in segregated accounts across multiple tier-1 banks, offering you the highest level of security and peace of mind.': 'Your funds are held in segregated accounts across multiple tier-1 banks, offering you the highest level of security and peace of mind.',
    'Secure Withdrawal Transactions': 'Secure Withdrawal Transactions',
    'Rest assured, your withdrawals are protected with one-time password verification, ensuring safety and security.': 'Rest assured, your withdrawals are protected with one-time password verification, ensuring safety and security.',
    "Payment Options": "Payment Options",
    "EFT | Electronic Fund Transfer (Recommended)": "EFT | Electronic Fund Transfer (Recommended)",
    "Execution Time": "Execution Time",
    "Up to two business days.": "Up to two business days.",
    "Cost": "Cost",
    "No charge .": "No charge .",
    "USDT Payment": "USDT Payment",
    "Acceptance": "Acceptance",
    "We accept USDT payments through BTC, ERC20, or TRC channels.": "We accept USDT payments through BTC, ERC20, or TRC channels.",
    "Execution Time": "Execution Time",
    'Deposits are processed within 5-10 minutes, while withdrawals take 1-2 working days.': 'Deposits are processed within 5-10 minutes, while withdrawals take 1-2 working days.',
    "Fees": "Fees",
    'No transaction fee is charged.': 'No transaction fee is charged.',

    //platform
    "Building on the esteemed legacy of its predecessor, MetaTrader 5 (MT5) enhances your trading experience by offering additional trading timeframes, improved order flexibility, and accelerated strategy testing speeds.": "Building on the esteemed legacy of its predecessor, ProTrader enhances your trading experience by offering additional trading timeframes, improved order flexibility, and accelerated strategy testing speeds.",
    "A platform for every device": 'A platform for every device',
    "Echoing the versatility of its predecessor, MT5 facilitates trading both at your desk and on the move, ensuring seamless market access regardless of your location.": 'Echoing the versatility of its predecessor, ProTrader facilitates trading both at your desk and on the move, ensuring seamless market access regardless of your location.',
    'Depth of Market for Enhanced Visibility': 'Depth of Market for Enhanced Visibility',
    'The MT5 Depth of Market (DoM) tool enhances market transparency, allowing traders to view the volume of trades available at different prices, thereby gaining deeper insights into market liquidity.': 'The ProTrader Depth of Market (DoM) tool enhances market transparency, allowing traders to view the volume of trades available at different prices, thereby gaining deeper insights into market liquidity.',
    'Advanced Strategy Tester for Optimal EA Performance': 'Advanced Strategy Tester for Optimal EA Performance',
    "Optimize your Expert Advisors (EAs) with MT5's multi-threaded strategy tester, designed for more efficient backtesting and refinement of your trading strategies.": "Optimize your Expert Advisors (EAs) with ProTrader's multi-threaded strategy tester, designed for more efficient backtesting and refinement of your trading strategies.",
    'Expanded Timeframes for Comprehensive Market Analysis': 'Expanded Timeframes for Comprehensive Market Analysis',
    "With 21 different timeframes, MT5 broadens your market analysis capabilities, offering varied perspectives to inform your trading decisions.": "With 21 different timeframes, ProTrader broadens your market analysis capabilities, offering varied perspectives to inform your trading decisions.",
    'Enhanced Order Flexibility for Precise Trade Execution': 'Enhanced Order Flexibility for Precise Trade Execution',
    "MT5 introduces two additional pending order types, granting you greater control over your trade entries and management.": "ProTrader introduces two additional pending order types, granting you greater control over your trade entries and management.",
    "WEBTRADER": "WEBTRADER",
    "Trade up with our high-speed customized web app": "Trade up with our high-speed customized web app",
    "Enjoy seamless and flexible trading on the Webtrader, a secure and streamlined web trader platform for trading over a hundred popular CFDs.": "Enjoy seamless and flexible trading on the Webtrader, a secure and streamlined web trader platform for trading over a hundred popular CFDs.",
    'Watchlist': 'Watchlist',
    'Access real-time prices and market movements with the Webtrader watchlist, featuring a comprehensive list of all available trading instruments.': 'Access real-time prices and market movements with the Webtrader watchlist, featuring a comprehensive list of all available trading instruments.',
    'One-Click Trading Mode': 'One-Click Trading Mode',
    'Instantly open and close orders by selecting from a list of instruments and utilizing the Limit Order field for setting up pending orders, all with just one click.': 'Instantly open and close orders by selecting from a list of instruments and utilizing the Limit Order field for setting up pending orders, all with just one click.',
    'Portfolio Area': 'Portfolio Area',
    "Monitor your orders in the portfolio section, which provides detailed information such as order type, volume, prices, take profit, stop loss, open time, swap, and profit and loss.": "Monitor your orders in the portfolio section, which provides detailed information such as order type, volume, prices, take profit, stop loss, open time, swap, and profit and loss.",
    'Instruments Tabs': 'Instruments Tabs',
    "Select from a variety of chart types and enhance them with indicators, drawing tools, and more. Charting capabilities are powered by TradingView, ensuring top-notch visualization and analysis tools.": "Select from a variety of chart types and enhance them with indicators, drawing tools, and more. Charting capabilities are powered by TradingView, ensuring top-notch visualization and analysis tools.",
    'Trading Account Management': 'Trading Account Management',
    'Manage your account effortlessly, viewing details like trading history, balance, equity, margin, free margin, margin level, and leverage settings in real-time.': 'Manage your account effortlessly, viewing details like trading history, balance, equity, margin, free margin, margin level, and leverage settings in real-time.',
    'Account Types': 'Account Types',
    'The Webtrader is designed to accommodate the flexibility required by traders, compatible with all trading account types supported by MetaTrader 5, ensuring a comprehensive trading experience across different strategies and preferences.': 'The Webtrader is designed to accommodate the flexibility required by traders, ensuring a comprehensive trading experience across different strategies and preferences.',
    "Provide an opportunity for clients to earn profits without the need to acquire technical skills.": "Provide an opportunity for clients to earn profits without the need to acquire technical skills.",
    "Make your INVESTMENT Easier and Smarter": "Make your INVESTMENT Easier and Smarter",
    "Undoubtedly, the financial market has a huge potential for profit. However, it is understandable that it requires time and effort to polish your skills and acquire relevant experiences, as well as time to be spent on keeping track of the market. Do you know? It’s not a problem! If you don’t feel like doing it on your own, we have the perfect solution for you!": "Undoubtedly, the financial market has a huge potential for profit. However, it is understandable that it requires time and effort to polish your skills and acquire relevant experiences, as well as time to be spent on keeping track of the market. Do you know? It’s not a problem! If you don’t feel like doing it on your own, we have the perfect solution for you!",
    "Our": "Our",
    "PAMM System": "PAMM System",
    "We have launched a PAMM system that allows you to access different types of trading opportunities without the need to acquire technical skills, and it can be done in a short period of time. As soon as you have chosen a MASTER suited to your needs and invest in the pooled funds, you can enjoy your investment journey as your funds are managed by your chosen master, while you have complete control over your money. Most importantly, you’ll only have to pay a fraction of the profits generated by the master.": "We have launched a PAMM system that allows you to access different types of trading opportunities without the need to acquire technical skills, and it can be done in a short period of time. As soon as you have chosen a MASTER suited to your needs and invest in the pooled funds, you can enjoy your investment journey as your funds are managed by your chosen master, while you have complete control over your money. Most importantly, you’ll only have to pay a fraction of the profits generated by the master.",
    "Our PAMM system is a bridge that connects both the Investor and the Master at the trading platform, allowing you to invest quickly and easily in only five steps.": "Our PAMM system is a bridge that connects both the Investor and the Master at the trading platform, allowing you to invest quickly and easily in only five steps.",
    "Open Account": "Open Account",
    "Make a Deposit": "Make a Deposit",
    "Select Master": "Select Master",
    "Cash in when profit": "Cash in when profit",
    "Profit Sharing": "Profit Sharing",
    "copy trading": "copy trading",
    "START COPYING AND EARNING WITH PRO TRADERS": "START COPYING AND EARNING WITH PRO TRADERS",
    "VTGM Copytrading lets you copy expert traders automatically, saving you the time and effort of creating your own trading strategy. Choose from the best Masters of Forex and diversify your trading portfolio.": "VTGM Copytrading lets you copy expert traders automatically, saving you the time and effort of creating your own trading strategy. Choose from the best Masters of Forex and diversify your trading portfolio.",
    "Realise the advantages of being a Copier": "Realise the advantages of being a Copier",
    'Learn From The Best': 'Learn From The Best',
    'Enter the Forex market with pros.': 'Enter the Forex market with pros.',
    'Diversify your portfolio': 'Diversify your portfolio',
    'Choose professionals you trust from a large number of Master Traders.': 'Choose professionals you trust from a large number of Master Traders.',
    'Enjoy fast order execution': 'Enjoy fast order execution',
    "Your order is executed less than 5ms after the Master Trader's one.": "Your order is executed less than 5ms after the Master Trader's one.",
    'Keep everything under control': 'Keep everything under control',
    'You can stop copying, unsubscribe, or close an order anytime.': 'You can stop copying, unsubscribe, or close an order anytime.',
    'Set it running in a few quick steps': 'Set it running in a few quick steps',
    'No additional verification required.': 'No additional verification required.',
    'Invest and withdraw safely and quickly': 'Invest and withdraw safely and quickly',
    'Popular payment methods to choose from.': 'Popular payment methods to choose from.',

    //product 
    "The Ultimate Trading Experience": "The Ultimate Trading Experience",
    "A professional trading environment that priorities users trading experience, giving users access to opportunities across the financial markets. We offers various resources and tools needed for users to success in their trading careers. Registered and regulated in Vanuatu have made us the most trustworthy and secure brokers among others.": "A professional trading environment that priorities users trading experience, giving users access to opportunities across the financial markets. We offers various resources and tools needed for users to success in their trading careers. Registered and regulated in Vanuatu have made us the most trustworthy and secure brokers among others.",
    "Diversify Your Trading Portfolio With Our Trading Products": "Diversify Your Trading Portfolio With Our Trading Products",
    "Trade CFDs on Forex, Indices, Commodities, Equities & ETFs, or subscribe to our PAMM service.": "Trade CFDs on Forex, Indices, Commodities, Equities & ETFs, or subscribe to our PAMM service.",
    "CFD": "CFD",
    "What Does CFD Stands For?": "What Does CFD Stands For?",
    'It means "contract for difference". These contracts track spot markets and are attractive to small investor because they do not require you to purchase the underlying asset.': 'It means "contract for difference". These contracts track spot markets and are attractive to small investor because they do not require you to purchase the underlying asset.',
    'You only have to pay the price difference from when you open a trading position to when you close it. Also, you can use leverage, which can help increase the size of your positions even if you have limited capital.': 'You only have to pay the price difference from when you open a trading position to when you close it. Also, you can use leverage, which can help increase the size of your positions even if you have limited capital.',
    "But what are CFDs in trading? Here is a look at the benefits of CFD trading and the best ways to engage is CFD trading.": "But what are CFDs in trading? Here is a look at the benefits of CFD trading and the best ways to engage is CFD trading.",
    "What is CFD Trading?": "What is CFD Trading?",
    "Going Short Or Long With CFDs": "Going Short Or Long With CFDs",
    "Going Long": "Going Long",
    'When you buy a CFD, you "go long", meaning you make profits when the underlying market rises. A CFD long position may be more common, but you can also take the other side of the trade.': 'When you buy a CFD, you "go long", meaning you make profits when the underlying market rises. A CFD long position may be more common, but you can also take the other side of the trade.',
    "Going Short": "Going Short",
    'When you take the "sell" side, you "go short", taking a short CFD position. If you take a short CFD position, you can make profits if the underlying market goes down.': 'When you take the "sell" side, you "go short", taking a short CFD position. If you take a short CFD position, you can make profits if the underlying market goes down.',
    "CFD Trading": "CFD Trading",
    "Long": "Long",
    "Short": "Short",
    "Trade": "Trade",
    "Buy": "Buy",
    "Sell": "Sell",
    "Profit": "Profit",
    "Market Goes Down": "Market Goes Down",
    "24-hour Market": "24-hour Market",
    "Yes": "Yes",
    "Automatic Trading": "Automatic Trading",
    "Available": "Available",
    "The ability to easily go long or short can potentially double the number of trading opportunities for traders who would otherwise only be able to go long with other assets.": "The ability to easily go long or short can potentially double the number of trading opportunities for traders who would otherwise only be able to go long with other assets.",
    "CFD trading often involves leverage, which grants traders more significant exposure to large positions without having to hand over the full cost at the time of trading.": "CFD trading often involves leverage, which grants traders more significant exposure to large positions without having to hand over the full cost at the time of trading.",
    "This allows traders to increase the size of a CFD position even if they have limited capital. However, increasing the size of your position with borrowed capital can also expose you to magnified profits and losses alike.": "This allows traders to increase the size of a CFD position even if they have limited capital. However, increasing the size of your position with borrowed capital can also expose you to magnified profits and losses alike.",
    "How CFDs work": "How CFDs work",
    "Here is what happens when you initiate a CFD trade on our website.": "Here is what happens when you initiate a CFD trade on our website.",
    'The trader opens a position and either pays or receives money based on the market movements.': 'The trader opens a position and either pays or receives money based on the market movements.',
    'If the market goes down, you must pay the difference between the opening and closing price.': 'If the market goes down, you must pay the difference between the opening and closing price.',
    'If the underlying asset increases in price, the broker pays the difference to the trader.': 'If the underlying asset increases in price, the broker pays the difference to the trader.',
    "Benefits of CFD Trading": "Benefits of CFD Trading",
    "Low Trading Cost": "Low Trading Cost",
    "A major benefit of CFD trading, especially when compared to stocks, is that they have a significantly lower cost. There are three costs involved in CFD trading:": "A major benefit of CFD trading, especially when compared to stocks, is that they have a significantly lower cost. There are three costs involved in CFD trading:",
    'Spread': 'Spread',
    "This is the difference between the buy and sell price. The buying price is always lower than the selling price, leaving the market price in the middle. The tighter the spread, the more value you can get. That's why we provide products that spread from 0.0 pips.": "This is the difference between the buy and sell price. The buying price is always lower than the selling price, leaving the market price in the middle. The tighter the spread, the more value you can get. That's why we provide products that spread from 0.0 pips.",
    'Commission': 'Commission',
    'Providers will charge a commission when a trade takes place on their platform. Liquidity providers also charge brokers.': 'Providers will charge a commission when a trade takes place on their platform. Liquidity providers also charge brokers.',
    'Swaps': 'Swaps',
    "This is the leverage cost, a daily cost that is added or subtracted from an account to pay for leverage.": "This is the leverage cost, a daily cost that is added or subtracted from an account to pay for leverage.",
    "Make Your Capital Go Further": "Make Your Capital Go Further",
    "Leverage trading is another advantage of CFDs. When trading with leverage, you can increase the size of your position, even if you don't have the required upfront capital. This means that if you were to buy 200 shares of anything, you wouldn't have to pay the full cost immediately.": "Leverage trading is another advantage of CFDs. When trading with leverage, you can increase the size of your position, even if you don't have the required upfront capital. This means that if you were to buy 200 shares of anything, you wouldn't have to pay the full cost immediately.",
    "Leveraged CFD trading allows you to increase the volume of profits or losses without the initial cost that a traditional trade would require. In certain circumstances, you could control $30 for every $1 in your account. Other significant benefits CFDs have that other asset classes lacks including:": "Leveraged CFD trading allows you to increase the volume of profits or losses without the initial cost that a traditional trade would require. In certain circumstances, you could control $30 for every $1 in your account. Other significant benefits CFDs have that other asset classes lacks including:",
    "Global accessibility to investors with limited capital.": "Global accessibility to investors with limited capital.",
    "Access to automatic and algorithmic trading.": "Access to automatic and algorithmic trading.",
    "Go long or short on a market's direction.": "Go long or short on a market's direction.",
    "Trade across various markets, including shares, forex, precious metals, energies, indices and stock indices. 24-hour, 5-day-a-week trading across several markets.": "Trade across various markets, including shares, forex, precious metals, energies, indices and stock indices. 24-hour, 5-day-a-week trading across several markets.",
    "To learn more about how CFDs stack up against other assets classes, like stocks, check out Our Blog.": "To learn more about how CFDs stack up against other assets classes, like stocks, Contact Our Account Managers.",
    "Assets to trade with CFDs": "Assets to trade with CFDs",
    "What is a CFD trading market option?": "What is a CFD trading market option?",
    "CFDs track many different asset classes. Here is what you can trade with a quality broker:": "CFDs track many different asset classes. Here is what you can trade with a quality broker:",
    'Forex': 'Forex',
    'Currency CFDs track forex pairs. A regulated CFD broker like TMGM offers access to major pairs like EUR/USD and AUD/USD, as well as minor pairs and exotic currencies.': 'Currency CFDs track forex pairs. A regulated CFD broker like VT Global Markets offers access to major pairs like EUR/USD and AUD/USD, as well as minor pairs and exotic currencies.',
    'Shares': 'Shares',
    'Share CFDs track major stocks like Apple, Tesla, Amazon, Netflix and Baidu. Since they track spot markets, CFDs do not have the same divergennt pricing features that make futures and options challenging to trade..': 'Share CFDs track major stocks like Apple, Tesla, Amazon, Netflix and Baidu. Since they track spot markets, CFDs do not have the same divergent pricing features that make futures and options challenging to trade.',
    'Metals': 'Metals',
    "Gold and Silver CFDs offer access to precious metal spot markets. These CFDs can work for day trading or protecting against inflation and volatility.": "Gold and Silver CFDs offer access to precious metal spot markets. These CFDs can work for day trading or protecting against inflation and volatility.",
    'Energies': 'Energies',
    "Crude oil CFDs offer access to volatile global energy markets. You can trade these popular commodities using both technical analysis and fundamental research.": "Crude oil CFDs offer access to volatile global energy markets. You can trade these popular commodities using both technical analysis and fundamental research.",
    'Cryptocurrencies': 'Cryptocurrencies',
    'Crypto CFDs allows you to invest in digital currency spot markets without the hassle of purchasing the underlying asset.': 'Crypto CFDs allows you to invest in digital currency spot markets without the hassle of purchasing the underlying asset.',
    'Indices': 'Indices',
    'Index CFDs track major global indices, including the S&P 500 and Dow Jones Industrial Average(DJIA) in the US, the S&P/ASX 200 in Australia, and the Nikkei 225 in Japan.': 'Index CFDs track major global indices, including the S&P 500 and Dow Jones Industrial Average(DJIA) in the US, the S&P/ASX 200 in Australia, and the Nikkei 225 in Japan.',
    "Get in touch with us to begin trading in CFDs, or open an account now and gain access to global markets in under three minutes.": "Get in touch with us to begin trading in CFDs, or open an account now and gain access to global markets in under three minutes.",
    "Get In Touch": "Get In Touch",
    "Trade a huge selection of currency pairs in the largest financial market with high leverage.": "Trade a huge selection of currency pairs in the largest financial market with high leverage.",
    "What is Forex?": "What is Forex?",
    "Foreign exchange, commonly known as Forex or FX, relates to the buy and sell on currencies with the aim of making profits from the difference of prices.": "Foreign exchange, commonly known as Forex or FX, relates to the buy and sell on currencies with the aim of making profits from the difference of prices.",
    "The forex market is the world’s largest financial market, which is also the most active and liquid market with over $5.5 trillion worth of currency volume processed each day.": "The forex market is the world’s largest financial market, which is also the most active and liquid market with over $5.5 trillion worth of currency volume processed each day.",
    "Live Chart": "Live Chart",
    "indices": "indices",
    "Trade the movements of an industry or sector's growth or trajectory in the form of CFD, with the ability to buy or sell.": "Trade the movements of an industry or sector's growth or trajectory in the form of CFD, with the ability to buy or sell.",
    "What Are Indices?": "What Are Indices?",
    "Indices are a collection of stocks and instruments that are used to track an industry or sector’s growth or trajectory.": "Indices are a collection of stocks and instruments that are used to track an industry or sector’s growth or trajectory.",
    "For example, the US500, also known as the S&P 500 Index, is an American stock market index that tracks the performance of 500 large companies listed on US stock exchanges.": "For example, the US500, also known as the S&P 500 Index, is an American stock market index that tracks the performance of 500 large companies listed on US stock exchanges.",
    "Commodities": "Commodities",
    "Trade CFDs on commodities, ranging from gold and silver to WTI and Brent oil.": "Trade CFDs on commodities, ranging from gold and silver to WTI and Brent oil.",
    "What Are Commodities?": "What Are Commodities?",
    "Commodities are commercial products that appear naturally in the ground or are agriculturally cultivated.": "Commodities are commercial products that appear naturally in the ground or are agriculturally cultivated.",
    "Changes in commodities price also tend to affect the entire supply chain. At our platform, commodities such as precious metals and energies are available for CFD trading.": "Changes in commodities price also tend to affect the entire supply chain. At our platform, commodities such as precious metals and energies are available for CFD trading.",
    "What Are Precious Metal?": "What Are Precious Metal?",
    "Precious metals are metals commodities that are often considered by investors seeking diversification during periods of market volatility or bear markets.": "Precious metals are metals commodities that are often considered by investors seeking diversification during periods of market volatility or bear markets.",
    "Precious metals are also known as reliable metals with real and conveyable value which can be used as a hedge against periods of high inflation or currency devaluation.": "Precious metals are also known as reliable metals with real and conveyable value which can be used as a hedge against periods of high inflation or currency devaluation.",
    "Equities": "Equities",
    "Trade price movement of a wide range of diverse global equities including Amazon, Apple, Facebook and Tesla Motors.": "Trade price movement of a wide range of diverse global equities including Amazon, Apple, Facebook and Tesla Motors.",
    "What Are Equities?": "What Are Equities?",
    "Equities are shares in a company. When you trade equities in the form of CFDs with VT, you are not actually purchasing the specific share, but rather trading the price movements, providing you with the opportunity to profit in both rising and falling markets.": "Equities are shares in a company. When you trade equities in the form of CFDs with VT, you are not actually purchasing the specific share, but rather trading the price movements, providing you with the opportunity to profit in both rising and falling markets.",
    "ETFs": "ETFs",
    "Trade ETFs like ishares Core S&P 500 and SPDR Gold Shares in the form of CFD.": "Trade ETFs like ishares Core S&P 500 and SPDR Gold Shares in the form of CFD.",
    "What Is ETF?": "What Is ETF?",
    "An exchange traded fund (ETF) is an investment instrument that tracks the performance of an asset or a group of assets that may include indices, commodities, bonds, and other assets. When you trade ETFs in the form of CFDs with VT, you are not actually purchasing the specific ETF, but rather investing in its price.": "An exchange traded fund (ETF) is an investment instrument that tracks the performance of an asset or a group of assets that may include indices, commodities, bonds, and other assets. When you trade ETFs in the form of CFDs with VT, you are not actually purchasing the specific ETF, but rather investing in its price.",
    "For example, the SPDR S&P 500 ETF Trust (SPY) tracks the performance of the S&P 500 index while the SPDR Gold Shares (GLD) tracks the performance of gold.": "For example, the SPDR S&P 500 ETF Trust (SPY) tracks the performance of the S&P 500 index while the SPDR Gold Shares (GLD) tracks the performance of gold.",

    //navbar
    "Partnership": "Partnership",
    "Referral Program": "Referral Program",
    "Product": "Product",
    "Platform": "Platform",
    "Account Overview": "Account Overview",

    //footer
    "Copyright © VTGM All Rights Reserved.": "Copyright © VTGM All Rights Reserved.",
    "Quicklinks": "Quicklinks",
    "Our Core": "Our Core",
    "Trading": "Trading",
    "Research & Education": "Research",
    "Company": "Company",
    "Disclaimer": "Disclaimer",
    "This website’s content is only of a general nature and does not constitute personal financial advice. Trading Forex and Leveraged Financial Instruments may not be suitable for all investors. Trading leveraged products involves a significant level of risk and can result in the loss of your invested capital. We recommend that you seek independent advice and ensure that you fully understand the risks involved before trading.": "This website’s content is only of a general nature and does not constitute personal financial advice. Trading Forex and Leveraged Financial Instruments may not be suitable for all investors. Trading leveraged products involves a significant level of risk and can result in the loss of your invested capital. We recommend that you seek independent advice and ensure that you fully understand the risks involved before trading.",
    "Before acting on any material on this page, you should consider the appropriateness of such information with regard to your objectives, financial situation and needs. It is the responsibility of the Client to ascertain whether he/she is permitted to use the service of VT Global markets based on the legal requirements in his/her country of residence. VT Global markets has no liability to any person or entity for any loss or damage in whole.": "Before acting on any material on this page, you should consider the appropriateness of such information with regard to your objectives, financial situation and needs. It is the responsibility of the Client to ascertain whether he/she is permitted to use the service of VT Global Markets based on the legal requirements in his/her country of residence. VT Global Markets has no liability to any person or entity for any loss or damage in whole.",

    //sidebar
    "Take A Look At PAMM": "Take A Look At PAMM",
    "Join·Grow·Multiply": "Join·Grow·Multiply",
    "Become An Introducing Broker (IBS)": "Become An Introducing Broker (IBS)",

    //menu
    "OUR-CORE": " OUR CORE ",
    "TRADING": "  TRADING  ",
    "PARTNERSHIP": "PARTNERSHIP",
    "RESEARCH": " RESEARCH ",
    "COMPANY": "  COMPANY  ",
}

export default EN;