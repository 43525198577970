import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from "react-router-dom";
import Company from 'views/Company/Company';
import Home from 'views/Home/Home';
import MenuMobile from 'views/Menu/MenuMobile';
import Partnership from 'views/Partnership/Partnership/Partnership';
import ReferralProgram from 'views/Partnership/ReferralProgram/ReferralProgram';
import Research from 'views/Research/Research';
import AccountOverview from 'views/Trading/Account/AccountOverview';
import Platform from 'views/Trading/Platform/Platform';
import Product from 'views/Trading/Product/Product';
import Trading from 'views/Trading/Trading';

export default function App() {

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("index-page");
        document.body.classList.remove("sidebar-collapse");
    };
});

  return (
    <>
      <div className="wrapper">
        <div className="main">
          <Switch>
            <Route path="/our-core" render={(props) => <Home {...props} />} />
            <Route path="/company" render={(props) => <Company {...props} />} />
            <Route path="/research" render={(props) => <Research {...props} />} />
            <Route path="/partnership" render={(props) => <Partnership {...props} />} />
            <Route path="/referral-program" render={(props) => <ReferralProgram {...props} />} />
            {/* <Route path="/trading" render={(props) => <Trading {...props} />} /> */}
            <Route path="/account-overview" render={(props) => <AccountOverview {...props} />} />
            <Route path="/platform" render={(props) => <Platform {...props} />} />
            <Route path="/product" render={(props) => <Product {...props} />} />

            <Redirect to="/our-core" />
            <Redirect from="/" to="/our-core" />
          </Switch>
        </div>
      </div>
    </>
  )
}