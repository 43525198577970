import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Lottie from "lottie-react";
import systemJson from "../../../assets/img/home/system.json";

import pammContentIcon1 from "../../../assets/img/platform/pammContentIcon.webp";
import pammContentIcon2 from "../../../assets/img/platform/pammContentIcon2.webp";
import pammContentIcon3 from "../../../assets/img/platform/pammContentIcon3.webp";
import pammContentIcon4 from "../../../assets/img/platform/pammContentIcon4.webp";
import pammContentIcon5 from "../../../assets/img/platform/pammContentIcon5.webp";

const pammContentArray = [
    { img: pammContentIcon1, index: "01.", title: "Open Account" },
    { img: pammContentIcon2, index: "02.", title: "Make a Deposit" },
    { img: pammContentIcon3, index: "03.", title: "Select Master" },
    { img: pammContentIcon4, index: "04.", title: "Cash in when profit" },
    { img: pammContentIcon5, index: "05.", title: "Profit Sharing" },
]

function PammContent(props) {
    const [imageIndex, setImageIndex] = React.useState(0);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 767 ? 1.8 : 1.2,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='pammContent'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={6} md={11} sm={11} xs={11} className=''>
                            <span className='font-Black sectionHeaderTitle2 text-white text-uppercase d-block mb-4 text-left'>{translate(props.lang, "Make your INVESTMENT Easier and Smarter")}</span>
                        </Col>
                        <Col lg={6} md={11} sm={11} xs={11} className=''>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-left'>
                                {translate(props.lang, "Undoubtedly, the financial market has a huge potential for profit. However, it is understandable that it requires time and effort to polish your skills and acquire relevant experiences, as well as time to be spent on keeping track of the market. Do you know? It’s not a problem! If you don’t feel like doing it on your own, we have the perfect solution for you!")}
                            </span>
                        </Col>
                    </Row>

                    <div className='px-4 px-lg-0'>
                        <Row className='justify-content-around align-items-center px-0 mx-0 w-100 h-100 pammContentBox'>
                            <Col lg={4} md={6} sm={8} xs={8} className='mb-5 mb-lg-0'>
                                <Lottie animationData={systemJson} className='lottieFile' />
                            </Col>
                            <Col lg={6} md={11} sm={11} xs={11} className=''>
                                <span className='font-Black sectionHeaderTitle2 text-white text-uppercase d-block mb-4 text-left'>{translate(props.lang, "Our")} <span className='linearType1'>{translate(props.lang, "PAMM System")}</span></span>
                                <span className='font-Regular sectionDesc text-light-purple d-block text-left'>
                                    {translate(props.lang, "We have launched a PAMM system that allows you to access different types of trading opportunities without the need to acquire technical skills, and it can be done in a short period of time. As soon as you have chosen a MASTER suited to your needs and invest in the pooled funds, you can enjoy your investment journey as your funds are managed by your chosen master, while you have complete control over your money. Most importantly, you’ll only have to pay a fraction of the profits generated by the master.")}
                                </span>
                            </Col>
                        </Row>
                    </div>

                    <Row className='justify-content-center align-items-center mx-0'>
                        <Col lg={7} md={11} sm={11} xs={11} className='mb-3'>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center'>
                                {translate(props.lang, "Our PAMM system is a bridge that connects both the Investor and the Master at the trading platform, allowing you to invest quickly and easily in only five steps.")}
                            </span>
                        </Col>
                    </Row>

                    <Row className='justify-content-end align-items-center mx-0'>
                        <Col lg={12} md={12} sm={11} xs={11} className='px-0 px-lg-4'>
                            <div className='d-none d-lg-block'>
                                <Row className='justify-content-start align-items-stretch px-0 mx-0 w-100 h-100 pammIconBoxSection'>
                                    {
                                        pammContentArray.map((item, i) => (
                                            <Col lg={"fit"} md={4} sm={12} xs={12} className='h-100 px-2 mb-4'>
                                                <Row className='justify-content-center align-items-start pammIconBox mx-0 h-100'>
                                                    <Col lg={7} md={6} sm={6} xs={6}>
                                                        <div className='pammIconImgDiv' id={'pammIconImgDiv' + i}>
                                                            <img src={item.img} className='' />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={5} sm={5} xs={5}>
                                                        <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                                    </Col>
                                                    <Col lg={11} md={11} sm={11} xs={11} className='descSpacing'>
                                                        <span className='font-Black sectionDesc text-white d-block'>{translate(props.lang, item.title)}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>

                            <div className='d-block d-lg-none my-4'>
                                <Slider {...settings} arrows={false} >
                                    {
                                        pammContentArray.map((item, i) => (
                                            <div className='h-100 px-2 mb-4'>
                                                <Row className='justify-content-center align-items-start pammIconBox mx-0 h-100'>
                                                    <Col lg={7} md={6} sm={6} xs={6}>
                                                        <div className='pammIconImgDiv' id={'pammIconImgDiv' + i}>
                                                            <img src={item.img} className='' />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={5} sm={5} xs={5}>
                                                        <span className='font-Bold sectionDivTitle text-purple d-block text-right text-capitalize'>{item.index}</span>
                                                    </Col>
                                                    <Col lg={11} md={11} sm={11} xs={11} className='descSpacing'>
                                                        <span className='font-Black sectionDesc text-white d-block'>{translate(props.lang, item.title)}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(PammContent);