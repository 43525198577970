import React from 'react'
import { FaArrowLeft, FaArrowRight, FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const advantageCopierArray = [
    { index: '01.', title: 'Learn From The Best', desc: 'Enter the Forex market with pros.' },
    { index: '02.', title: 'Diversify your portfolio', desc: 'Choose professionals you trust from a large number of Master Traders.' },
    { index: '03.', title: 'Enjoy fast order execution', desc: "Your order is executed less than 5ms after the Master Trader's one." },
    { index: '04.', title: 'Keep everything under control', desc: 'You can stop copying, unsubscribe, or close an order anytime.' },
    { index: '05.', title: 'Set it running in a few quick steps', desc: 'No additional verification required.' },
    { index: '06.', title: 'Invest and withdraw safely and quickly', desc: 'Popular payment methods to choose from.' },
]

function AdvantageCopier(props) {
    const [imageIndex, setImageIndex] = React.useState(0);
    const slider2 = React.useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 767 ? 2 : 1,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='advantageCopier'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-around align-items-stretch px-0 mx-0 w-100 h-100 advantageCopierBoxSection'>
                        <Col lg={4} md={11} sm={11} xs={11} className='mb-4'>
                            <span className='font-Black sectionHeaderTitle2 text-white text-uppercase d-block mb-4 text-left'>
                                {translate(props.lang, "Realise the advantages of being a Copier")}
                            </span>
                            <div className="d-flex align-items-center justify-content-start mt-5">
                                <div className='d-flex justify-content-start align-items-center arrow-btn mr-3 mr-lg-4' onClick={() => slider2?.current?.slickPrev()}>
                                    <div className='btn'>
                                        <FaArrowLeft className='sectionDivTitle text-white' />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-start align-items-center arrow-btn' onClick={() => slider2?.current?.slickNext()}>
                                    <div className='btn'>
                                        <FaArrowRight className='sectionDivTitle text-white' />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8} md={11} sm={11} xs={11} className='mb-4'>
                            <Slider {...settings} arrows={false} ref={slider2} >
                                {
                                    advantageCopierArray.map((item, i) => (
                                        <Row className='justify-content-center align-items-start advantageCopierBox mx-0 h-100 px-2 px-lg-4' id={"advantageCopierBox" + i}>
                                            <Col lg={11} md={12} sm={11} xs={11}>
                                                <span className='font-Bold sectionDivTitle text-index-style d-block text-left text-capitalize'>{item.index}</span>
                                            </Col>
                                            <Col lg={11} md={12} sm={11} xs={11} className=''>
                                                <span className='font-Regular sectionDivTitle text-white d-block my-4 text-capitalize'>{translate(props.lang, item.title)}</span>
                                                <span className='font-Regular sectionDesc text-white d-block'>{translate(props.lang, item.desc)}</span>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(AdvantageCopier);