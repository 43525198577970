import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";
import { EconomicCalendar } from "react-ts-tradingview-widgets";

function APISection(props) {
    return (
        <>
            <div className='apiSection'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-around justify-content-lg-between align-items-start px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={11} xs={11} className='mt-5'>
                            <div className='w-100 apiSectionBox d-flex w-100 justify-content-center align-items-center'>
                                <EconomicCalendar colorTheme="dark" isTransparent={true} width="100%" locale={props.lang === "ZH" ? "zh_CN": "en"}></EconomicCalendar>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(APISection);