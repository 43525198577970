import React from 'react'
import { FaArrowLeft, FaArrowRight, FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const edgePrimeArray = [
    { index: "01.", desc: "One of the TOP 50 players in the worldwide eFX market" },
    { index: "02.", desc: "Excellent trading conditions" },
    { index: "03.", desc: "Fully regulated investment firm" },
    { index: "04.", desc: "German management with German understanding of operating in business" },
    { index: "05.", desc: "Ultra-flexible account setups and individual conditions" },
    { index: "06.", desc: "World-wide liquidity hubs with server locations in Equinix LD4 (London), NY4 (New York) and TY3 (Tokyo) datacenters." },
]

function EdgePrime(props) {
    const [imageIndex, setImageIndex] = React.useState(0);
    const slider2 = React.useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 991 ? 3 : window.innerWidth > 767 ? 2 : 1,
        centerMode: false,
        centerPadding: 0,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <>
            <div className='edgePrime'>
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                        <Col lg={12} md={11} sm={11} xs={11} className='px-0'>
                            <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100'>
                                <Col lg={6} md={12} sm={12} xs={12} className='mb-3'>
                                    <span className='font-Black sectionHeaderTitle2 text-white d-block text-left text-uppercase mb-2'>{translate(props.lang, "The Edge of GBE Prime")}</span>
                                </Col>
                                <Col lg={6} md={12} sm={12} xs={12} className='mb-3'>
                                    <div className="d-flex align-items-end justify-content-end">
                                        <div className='d-flex justify-content-start align-items-center arrow-btn mr-3 mr-lg-4'  onClick={() => slider2?.current?.slickPrev()}>
                                            <div className='btn'>
                                                <FaArrowLeft className='sectionDivTitle text-white' />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start align-items-center arrow-btn'  onClick={() => slider2?.current?.slickNext()}>
                                            <div className='btn'>
                                                <FaArrowRight className='sectionDivTitle text-white' />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className=''>
                                    <Slider {...settings} arrows={false} ref={slider2} >
                                        {
                                            edgePrimeArray.map((item, i) => {
                                                return (
                                                    <div className="edgePrimeInfoBox h-100" id={"edgePrimeInfoBox" + i}>
                                                        <span className='font-Black sectionDivTitle d-block text-left mb-5'>{item.index}</span>
                                                        <span className='font-Regular sectionDesc text-light-purple d-block text-left'>{translate(props.lang, item.desc)}</span>
                                                    </div>
                                                );
                                            })}
                                    </Slider>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(EdgePrime);