import React from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { translate } from "views/utils/translate";

function ResearchHeader(props) {
    return (
        <>
            <div className='header' id="analytical-tools">
                <Container className='w-100 h-100'>
                    <Row className='justify-content-center align-items-center px-0 mx-0 w-100 h-100 my-5'>
                        <Col lg={6} md={9} sm={11} xs={11} className='mb-4 mb-lg-0'>
                            <img src={require("../../assets/img/research/headerImg.webp").default} alt="" className="w-100 floating3" />
                        </Col>
                        <Col lg={6} md={11} sm={11} xs={11} className=''>
                            <span className='font-Black sectionHeaderTitle text-white text-uppercase text-center text-lg-left d-block mb-3'>{translate(props.lang, "Analytical tools")}</span>
                            <span className='font-Regular sectionDesc text-light-purple d-block text-center text-lg-left'>{translate(props.lang, "We make sure you have access to the top fundamental and technical analysis tools so you can plan your trades with confidence.")}</span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(ResearchHeader);